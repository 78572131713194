define("ember-keycloak-auth/components/keycloak-session-link", ["exports", "ember-keycloak-auth/templates/components/keycloak-session-link"], function (_exports, _keycloakSessionLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _keycloakSessionLink.default,
    session: Ember.inject.service('keycloak-session'),
    actions: {
      login() {
        this.get('session').login();
      },

      logout() {
        this.get('session').logout();
      }

    }
  });

  _exports.default = _default;
});