define("iris/components/project/edit/component", ["exports", "iris/utils/converter", "iris/lib/modals", "iris/utils/dependencies", "iris/models/file-state", "iris/utils/finances", "iris/utils/create-record", "iris/lib/filters/types", "iris/utils/error"], function (_exports, _converter, _modals, _dependencies, _fileState, _finances, _createRecord, _types, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    api: Ember.inject.service(),
    loading: Ember.inject.service(),
    modals: Ember.inject.service(),
    regionElement: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    groupBy: 'productResult',
    defaultCurrency: null,
    selectedProjectTicketTemplateGroup: null,

    async didReceiveAttrs() {
      this._super(...arguments);

      const currency = await (0, _finances.getSessionDefaultCurrency)(this.sessionAccount);
      this.set('defaultCurrency', currency);
    },

    workLogExtraColumns: Ember.computed(function () {
      return [{
        name: 'ticket.subject',
        displayName: this.intl.t('ticket.subject'),
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'user.longName',
        displayName: this.intl.t('account.longName'),
        visibility: _types.AttributeVisibility.Filter
      }];
    }),
    workLogColumnOverwrites: Ember.computed(function () {
      return {
        ticket: {
          visibility: _types.AttributeVisibility.Table
        },
        user: {
          visibility: _types.AttributeVisibility.Table
        }
      };
    }),
    wiki: 1,
    ticketListNeededFields: Ember.computed('regionElement', function () {
      const fields = ['productResult.name', 'ticketNumber', 'subject', 'ticketUserAssocs', 'plannedEndDate', 'state', 'plannedExpensesInHour', 'alreadyAssignedTimes', 'sumPublicSpentTime', 'sumSpentTime', 'allSpentTime'];

      if (this.regionElement.show('ticket-pst')) {
        fields.push('pst');
      }

      return fields;
    }),
    columns: Ember.computed('model.file.id', function () {
      return {
        productResult: {
          optionsQuery: async () => {
            const productResults = await this.store.query('fileProductResult', {
              filter: {
                where: {
                  fileId: this.model.file.id
                }
              }
            });
            return productResults.map(pr => {
              return {
                id: pr.id,
                name: pr.name
              };
            });
          },
          displayName: this.intl.t('ticket.productResult')
        },
        state: {
          name: 'ticketStateId'
        },
        sumPublicSpentTime: {
          visibility: 'export'
        },
        sumSpentTime: {
          visibility: 'export'
        },
        allSpentTime: {
          visibility: 'export'
        },
        alreadyAssignedTimes: {
          exportable: false
        }
      };
    }),
    ticketBulkActions: Ember.computed(function () {
      const {
        api,
        flashMessages,
        intl,
        modals
      } = this;
      return [{
        name: intl.t('buttons.delete'),
        icon: 'trash',

        async action(selection) {
          const deleteConfirmation = await modals.open('beta/modals/confirmation', {
            title: intl.t('buttons.delete'),
            body: intl.t('ticket.bulk-delete-confirmation')
          });

          if (!deleteConfirmation) {
            return;
          }

          const collectTicketIds = selection => selection.reduce((ids, item) => item.id ? [...ids, item.id] : item.children ? [...ids, ...collectTicketIds(item.children)] : ids, []);

          const ticketIds = collectTicketIds(selection);

          try {
            const result = await api.collectionAction('ticket', 'bulk-delete', {
              method: 'POST',
              data: {
                ticketIds
              }
            });
            const {
              all,
              deleted,
              skipped
            } = result;
            flashMessages.info(intl.t('ticket.bulk-delete-response', {
              all,
              deleted,
              skipped
            }), {
              title: intl.t('general.delete.success-title'),
              icon: 'warning'
            });
          } catch (error) {
            flashMessages.danger(intl.t('general.delete.error'), {
              title: intl.t('general.delete.error'),
              icon: 'warning'
            });
          }

          this.storage.refresh();
        }

      }];
    }),
    externalRowActions: Ember.computed(function () {
      const {
        flashMessages,
        intl,
        modals
      } = this;
      return [{
        name: intl.t('general.assign'),
        icon: 'user',
        style: '',

        async action(row, event) {
          event.stopPropagation();
          event.preventDefault();
          const record = this.groupBy ? this.store.peekRecord('ticket', row.id) : row;
          await modals.open('modals/ticket-user-assoc', {
            model: record
          }, {
            className: _modals.ModalLayoutClassName['XL']
          });
          this.storage.refresh();
        }

      }, {
        name: intl.t('general.deletion'),
        icon: 'trash',
        style: 'danger',
        disabled: Ember.computed('row.ticket.allSpentTime', function () {
          return Ember.get(this, 'row.ticket.allSpentTime') ? intl.t('file-operational.ticket-not-deletable') : false;
        }),

        async action(record, event) {
          event.stopPropagation();
          event.preventDefault();
          let shouldDelete = await modals.open('beta/modals/delete-confirmation');

          if (shouldDelete === false) {
            return;
          }

          const res = await record.ticket.destroyRecord(true);

          if (res) {
            this.storage.refresh();
            flashMessages.info(intl.t('general.delete.success'), {
              title: intl.t('general.delete.success-title'),
              icon: 'times'
            });
          } else {
            flashMessages.danger(intl.t('general.delete.error'), {
              title: intl.t('general.delete.error'),
              icon: 'warning'
            });
          }
        }

      }];
    }),
    outgoungProposalRowActions: Ember.computed(function () {
      return [{
        name: this.intl.t('finances.quotation-generation'),
        icon: 'file-text',
        style: '',

        async action(row, event) {
          event.stopPropagation();
          event.preventDefault();

          try {
            const clientPartner = await row.get('file.clientPartner');
            const addresses = await clientPartner.get('addresses');
            const billingAddress = addresses.find(address => {
              return address.belongsTo('addressType').id() === 'SYS_02';
            });

            if (!billingAddress) {
              this.flashMessages.danger(this.intl.t('projects.files.no-billing-address'), {
                title: this.intl.t('projects.files.no-billing-address-title'),
                icon: 'times',
                sticky: true
              });
              return;
            }

            this.loading.run(async () => {
              const fileId = await row.belongsTo('file').id();
              const base64 = await this.api.memberAction('project', row.id + '/pdf-quotation', fileId, {
                method: 'GET'
              });
              row.set('valid', true);
              await row.save(true);
              this.storage.refresh();
              saveAs(_converter.default.b64toBlob(base64, 'application/pdf'), `quotation${moment().format('YYYY/MM/DD')}.pdf`);
            });
          } catch (e) {
            this.get('flashMessages').danger(this.intl.t('general.generation.error'), {
              title: this.intl.t('general.generation.error'),
              icon: 'warning',
              sticky: true
            });
          }
        }

      }, {
        name: this.intl.t('finances.tig-generation'),
        icon: 'file-text-o',
        style: '',

        async action(row, event) {
          event.stopPropagation();
          event.preventDefault();
          const clientPartner = await row.get('file.clientPartner');
          const addresses = await clientPartner.get('addresses');
          const billingAddress = addresses.find(address => {
            return address.belongsTo('addressType').id() === 'SYS_02';
          });

          if (!billingAddress) {
            this.flashMessages.danger(this.intl.t('projects.files.no-billing-address'), {
              title: this.intl.t('projects.files.no-billing-address-title'),
              icon: 'times',
              sticky: true
            });
            return;
          }

          const completionCertSigner = await row.get('file.completionCertSigner');
          let signer;

          if (!completionCertSigner) {
            const file = await row.get('file');
            signer = await this.modals.open('modals/completion-certificate/signer-alert', {
              row: row,
              file: file
            }, {
              className: _modals.ModalLayoutClassName['M']
            });
          }

          if (signer || completionCertSigner) {
            const pdf = await this.modals.open('modals/completion-certificate', {
              model: row
            }, {
              className: _modals.ModalLayoutClassName['XXXL']
            });

            if (pdf) {
              row.set('isOrdered', true);
              this.storage.refresh();
            }
          }
        }

      }];
    }),
    proposalRowActions: Ember.computed(function () {
      return [{
        name: this.intl.t('buttons.clone'),
        icon: 'clone',

        async action(proposal, event) {
          event.stopPropagation();
          event.preventDefault();
          let shouldClone = await this.modals.open('beta/modals/confirmation', {
            title: this.intl.t('modals.clone-confirmation.title'),
            body: this.intl.t('modals.clone-confirmation.info')
          });

          if (shouldClone) {
            try {
              let record = await this.api.memberAction('fileProposal', 'clone', proposal.id, {
                method: 'POST'
              });
              this.flashMessages.info(this.intl.t('modals.clone-confirmation.success.info'), {
                title: this.intl.t('modals.clone-confirmation.success.title'),
                icon: 'copy'
              });
              this.storage.refresh();
            } catch (error) {
              this.flashMessages.danger(this.intl.t('modals.clone-confirmation.error.info'), {
                title: this.intl.t('modals.clone-confirmation.error.title'),
                icon: 'warning',
                sticky: true
              });
            }
          }
        }

      }];
    }),
    worklogDropDownActions: Ember.computed(function () {
      return [{
        name: this.intl.t('general.download'),
        action: this.downloadWorklogsReport.bind(this)
      }];
    }),
    projectTicketDropDownActions: Ember.computed(function () {
      return [{
        name: this.intl.t('project-ticket.generate-project-tickets'),
        action: this.generateProjectTickets.bind(this)
      }];
    }),
    customButtons: Ember.computed('model.file.planPeriodCloseDate', 'model.file.closed', function () {
      let buttons = [];

      if (!this.disableInputObject.planning) {
        if (!this.model.file.planPeriodCloseDate) {
          buttons.push({
            icon: 'fa fa-times',
            text: this.intl.t('buttons.close-planning'),
            action: this.get('actions.closePlanning').bind(this),
            type: 'dark'
          });
        } else {
          buttons.push({
            icon: 'fa fa-check',
            text: this.intl.t('buttons.reopen-planning'),
            action: this.get('actions.openPlanning').bind(this),
            type: 'default'
          });
        }
      }

      if (!this.disableInputObject.common) {
        if (!this.model.file.closed) {
          buttons.push({
            icon: 'fa fa-file-excel-o',
            text: this.intl.t('forms.close-file'),
            action: this.get('actions.closeFile').bind(this),
            type: 'success'
          });
        } else {
          buttons.push({
            icon: 'fa fa-file-text',
            text: this.intl.t('forms.open-file'),
            action: this.get('actions.openFile').bind(this),
            type: 'default'
          });
        }
      }

      return buttons;
    }),
    contactHash: Ember.computed('model.file.clientPartner', 'model.file.primeContractor', function () {
      if (this.model.file.subcontractor) {
        return {
          and: [{
            or: [{
              partnerId: this.model.file.primeContractor.get('id')
            }, {
              partnerId: this.model.file.clientPartner.get('id')
            }]
          }, {
            active: 1
          }]
        };
      }

      return {
        and: [{
          partnerId: this.model.file.clientPartner.get('id')
        }, {
          active: 1
        }]
      };
    }),
    ticketCount: Ember.computed.alias('selectedProjectTicketTemplateGroup.projectTicketTemplates.length'),

    ticketAfterSave(savedTicket) {
      savedTicket.setStartEndDates();
    },

    downloadWorklogsReport() {
      let data = [];
      let file = this.get('model.file');
      data.push([]);
      data.push([this.intl.t('work-log.primeContractorName'), file.get('primeContractor.name')]);
      data.push([this.intl.t('work-log.clientPartnerName'), file.get('clientPartner.name')]);
      data.push([this.intl.t('work-log.fileName'), file.get('name')]);
      data.push([this.intl.t('work-log.managerUserName'), file.get('managerUser.longName')]);
      data.push([this.intl.t('work-log.fileDateStart'), file.get('dateStart')]);
      data.push([this.intl.t('work-log.fileDateEnd'), file.get('dateEnd')]);
      data.push([this.intl.t('work-log.generationDate'), moment().format('YYYY.MM.DD H:mm:ss').toString()]);
      let header = [this.intl.t('work-log.workLogNumber'), this.intl.t('file-product-result.modelName'), this.intl.t('work-log.solver'), this.intl.t('work-log.ticketName'), this.intl.t('application-to-intervene.scheduled-hpurs'), this.intl.t('work-log.publicSpentTime'), this.intl.t('work-log.spentTime'), this.intl.t('labels.total'), this.intl.t('work-log.ticketStart'), this.intl.t('work-log.ticketEnd'), this.intl.t('work-log.workDate'), this.intl.t('work-log.createDate'), this.intl.t('work-log.isLate'), this.intl.t('work-log.status'), this.intl.t('work-log.decription'), this.intl.t('work-log.attachment')];
      data.push([]);
      data.push(header);
      let workSheetName = 'work-log-export';
      this.get('ajax').call('GET', 'project', file.id, 'work-logs-xslx').then(async res => {
        res.forEach(item => {
          let pr = item.ticket.productResult ? item.ticket.productResult.name : 'n/a';
          let bs = item.ticket.businessService ? item.ticket.businessService.name : 'n/a';
          let plannedStartDate = item.ticket.plannedStartDate ? moment(item.ticket.plannedStartDate).format('L') : '';
          let plannedEndDate = item.ticket.plannedEndDate ? moment(item.ticket.plannedEndDate).format('L') : '';
          let beginDate = moment(item.beginDate).format('L');
          let createDate = moment(item.createDate).format('L');
          let isLate = 'n/a';

          if (plannedEndDate && beginDate) {
            isLate = moment(beginDate).isAfter(plannedEndDate) ? 'IGEN' : 'NEM';
          }

          data.push([item.workLogNumber, pr, item.user.longName, item.ticket.subject, Number(item.ticket.plannedExpensesInHour), Number(item.publicSpentTime), Number(item.spentTime), Number(item.publicSpentTime) + Number(item.spentTime), plannedStartDate, plannedEndDate, beginDate, createDate, isLate, item.ticket.state.name, item.comment, item.documents.length ? this.intl.t('general.yes') : this.intl.t('general.no')]);
        });
        let xlsx = await (0, _dependencies.loadXLSXModule)();
        let workbook = new Workbook(),
            ws = sheet_from_array_of_arrays(data, xlsx);
        workbook.SheetNames.push(workSheetName);
        workbook.Sheets[workSheetName] = ws;
        let binaryWorkbook = xlsx.write(workbook, {
          bookType: 'xlsx',
          bookSST: false,
          type: 'binary'
        });
        saveAs(new Blob([s2ab(binaryWorkbook)], {
          type: 'application/octet-stream'
        }), 'work-log-export.xlsx');
      });

      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);

        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;

        return buf;
      }

      function Workbook() {
        if (!(this instanceof Workbook)) return new Workbook();
        this.SheetNames = [];
        this.Sheets = {};
      }

      function convertDateValue(v, date1904) {
        if (date1904) v += 1462;
        var epoch = Date.parse(v);
        return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
      }

      function sheet_from_array_of_arrays(data, xlsx) {
        var ws = {};
        var range = {
          s: {
            c: 10000000,
            r: 10000000
          },
          e: {
            c: 0,
            r: 0
          }
        };

        for (var R = 0; R != data.length; ++R) {
          for (var C = 0; C != data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            var cell = {
              v: data[R][C]
            };
            if (cell.v == null) continue;
            var cell_ref = xlsx.utils.encode_cell({
              c: C,
              r: R
            });
            if (typeof cell.v === 'number') cell.t = 'n';else if (typeof cell.v === 'boolean') cell.t = 'b';else if (cell.v instanceof Date) {
              cell.t = 'n';
              cell.z = xlsx.SSF._table[14];
              cell.v = convertDateValue(cell.v);
            } else cell.t = 's';
            ws[cell_ref] = cell;
          }
        }

        if (range.s.c < 10000000) ws['!ref'] = xlsx.utils.encode_range(range);
        return ws;
      }
    },

    async generateProjectTickets(storage) {
      const {
        flashMessages,
        intl,
        modals
      } = this;
      const fileId = this.model.file.id;
      let where = {
        fileId
      };

      if (this.projectState === 'project') {
        where.project = true;
      } else {
        where.projectOpportunity = true;
      }

      let {
        projectTicketTemplateGroup,
        createNewProductResult,
        productResultName
      } = await modals.open('modals/select-project-ticket-template-group', where, {
        className: _modals.ModalLayoutClassName.XS
      });

      if (projectTicketTemplateGroup) {
        try {
          await this.get('ajax').call('POST', 'project', null, 'create-tickets-from-ticket-template', {
            projectTicketTemplateGroupId: projectTicketTemplateGroup.id,
            fileId,
            createNewProductResult,
            productResultName
          });
          storage.refresh();
          flashMessages.info(intl.t('projects.project-tickets.generate-success'), {
            title: intl.t('models.successful'),
            icon: 'check'
          });
        } catch (err) {
          flashMessages.danger(intl.t('projects.project-tickets.generate-fail'), {
            title: intl.t('general.error'),
            icon: 'warning'
          });
          console.log(err);
        }
      }
    },

    async beforeSave(savedFile) {
      if (savedFile.isStateChanged && savedFile.fileState.get('id') === _fileState.FILE_STATE_ID.Accepted) {
        savedFile.set('isStateChanged', false);
      }
    },

    async afterSave(record) {
      record.set('selectedProjectTicketTemplateGroup', null);
    },

    actions: {
      async copyProjectDataToClipboard(file) {
        const fileOwnerPartner = await file.get('fileOwnerPartner');
        const clientPartner = await file.get('clientPartner');
        const primeContractor = await file.get('primeContractor');
        const partnerSponsor = await file.get('partnerSponsor');
        const managerUser = await file.get('managerUser');
        const sponsorUser = await file.get('sponsorUser');
        const fileType = await file.get('fileType');
        const value = `${file.fileNumber};${file.name};${fileOwnerPartner ? fileOwnerPartner.name : ''};${clientPartner.name};${clientPartner.shortName};${primeContractor ? primeContractor.name + ';' + primeContractor.shortName : ';'};${partnerSponsor ? partnerSponsor.name + ';' + partnerSponsor.email + ';' + partnerSponsor.phone : ';;'};${managerUser ? managerUser.name + ';' + managerUser.email + ';' + managerUser.phone : ';;'};${sponsorUser ? sponsorUser.name : ''};${fileType ? fileType.name : ''}`;
        navigator.clipboard.writeText(value);
        this.flashMessages.success(this.intl.t('forms.project-data-copied-to-clipboard'), {
          title: this.intl.t('forms.copy-success'),
          icon: 'check'
        });
      },

      async changeFileState(state) {
        const {
          flashMessages,
          intl,
          projectState
        } = this;
        const {
          file
        } = this.model;

        if (projectState !== 'project' && state.get('id') === _fileState.FILE_STATE_ID.Accepted) {
          flashMessages.warning(intl.t('projects.files.file-state-change-info'), {
            title: intl.t('general.warning'),
            icon: 'warning',
            sticky: true
          });
          let ticket = await file.get('tickets').get('firstObject');

          if (ticket) {
            await ticket.setStartEndDates(true);
          }
        }

        file.set('fileState', state);
        file.set('isStateChanged', true);
      },

      changePartner(selected) {
        let file = this.model.file;

        if (!file.subcontractor) {
          file.set('primeContractor', selected);
        }

        file.set('clientPartner', selected);
      },

      changeManager(selected) {
        let file = this.model.file;
        file.set('managerUser', selected);
        file.set('salesManager', selected);
        file.set('technicalManagerUser', selected);
      },

      setProfitCenter(profitCenter) {
        let file = this.model.file;
        file.set('profitCenter', profitCenter);
        file.set('fileBusinessType', null);
        file.set('fileType', null);
      },

      setFileBusinessType(fileBusinessType) {
        let file = this.model.file;
        file.set('fileBusinessType', fileBusinessType);
        file.set('fileType', null);
      },

      closePlanning() {
        let file = this.model.file;
        file.set('planPeriodCloseDate', new Date());
        file.save();
      },

      openPlanning() {
        let file = this.model.file;
        file.set('planPeriodCloseDate', null);
        file.save();
      },

      async closeFile() {
        let file = this.model.file;
        file.set('closed', true);
        file.save();
      },

      openFile() {
        let file = this.model.file;
        file.set('closed', false);
        file.save();
      },

      subcontractorChanged() {
        this.set('model.file.primeContractor', this.model.file.get('clientPartner'));
      },

      projectTicketTemplateGroupChanged(selected) {
        this.set('selectedProjectTicketTemplateGroup', selected);
        this.model.file.set('projectTicketTemplateGroupId', selected === null || selected === void 0 ? void 0 : selected.id);
      },

      async createOutgoingProposal() {
        return await (0, _createRecord.createFileOutgoingProposal)(this.store, this.intl, this.sessionAccount, this.model.file);
      },

      async generateProjectTickets() {
        let where = {};

        if (this.projectState === 'project') {
          where.project = true;
        } else {
          where.projectOpportunity = true;
        }

        let {
          projectTicketTemplateGroup,
          createNewProductResult,
          productResultName
        } = await this.modals.open('modals/select-project-ticket-template-group', where, {
          className: _modals.ModalLayoutClassName.XS
        });

        if (projectTicketTemplateGroup) {
          try {
            await this.get('ajax').call('POST', 'project', null, 'create-tickets-from-ticket-template', {
              projectTicketTemplateGroupId: projectTicketTemplateGroup.id,
              fileId: this.model.file.id,
              createNewProductResult,
              productResultName
            });
            this.storage.refresh();
          } catch (err) {
            console.log(err);
          }
        }
      },

      async openWorklogDisplayModal(worklog) {
        await this.modals.open('modals/work-log-display-modal', {
          worklog
        }, {
          className: _modals.ModalLayoutClassName.S
        });
      },

      /**
       * Edits the correspodenting fileUserGradeCostPlanned and reloads the fileUserGradeCost record
       *
       * @param {*} record fileUserGradeCost record
       */
      async editPlannedCost(record) {
        const {
          flashMessages,
          intl
        } = this;
        const userGradeId = record.belongsTo('userGrade').id();
        const projectId = record.belongsTo('file').id();

        try {
          const fileUserGradeCostPlanneds = await this.api.collectionAction('fileUserGradeCostPlanned', 'update-cost', {
            method: 'POST',
            data: {
              userGradeId,
              projectId,
              cost: record.plannedCost
            }
          });
          await record.reload();
          flashMessages.info(intl.t('general.save.success'), {
            title: intl.t('general.save.success-title'),
            icon: 'times'
          });
        } catch (error) {
          const msg = (0, _error.formatErrorMessages)(error);
          flashMessages.danger(msg, {
            title: intl.t('general.save.error'),
            sticky: true,
            icon: 'warning'
          });
        }
      },

      /**
       * Resets all file user grade cost planned record to the main values
       *
       * @param {*} o2m One2Many Component
       */
      async resetUserGradeCostPlanned(o2m) {
        const {
          api,
          flashMessages,
          intl,
          modals
        } = this;
        const resetConfirmation = await modals.open('beta/modals/confirmation', {
          title: intl.t('file-user-grade-cost.resetUserGrades'),
          body: intl.t('file-user-grade-cost.resetUserGradesConfimation')
        });

        if (!resetConfirmation) {
          return;
        }

        try {
          await api.memberAction('project', 'reset-user-grade-planned', this.model.file.id, {
            method: 'POST'
          });
          flashMessages.info(intl.t('general.save.success'), {
            title: intl.t('general.save.success-title'),
            icon: 'times'
          });
          o2m.storage.refresh();
        } catch (error) {
          const msg = (0, _error.formatErrorMessages)(error);
          flashMessages.danger(msg, {
            title: intl.t('general.save.error'),
            sticky: true,
            icon: 'warning'
          });
        }
      }

    }
  });

  _exports.default = _default;
});