define("ember-plupload/system/file", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moxie, plupload */
  const moxieFileReader = function () {
    return new moxie.file.FileReader();
  };

  const keys = Object.keys;

  const mergeDefaults = function (defaults, options) {
    const unsetKeys = Ember.A(keys(defaults)).removeObjects(keys(options));
    const settings = (0, _emberCopy.copy)(options, true);

    for (let i = 0, len = unsetKeys.length; i < len; i++) {
      let key = unsetKeys[i];
      settings[key] = defaults[key];
    }

    return settings;
  };

  const settingsToConfig = function (settings) {
    let {
      url,
      method,
      accepts,
      contentType,
      headers,
      data,
      maxRetries,
      chunkSize,
      multipart,
      fileKey
    } = mergeDefaults({
      method: 'POST',
      accepts: ['application/json', 'text/javascript'],
      contentType: Ember.get(this, 'type'),
      headers: {},
      data: {},
      maxRetries: 0,
      chunkSize: 0,
      multipart: true,
      fileKey: 'file'
    }, settings);

    if (headers.Accept == null) {
      if (!Ember.Array.detect(accepts)) {
        accepts = Ember.A([accepts]).compact();
      }

      headers.Accept = accepts.join(',');
    } // Switch setting content type according to
    // the type of request we're sending.


    if (contentType) {
      if (multipart) {
        data['Content-Type'] = contentType;
      } else {
        headers['Content-Type'] = contentType;
      }
    }

    return {
      url: url,
      method: method,
      headers: headers,
      multipart: multipart,
      multipart_params: data,
      max_retries: maxRetries,
      chunk_size: chunkSize,
      file_data_name: fileKey
    };
  };
  /**
    A representation of a single file being uploaded
    by the `UploadQueue`.
  
    @namespace ember-plupload
    @class File
    @extends Ember.Object
   */


  var _default = Ember.Object.extend({
    /**
      The unique ID of the file.
       @property id
      @type String
     */
    id: Ember.computed.reads('file.id'),

    /**
      The name of the file.
       @property name
      @type String
     */
    name: Ember.computed.alias('file.name'),

    /**
      The size of the file in bytes
       @property size
      @type Number
     */
    size: Ember.computed.reads('file.size'),

    /**
      The content type of the file
       @property type
      @type String
     */
    type: Ember.computed.reads('file.type'),

    /**
      The current upload progress of the file,
      which is a number between 0 and 100.
       @property progress
      @type Number
     */
    progress: Ember.computed({
      get() {
        return Ember.get(this, 'file.percent');
      }

    }),

    /**
      Remove the file from the upload queue.
       @method destroy
     */
    destroy() {
      this._super(...arguments);

      Ember.get(this, 'uploader').removeFile(Ember.get(this, 'file'));
    },

    upload(url, settings) {
      var uploader = Ember.get(this, 'uploader');
      this._deferred = Ember.RSVP.defer(`File: '${Ember.get(this, 'id')}' Upload file`);

      if (settings == null) {
        if (typeof url === 'object') {
          settings = url;
        } else {
          settings = {
            url
          };
        }
      } else if (url) {
        settings.url = url;
      }

      this.settings = settingsToConfig.call(this, settings); // Handle future versions of plupload with
      // a parallel upload api provided on the file object

      if (this.file.upload) {
        this.file.upload(this.settings);
      } else {
        if (this.file.status === plupload.FAILED) {
          this.file.status = plupload.QUEUED;
        }

        if (this.get('queue').every(f => f.settings)) {
          uploader.start();
        }
      }

      return this._deferred.promise;
    },

    read() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        as: 'data-url'
      };
      let file = Ember.get(this, 'file').getSource();
      /*jshint -W055 */

      let reader = moxieFileReader();
      /*jshint +W055 */

      let {
        promise,
        resolve,
        reject
      } = Ember.RSVP.defer();
      reader.onloadend = resolve;
      reader.onerror = reject;

      switch (options.as) {
        case 'array-buffer':
          reader.readAsArrayBuffer(file);
          break;

        case 'data-url':
          reader.readAsDataURL(file);
          break;

        case 'binary-string':
          reader.readAsBinaryString(file);
          break;

        case 'text':
          reader.readAsText(file);
          break;
      }

      return promise.then(function () {
        return reader.result;
      }, function () {
        return Ember.RSVP.reject(reader.error);
      });
    }

  });

  _exports.default = _default;
});