define("ember-promise-modals/templates/components/modal-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xWDyF6It",
    "block": "{\"symbols\":[\"modal\",\"index\"],\"statements\":[[4,\"each\",[[23,0,[\"modals\",\"_stack\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"epm-backdrop \",[28,\"if\",[[23,1,[\"isClosing\"]],\"epm-out\"],null]]]],[10,\"tabindex\",\"-1\"],[10,\"role\",\"presentation\"],[10,\"aria-hidden\",\"true\"],[11,\"data-test-epm-backdrop\",[29,[[23,2,[]]]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"epm-modal-container\"],[8],[0,\"\\n    \"],[5,\"epm-modal\",[[12,\"data-test-epm-modal\",[29,[[23,2,[]]]]]],[[\"@modal\"],[[23,1,[]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-promise-modals/templates/components/modal-container.hbs"
    }
  });

  _exports.default = _default;
});