define("ember-changeset/helpers/changeset-get", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CONTENT = '_content';
  const CHANGES = '_changes';

  class ChangesetGet extends Ember.Helper.extend({
    invalidate: Ember.observer(`changeset.${CONTENT}`, `changeset.${CHANGES}`, function () {
      this.recompute();
    })
  }) {
    constructor() {
      super(...arguments);
      this.changeset = null;
    }

    compute(_ref) {
      let [changeset, fieldPath] = _ref;

      if (this.changeset === null) {
        this.set('changeset', changeset);
      }

      if (!this.changeset) {
        return;
      }

      return this.changeset.get(fieldPath);
    }

  }

  _exports.default = ChangesetGet;
});