define("iris/templates/components/avatar-stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NUBNcSAT",
    "block": "{\"symbols\":[\"user\",\"i\",\"&default\"],\"statements\":[[14,3],[0,\"\\n\"],[4,\"each\",[[24,[\"users\"]]],null,{\"statements\":[[4,\"if\",[[28,\"and\",[[23,1,[\"userPhoto\"]],[28,\"lt\",[[23,2,[]],4],null]],null]],null,{\"statements\":[[0,\"    \"],[5,\"avatar\",[],[[\"@class\",\"@image\",\"@name\"],[\"inline\",[23,1,[\"userPhoto\"]],[23,1,[\"longName\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"ember-tooltip\",null,null,{\"statements\":[[0,\"  \"],[1,[22,\"names\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/avatar-stack.hbs"
    }
  });

  _exports.default = _default;
});