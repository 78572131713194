define("iris/components/ember-initials/adorable/component", ["exports", "ember-initials/components/adorable"], function (_exports, _adorable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _adorable.default;
    }
  });
});