define("ember-autofocus-modifier/modifiers/autofocus", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_SELECTOR = 'input:not([disabled]):not([readonly]),textarea:not([disabled]):not([readonly])';

  var _default = (0, _emberModifier.modifier)(function autofocus(element, _ref, _ref2) {
    let [selector = DEFAULT_SELECTOR] = _ref;
    let {
      disabled
    } = _ref2;

    if (disabled) {
      return;
    }

    const childElement = element.querySelector(selector);
    Ember.run.next(function () {
      if (childElement) {
        childElement.focus();
      } else {
        element.focus();
      }
    });
  }, {
    eager: false
  });

  _exports.default = _default;
});