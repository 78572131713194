define("@kockpit/ember-gantt/components/gantt-generic-content", ["exports", "@kockpit/ember-gantt/templates/components/gantt-generic-content"], function (_exports, _ganttGenericContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _ganttGenericContent.default
  });

  _exports.default = _default;
});