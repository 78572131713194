define('ember-number-to-human-size/helpers/number-to-human-size', ['exports', 'ember-number-to-human-size/utils/make-helper'], function (exports, _makeHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numberToHumanSize = numberToHumanSize;
  function numberToHumanSize(params /*, hash*/) {
    let bytes = params[0];
    let si = !params[1];
    let roundAmount = params[2] || 0;
    let thresh = si ? 1000 : 1024;
    let u = -1;
    let units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    if (Math.abs(bytes) < thresh) {
      return `${bytes} B`;
    }

    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);

    return `${bytes.toFixed(roundAmount)} ${units[u]}`;
  }

  exports.default = (0, _makeHelper.default)(numberToHumanSize);
});