define("ember-cropperjs/components/image-cropper-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ImageCropperCallComponent = Ember.Component.extend({
    tagName: '',
    obj: null,
    func: null,
    args: null,
    onResp: null,
    params: null,

    didReceiveAttrs() {
      this._super(...arguments);

      let {
        obj,
        func,
        args,
        params
      } = Ember.getProperties(this, 'obj', 'func', 'args', 'params');

      if (args === null && params !== null) {
        if (func !== null) {
          args = params;
        } else {
          [func, ...args] = params;
        }
      }

      (false && !(typeof obj === 'object' && obj !== null) && Ember.assert('image-cropper-call obj is required', typeof obj === 'object' && obj !== null));
      (false && !(typeof func === 'string') && Ember.assert('image-cropper-call func is required and must be a string', typeof func === 'string'));
      (false && !(typeof obj[func] === 'function') && Ember.assert(`image-cropper-call ${func} must be a function on ${obj}`, typeof obj[func] === 'function'));
      this.sendAction('onResp', obj[func].apply(obj, args)); // eslint-disable-line ember/closure-actions
    }

  });
  ImageCropperCallComponent.reopenClass({
    positionalParams: 'params'
  });
  var _default = ImageCropperCallComponent;
  _exports.default = _default;
});