define("iris/models/file-product-result", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 512
    })],
    comment: (0, _emberCpValidations.validator)('length', {
      max: 2024
    })
  });
  let now = new Date();
  let end = moment().add(2, 'w').toDate();

  var _default = _model.default.extend(Validations, {
    orderColumn: (0, _attr.default)('string', {
      defaultValue: 'plannedStartDate'
    }),
    name: (0, _attr.default)('string'),
    plannedStartDate: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    plannedEndDate: (0, _attr.default)('date', {
      defaultValue: function () {
        return end;
      }
    }),
    billable: (0, _attr.default)('boolean'),
    comment: (0, _attr.default)('string'),
    file: (0, _relationships.belongsTo)('project'),
    tickets: (0, _relationships.hasMany)('ticket'),
    proposalItems: (0, _relationships.hasMany)('fileProposalItem'),
    plannedMoney: Ember.computed.mapBy('tickets.content', 'plannedBudgetMoney'),
    amountTicket: Ember.computed.sum('plannedMoney'),
    plannedMoneyProposal: Ember.computed.mapBy('validProposalItems', 'defaultAmountUntaxed'),
    plannedProposal: Ember.computed.sum('plannedMoneyProposal'),
    plannedProposalFileAmount: Ember.computed('plannedMoneyProposal', 'file.exchangeRate', function () {
      let res = 0;

      for (const defaultAmountUntaxed of this.get('plannedMoneyProposal').toArray()) {
        res += defaultAmountUntaxed / this.get('file.exchangeRate');
      }

      return res;
    }),
    amount: Ember.computed('amountTicket', 'plannedProposalFileAmount', function () {
      return parseFloat(this.get('amountTicket')) + parseFloat(this.get('plannedProposalFileAmount'));
    }),
    actualMoneyProposal: Ember.computed.mapBy('paidAndValidProposalItems', 'defaultAmountUntaxed'),
    actualProposal: Ember.computed.sum('actualMoneyProposal'),
    ticketCosts: Ember.computed.mapBy('tickets.content', 'cost'),
    costTicket: Ember.computed.sum('ticketCosts'),
    cost: Ember.computed('costTicket', 'actualProposal', function () {
      return parseFloat(this.get('costTicket')) + parseFloat(this.get('actualProposal'));
    }),
    ticketOverheadCosts: Ember.computed.mapBy('tickets.content', 'overheadCost'),
    costOverheadTicket: Ember.computed.sum('ticketOverheadCosts'),
    costOverhead: Ember.computed('costOverheadTicket', 'actualProposal', 'tickets.[]', function () {
      return parseFloat(this.get('costOverheadTicket')) + parseFloat(this.get('actualProposal'));
    }),
    validProposalItems: Ember.computed.filterBy('proposalItems', 'isValid'),
    paidProposalItems: Ember.computed.filterBy('proposalItems', 'isPaid'),
    paidAndValidProposalItems: Ember.computed.filterBy('validProposalItems', 'isPaid')
  });

  _exports.default = _default;
});