define("iris/components/forms/entity-form-min", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function () {
      this._super();

      this.set('model.parent', this.get('parentEntity'));
      this.set('model.generateName', true);
    },
    mode: 'entity',
    softwareMode: 'new',
    tabs: Ember.computed('mode', 'softwareMode', 'noTab', function () {
      let noTab = this.get('noTab');
      let mode = this.get('mode');
      let softwareMode = this.get('softwareMode');

      if (noTab) {
        return [];
      } else {
        let res = [{
          name: this.intl.t('entity-type.entityAttributes'),
          route: 'cmdb.entity-types.entities.' + mode + '.software.' + softwareMode + '.attributes'
        }, {
          name: this.intl.t('cmdb.software'),
          route: 'cmdb.entity-types.entities.' + mode + '.software.' + softwareMode + '.software'
        }];
        return res;
      }
    }),
    types: Ember.computed('entityTypes', function () {
      let entityTypes = this.get('entityTypes');

      if (entityTypes) {
        let normalEntityType = entityTypes.filter(et => {
          return et.id !== null;
        });
        return normalEntityType.filter(et => {
          return et.belongsTo('entityTypeCategory').id().slice(-9) === '000000002';
        });
      } else {
        return Ember.A();
      }
    }),
    actions: {
      changeEntityType: function (selected) {
        let store = this.get('store');
        let model = this.get('model');
        let entityType = selected;
        model.set('entityType', entityType);
        model.set('generateName', entityType.get('generateName'));
        model.get('entityValues').then(values => {
          values.invoke('deleteRecord');
          values.clear();
          entityType.get('entityAttributes').then(attributes => {
            attributes.forEach(col => {
              let record = store.createRecord('entityValue', {
                value: col.get('defaultValue'),
                entity: model,
                entityAttribute: col,
                orderIndex: col.orderIndex
              });
              values.pushObject(record);
            });
          });
        });
      },

      toggleNameField() {
        if (!this.get('model.generateName')) {
          this.set('model.name', '');
        } else {
          this.set('model.name', '-');
        }
      }

    },
    partnerHash: Ember.computed('model', function () {
      return {
        and: [{
          deletedAt: null
        }, {
          isActive: 1
        }]
      };
    })
  });

  _exports.default = _default;
});