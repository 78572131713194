define("ember-changeset/utils/validator-lookup", ["exports", "ember-changeset/utils/wrap", "ember-changeset/utils/handle-multiple-validations", "ember-changeset/utils/is-promise"], function (_exports, _wrap, _handleMultipleValidations, _isPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lookupValidator;

  /**
   * returns a closure to lookup and validate k/v pairs set on a changeset
   *
   * @method lookupValidator
   * @param validationMap
   */
  function lookupValidator(validationMap) {
    return _ref => {
      let {
        key,
        newValue,
        oldValue,
        changes,
        content
      } = _ref;
      let validator = validationMap[key];

      if (Ember.isEmpty(validator)) {
        return true;
      }

      if (Ember.isArray(validator)) {
        return (0, _handleMultipleValidations.default)(validator, {
          key,
          newValue,
          oldValue,
          changes,
          content
        });
      }

      let validation = validator(key, newValue, oldValue, changes, content);
      return (0, _isPromise.default)(validation) ? validation.then(_wrap.default) : [validation];
    };
  }
});