define("ember-aria-tabs/templates/components/aria-tab-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aM9Xv7cP",
    "block": "{\"symbols\":[\"@tabNodes\",\"@selectedIndex\",\"@selectedTabClassName\",\"@parentGuid\",\"@panelNodes\",\"@onKeyDown\",\"@onClick\",\"@focus\",\"@disabledTabClassName\",\"&default\"],\"statements\":[[14,10,[[28,\"hash\",null,[[\"tab\"],[[28,\"component\",[\"aria-tab\"],[[\"disabledTabClassName\",\"focus\",\"onClick\",\"onKeyDown\",\"panelNodes\",\"parentGuid\",\"selectedTabClassName\",\"selectedIndex\",\"tabNodes\"],[[23,9,[]],[23,8,[]],[23,7,[]],[23,6,[]],[23,5,[]],[23,4,[]],[23,3,[]],[23,2,[]],[23,1,[]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-aria-tabs/templates/components/aria-tab-list.hbs"
    }
  });

  _exports.default = _default;
});