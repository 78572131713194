define('ember-countdown/components/countdown-html', ['exports', 'ember-countdown/templates/components/countdown-html', 'countdownjs', 'ember-countdown/components/countdown-component'], function (exports, _countdownHtml, _countdownjs, _countdownComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _countdownComponent.default.extend({
    htmlTag: 'span',
    layout: _countdownHtml.default,

    countdownText() {
      _countdownjs.default.setLabels(this.get('singularLabel'), this.get('pluralLabel'), this.get('lastLabel'), this.get('delimLabel'), this.get('emptyLabel'));
      const newCountdown = (0, _countdownjs.default)(this.get('parsedStartDate'), this.get('parsedEndDate'), this.get('units'), this.get('max'));
      const tag = this.get('htmlTag');

      if (this.get('suffix') && newCountdown.value > 0) {
        this.set('text', `${newCountdown.toHTML(tag)} ${this.get('suffixFromNow')}`);
      } else if (this.get('suffix') && newCountdown.value < 0) {
        this.set('text', `${newCountdown.toHTML(tag)} ${this.get('suffixToNow')}`);
      } else {
        this.set('text', newCountdown.toHTML(tag));
      }
    }
  });
});