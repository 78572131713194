define("iris/templates/settings/operations/resource-plan-priorities/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oyUeM+Kp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@proportion\",\"@componentLayout\",\"@beforeSave\"],[[23,0,[\"model\"]],\"settings.operations.resource-plan-priorities\",\"3-9\",\"col-2\",[28,\"route-action\",[\"beforeSave\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/operations/resource-plan-priorities/edit.hbs"
    }
  });

  _exports.default = _default;
});