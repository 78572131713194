define("@kockpit/ember-gantt/components/gantt-line-barcontent", ["exports", "@kockpit/ember-gantt/templates/components/gantt-line-barcontent"], function (_exports, _ganttLineBarcontent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   Lines can have special "bar-content". Use this as sub-component of the line component
   ### Usage
   Use as a block level component with any number of yielded line, header etc. - see examples)
   components as children:
   ```handlebars
    {{#gantt-chart dayWidth=10 as |chart|}}
  
      {{#each projects as |p|}}
  
        {{#chart.line dateStart=p.dateStart dateEnd=p.dateEnd as |line|}}
  
          {{#line.barContent}}<span>My content</span>{{/line.barContent}}
  
        {{/chart.line}}
      {{/each}}
  
    {{/gantt-chart}}
   ```
  
   @class GanttLineBarcontent
   @namespace Components
   @extends Ember.Component
   @public
   */
  var _default = Ember.Component.extend({
    layout: _ganttLineBarcontent.default,
    classNames: ['gantt-line-bar-content']
  });

  _exports.default = _default;
});