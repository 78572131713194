define("ember-world-flags/helpers/world-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defaultSize = 16;
  var defaultCountry = 'us';

  var _default = Ember.Helper.helper(function worldFlag(params) {
    var length, country, size;
    length = params.length;
    country = params[0] || defaultCountry;
    size = params[1] || defaultSize;

    if (length === 0 || length > 2) {
      throw new TypeError('Invalid Number of arguments, expected at least 1 and at most 2');
    }

    var result = `<i class="flag${size} flag-${country.toLowerCase()}"></i>`;
    return Ember.String.htmlSafe(result);
  });

  _exports.default = _default;
});