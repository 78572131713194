define("iris/templates/components/form-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "khAH/NCo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[11,\"class\",[29,[[22,\"labelClass\"],\" \",[22,\"hasValidationClass\"],\" control-label\"]]],[8],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"labelClass\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[22,\"controlClass\"]],[8],[0,\"\\n    \"],[14,1],[0,\"\\n\"],[4,\"if\",[[24,[\"showMessage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[\"alert alert-danger \",[23,0,[\"controlClass\"]]]]],[8],[0,\"\\n        \"],[1,[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[24,[\"model\"]],\"validations\"],null],\"attrs\"],null],[24,[\"property\"]]],null],\"message\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[14,1],[0,\"\\n\"],[4,\"if\",[[24,[\"showMessage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[8],[0,\"\\n        \"],[1,[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[24,[\"model\"]],\"validations\"],null],\"attrs\"],null],[24,[\"property\"]]],null],\"message\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[24,[\"tooltip\"]]],null,{\"statements\":[[4,\"ember-tooltip\",null,null,{\"statements\":[[0,\"    \"],[1,[22,\"tooltip\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/form-group.hbs"
    }
  });

  _exports.default = _default;
});