define('@salsify/ember-block-slots/mixins/slots', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _slots: Ember.computed(function () {
      return Ember.A();
    }),

    _activateSlot(name) {
      this._slots.addObject(name);
    },

    _deactivateSlot(name) {
      this._slots.removeObject(name);
    },
    _isRegistered(name) {
      return this._slots.includes(name);
    }
  });
});