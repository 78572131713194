define("ember-promise-modals/components/modal", ["exports", "focus-trap", "ember-promise-modals/templates/components/modal"], function (_exports, _focusTrap, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _modal.default,
    tagName: '',
    outAnimationClass: 'epm-out',
    modals: Ember.inject.service(),
    optionsClassName: Ember.computed.readOnly('modal._options.className'),
    modalElementId: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'modalElementId', Ember.guidFor(this));
      this.modal._componentInstance = this;
    },

    didInsertElement() {
      this._super(...arguments);

      let {
        clickOutsideDeactivates,
        _disableFocusTrap
      } = this.modals;
      let element = document.getElementById(this.modalElementId);
      let options = {
        clickOutsideDeactivates,
        fallbackFocus: `#${this.modalElementId}`,
        onDeactivate: () => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          this.closeModal();
        }
      };

      if (!_disableFocusTrap) {
        this.focusTrap = (0, _focusTrap.createFocusTrap)(element, options);
        this.focusTrap.activate();
      }

      this.fadeOutEnd = _ref => {
        let {
          target,
          animationName
        } = _ref;

        this.modals._onModalAnimationEnd();

        let isntTarget = target !== element;
        let animationEndsWrong = animationName.substring(animationName.length - 4) !== '-out';

        if (isntTarget || animationEndsWrong) {
          return;
        }

        this.modal._remove();
      };

      this.modals._onModalAnimationStart();

      element.addEventListener('animationend', this.fadeOutEnd);
      Ember.set(this, 'animatingOutClass', '');
    },

    willDestroyElement() {
      if (this.focusTrap) {
        this.focusTrap.deactivate({
          onDeactivate: null
        });
      }

      if (this.fadeOutEnd) {
        let element = document.getElementById(this.modalElementId);

        if (element) {
          element.removeEventListener('animationend', this.fadeOutEnd); // make sure that we remove the modal, also resolving the test waiter

          this.modal._remove();
        }
      }

      this._super(...arguments);
    },

    closeModal(result) {
      // Trigger out animation
      Ember.set(this, 'animatingOutClass', this.outAnimationClass);

      if (this.focusTrap) {
        this.focusTrap.deactivate({
          onDeactivate: null
        });
      }

      this.modal._resolve(result);
    },

    actions: {
      close(result) {
        this.closeModal(result);
      }

    }
  });

  _exports.default = _default;
});