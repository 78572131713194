define("iris/routes/dashboard", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    ajax: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentUser: Ember.computed.reads('sessionAccount.account'),

    async beforeModel() {
      const region = this.get('sessionAccount.region'); // Portal

      if (this.currentUser.portal) {
        let where = {
          and: [{
            regionId: region.id
          }, {
            settingKey: 'portalLandingPage'
          }]
        };
        let settings = await this.get('store').query('regionalSetting', {
          filter: {
            where: where
          }
        });
        const contact = await this.currentUser.get('contact');

        if (settings && settings.get('length')) {
          settings.forEach(s => {
            if (contact && contact.belongsTo('partner').id() === s.get('partner.id')) {
              this.transitionTo(s.get('settingValue'));
            } else {
              this.transitionTo('operation.portal-dashboard');
            }
          });
        }
      } else {
        // Normal user
        let where = {
          and: [{
            regionId: region.id
          }, {
            settingKey: 'landingPage'
          }]
        };
        let settings = await this.get('store').query('regionalSetting', {
          filter: {
            where: where
          }
        });

        if (settings && settings.get('length')) {
          settings.forEach(s => {
            if (s.get('settingKey') === 'landingPage') {
              this.transitionTo(s.get('settingValue'));
            }
          });
        }
      }
    }

  });

  _exports.default = _default;
});