define("iris/routes/application", ["exports", "jquery", "ember-simple-auth/mixins/application-route-mixin", "iris/mixins/loading-slider", "@sentry/browser", "iris/config/environment", "moment", "iris/utils/model"], function (_exports, _jquery, _applicationRouteMixin, _loadingSlider, Sentry, _environment, _moment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isTesting = _environment.default.environment === 'test';

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _loadingSlider.default, {
    record: null,
    disableButton: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    actualFetchData: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    errorHandle: Ember.inject.service(),
    keycloakSession: Ember.inject.service(),
    themeSwitcher: Ember.inject.service(),

    beforeModel(transition) {
      this._super(...arguments);

      let {
        targetName
      } = transition;

      if (targetName !== 'confirmation' && !targetName.includes('unauth')) {
        var options = _environment.default.keycloak;
        var session = this.get('keycloakSession');
        session.installKeycloak(options);
        session.set('responseMode', 'fragment');
        session.set('checkLoginIframe', false);

        if (isTesting) {
          // Fake an already logged-in user for testing. It's also required to not
          // to init Keycloak
          this.set('session.data.authenticated.userId', 'SYS_DEV');
          return this._loadCurrentUser().then(result => {
            return result;
          });
        }

        return session.initKeycloak().then(ret => {
          return this.get('session').authenticate('authenticator:keycloak', session.get('keycloak.token')).then(() => {
            return this._loadCurrentUser().then(() => {
              return ret;
            });
          }).catch(reason => {
            console.error(reason);
          });
        }).then(() => {
          this.themeSwitcher.initTheme();
        });
      }
    },

    setupController: function () {
      var lastTransition = this.get('lastTransition');
      var keycloakSession = this.get('keycloakSession');
      this.controller.set('lastTransition', lastTransition);
      this.controller.set('keycloakSession', keycloakSession);
      this.controller.set('isBootCompleted', true);
    },

    async _loadCurrentUser() {
      let result = await this.sessionAccount.loadCurrentUser(); // Prefetch `account.competencies`, because the template will need it when
      // rendered. This will block rendering until `competencies` are fetched,
      // because if they aren't, the request will be issued from the template by
      // accessing `account.competencyNames` which depends on
      // `account.competencies`. This will result in the main outlet to be
      // rendered twice, which is unfortunate, because `didInsertElement` will run
      // twice for components. The rendering alone is expensive, but if such
      // components are fetching data, these requests will be repeated
      // unnecessarily and add further delays.

      await this.sessionAccount.account.get('competencies');
      let language = await this.sessionAccount.account.get('language');
      this.get('intl').setLocale(language.languageCode);

      _moment.default.locale(language.languageCode);

      return result;
    },

    sessionAuthenticated() {
      this._super(...arguments);

      this._loadCurrentUser().catch(err => {
        console.error(err);
        this.get('session').invalidate();
      });
    },

    _save() {
      var record = this.get('record');
      var self = this;

      if (record.validate) {
        (0, _model.validateRecord)(record).then(_ref => {
          let {
            model,
            validations
          } = _ref;

          if (validations.get('isValid')) {
            self._handleSave(record);
          } else {
            this.get('errorHandle').validate(model, 'save');
            model.setValidated();
          }
        });
      } else {
        // we dont have a validator on the record
        self._handleSave(record);
      }
    },

    async _handleSave(record) {
      // insert new record and reset
      if (record.get('isNew')) {
        if (this.reset) {
          if ((0, _model.isCopyable)(record)) {
            // make a clone of the record, save it, then reset the record,
            // so user can save a new instance
            let clone = await record.copy();

            this._saveToServer(clone);

            (0, _model.resetRecordAttrs)(record);
          } else {
            Sentry.captureMessage(`A record had 'reset' option but it does not mixes in 'Copyable': ${record.constructor.modelName}`);

            this._saveToServer(record);
          }
        } else {
          this._saveToServer(record);
        }
      } else {
        this._saveToServer(record);
      }
    },

    async _saveToServer(record) {
      const flashMessages = Ember.get(this, 'flashMessages');
      this.set('disableButton.disabled', true);

      try {
        const res = await record.save();
        Ember.run.later(() => {
          this.set('disableButton.disabled', false);
        }, 500);
        let afterSave = this.get('afterSave');

        if (res === false) {
          return;
        }

        flashMessages.add({
          message: 'Successfully updated!',
          title: 'IRIS message',
          icon: 'info'
        });

        if (afterSave) {
          afterSave(res);
        }

        this._redirect();
      } catch (err) {
        let errorMessages = '';

        if (err.errors) {
          err.errors.forEach(error => {
            errorMessages += error.detail + '.';
          });
        } else {
          errorMessages += err;
        }

        flashMessages.add({
          message: errorMessages + '!!!',
          title: 'IRIS message',
          icon: 'warning'
        });
      }
    },

    _delete: function () {
      this.controller.set('deleteConfirmation', true);
    },
    _deleteFromServer: function () {
      var self = this;
      const flashMessages = Ember.get(this, 'flashMessages');
      let record = this.get('record');
      let toResolve = null;
      let afterDelete = this.get('afterDelete');

      if (Ember.isArray(record)) {
        toResolve = this._batchDelete(record);
      } else {
        if (self.destroyRecord === false) {
          toResolve = record.deleteRecord();
        } else {
          toResolve = record.destroyRecord(true);
        }
      }

      let errorMessages = '';
      toResolve.then(res => {
        let success = true;

        if (Ember.isArray(res)) {
          res.forEach(function (item, index, enumerable) {
            if (item.state == 'rejected') {
              success = false;
              item.reason.errors.forEach(error => {
                errorMessages += error.detail + '.';
              });
            }
          });
        } else {
          if (res.state == 'rejected') {
            success = false;
            res.reason.errors.forEach(error => {
              errorMessages += error.detail + '.';
            });
          }
        }

        if (success) {
          flashMessages.add({
            message: 'Successfully deleted!',
            title: 'IRIS message',
            icon: 'info'
          });
          self.controller.set('deleteConfirmation', false);

          if (afterDelete) {
            if (typeof afterDelete === 'string') {
              this.send(afterDelete);
            }

            if (typeof afterDelete === 'function') {
              afterDelete();
            }
          }

          self._redirect();
        } else {
          flashMessages.add({
            message: errorMessages,
            title: 'IRIS message',
            icon: 'warning'
          });
        }
      }).catch(err => {
        console.error(err);
        err.errors.forEach(error => {
          errorMessages += error.detail + '.';
        });
        flashMessages.add({
          message: errorMessages,
          title: 'IRIS message',
          icon: 'warning'
        });
      });
    },
    _batchDelete: function (batch) {
      // Make sure to filter out records that are in flight.
      // https://sentry.io/organizations/damit/issues/2278892730/?project=5376673
      let promises = batch.filter(record => !record.get('isLoading') && !record.get('isSaving')).invoke('destroyRecord', true);
      batch.clear();
      return Ember.RSVP.allSettled(promises);
    },
    _redirect: function () {
      var direction = this.get('redirectTo');

      if (this.get('loadData')) {
        let controller = this.controllerFor(direction);
        controller.set('loadData', true);
      }

      if (direction) {
        this.transitionTo(direction);
      }
    },

    _handleArgs(args, command) {
      let object = args[0];
      let record = args[args.length - 1]; // Find the Ember Data record from args

      let emberRecord = args.find(arg => arg && typeof arg === 'object' && arg.constructor && arg.constructor.isModel);
      record = emberRecord || record; // null out

      this.setProperties({
        redirectTo: null,
        reset: false,
        beforeDelete: null,
        afterDelete: null,
        beforeSave: null,
        afterSave: null,
        loadData: false
      });

      if (args.length > 1) {
        this.setProperties({
          redirectTo: object.redirectTo,
          reset: false || object.reset,
          destroyRecord: true || object.destroyRecord,
          beforeDelete: object.beforeDelete,
          afterDelete: object.afterDelete,
          beforeSave: object.beforeSave,
          afterSave: object.afterSave,
          loadData: object.loadData
        });
      }

      this.set('record', record);

      if (this.get('beforeSave')) {
        this.beforeSave(record, this._save.bind(this));
      } else {
        this['_' + command]();
      }
    },

    _getWhere(hash) {
      let where = {};
      let defaultWhere = null,
          userWhere = null,
          searchWhere = null;
      let searchWhereClause = Ember.A(),
          whereAll = Ember.A(),
          whereAllWithDefault = Ember.A(); //default filter

      if (hash.defaultFilterOptions) {
        if (hash.defaultFilterOptions.length) {
          if (hash.defaultFilterOptions.length > 1) {
            defaultWhere = {
              and: hash.defaultFilterOptions
            };
          } else {
            defaultWhere = hash.defaultFilterOptions[0];
          }
        }
      } //user filter


      if (hash.filterObject.length) {
        if (hash.filterObject.length > 1) {
          userWhere = {};

          if (hash.filterMode == 'neq') {
            let ar = Ember.A();
            let o = {};
            hash.filterObject.forEach(function (obj) {
              for (var index in obj) {
                var attr = obj[index];
              }

              o[index] = {
                neq: attr
              };
              ar.pushObject(o);
            });
            userWhere['and'] = ar;
          } else {
            userWhere[hash.filterMode] = hash.filterObject;
          }
        } else {
          if (hash.filterMode == 'neq') {
            userWhere = {};
            let obj = hash.filterObject[0];
            userWhere[Object.keys(obj)[0]] = {
              neq: obj[Object.keys(obj)[0]]
            };
          } else {
            userWhere = hash.filterObject[0];
          }
        }
      } //search filter


      if (hash.searchTerm) {
        hash.searchColumns.forEach(sc => {
          let o = {};
          o[sc] = {
            like: '%' + hash.searchTerm + '%'
          };
          searchWhereClause.pushObject(o);
        });
      }

      if (searchWhereClause.length) {
        if (searchWhereClause.length > 1) {
          searchWhere = {
            or: searchWhereClause
          };
        } else {
          searchWhere = searchWhereClause[0];
        }
      } // if(typeof hash.defaultFilter !== 'undefined' && hash.defaultFilter != null) {
      //   where = { and: hash.defaultFilter }
      //   // where[Object.keys(hash.defaultFilter)[0]] = hash.defaultFilter;
      // }


      if (defaultWhere) whereAll.pushObject(defaultWhere);
      if (userWhere) whereAll.pushObject(userWhere);
      if (searchWhere) whereAll.pushObject(searchWhere);

      if (hash.defaultFilter) {
        if (whereAll.length) {
          if (whereAll.length > 1) {
            whereAllWithDefault.pushObject({
              and: whereAll
            });
          } else {
            whereAllWithDefault.pushObject(whereAll[0]);
          }

          whereAllWithDefault.pushObject(hash.defaultFilter);
          where = {
            and: whereAllWithDefault
          };
        } else {
          where = hash.defaultFilter;
        }
      } else {
        if (whereAll.length) {
          if (whereAll.length > 1) {
            where = {
              and: whereAll
            };
          } else {
            where = whereAll[0];
          }
        }
      }

      return where;
    },

    _processRollback(record) {
      if (!record.get('toSave')) {
        if (record.id) {
          record.rollback();
        }
      }
    },

    actions: {
      setOptionsPower: async function (model, options, conditionKey, key) {
        const records = await this.store.findAll(model);

        if (conditionKey) {
          let res = Ember.A();
          let condition = {};
          records.forEach(row => {
            condition[conditionKey] = row.get('id');
            res.pushObject({
              text: row.get(key),
              condition: condition
            });
            condition = {};
          });
          Ember.set(options, 'options', res);
        } else {
          Ember.set(options, 'options', records);
        }
      },

      delete() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this._handleArgs(args, 'delete');
      },

      cancelDelete() {
        this.controller.set('deleteConfirmation', false);
      },

      confirmDelete() {
        if (this.get('beforeDelete')) {
          this.beforeDelete(this.get('record'), this._deleteFromServer.bind(this));
        } else {
          this._deleteFromServer();
        }
      },

      duplicateSave() {
        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        this._handleArgs(args, 'duplicateSave');
      },

      save() {
        for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
          args[_key3] = arguments[_key3];
        }

        this._handleArgs(args, 'save');
      },

      didTransition() {
        let currentPath = Ember.getOwner(this).lookup('controller:application').currentPath;
        this.set('currentPath', currentPath);
      },

      transitionTo(o) {
        try {
          if (typeof o === 'string') {
            this.transitionTo(o);
          } else {
            if (o.disabled) return;

            if (!o.segment && !o.segment.id) {
              // new
              this.transitionTo(o.route);
            }

            this.transitionTo(o.route, o.segment);
          }
        } catch (error) {
          // https://sentry.io/organizations/damit/issues/2186478460/?project=5376673
          Sentry.captureException(new Error(`Application Route's 'transitionTo' action was called with a value it couldn't handle.`));
        }
      },

      logout() {
        this.get('session').invalidate('authenticator:keycloak');
      },

      toggleSidebar() {
        (0, _jquery.default)('html').toggleClass('sidebar-left-collapsed');
        (0, _jquery.default)('html').toggleClass('sidebar-left-opened');
      },

      back(param1, value) {
        let rollback = param1 === false || value === false ? false : true; //Param1 lehet a value és lehet egy depth is
        // var _depth = 1;

        let _depth = 1;

        if (Ember.typeOf(param1) === 'instance') {
          if (rollback !== false) this._processRollback(param1);
        } else {
          _depth = param1 || 1;

          if (Ember.typeOf(value) === 'instance') {
            if (rollback !== false) this._processRollback(value);
          }
        }

        if (typeof _depth === 'string') {
          this.send('transitionTo', _depth);
        } else {
          let currentPath = Ember.getOwner(this).lookup('controller:application').currentPath;
          let split = currentPath.split('.');
          let backPath = split.slice(0, split.length - _depth).join('.');
          this.send('transitionTo', backPath);
        }
      },

      fetchData(model, hash) {
        let order = 'createDate DESC';

        let where = this._getWhere(hash);

        let method = hash.method;
        let table = hash.table;
        let id = hash.id || '';
        let verb = hash.verb ? hash.verb : 'GET';
        if (hash.sortProperties.get('length')) order = hash.sortProperties;

        if (method) {
          this.get('ajax').call(verb, model, id, method, {
            filter: {
              limit: hash.limit,
              skip: hash.offset,
              where: where,
              order: order
            }
          }).then(res => {
            Ember.run(() => {
              if (!table.get('isDestroyed')) {
                table.set('total', res.result.count);
                table.set('data', res.result.data);
                this.set('actualFetchData.data', res.result.data);
              }
            });
          });
        } else {
          this.get('ajax').call(verb, model, '', 'count', {
            where: where
          }).then(res => {
            Ember.run(() => {
              if (!table.get('isDestroyed')) {
                table.set('total', res.count);
              }
            });
          });
          this.store.query(model, {
            filter: {
              limit: hash.limit,
              skip: hash.offset,
              where: where,
              order: order
            }
          }).then(res => {
            Ember.run(() => {
              if (!table.get('isDestroyed')) {
                table.set('data', res);
              }

              this.set('actualFetchData.data', res);
            });
          });
        }
      },

      stopBubbling(event) {//event.stopPropagation();
      },

      historyBack() {
        window.history.back();
      }

    }
  });

  _exports.default = _default;
});