define("ember-aria-tabs/components/aria-tab-panel", ["exports", "ember-aria-tabs/templates/components/aria-tab-panel"], function (_exports, _ariaTabPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_CLASS = 'ember-tabs__tab-panel';
  /**
   * A tab panel component rendered as `<div />`.
   *
   * If you specify additional props on the `<AriaTabPanel />` component they will be forwarded to the rendered `<div />`.
   *
   * Default CSS class: `ember-tabs__tab-panel`
   *
   * @class AriaTabPanel
   * @public
   */

  var _default = Ember.Component.extend({
    layout: _ariaTabPanel.default,
    classNames: [DEFAULT_CLASS],
    classNameBindings: ['_selectedClassName'],
    attributeBindings: ['tabId:aria-labelledby', 'parentGuid:data-parent-guid', 'role'],

    /**
     * Defaults to `false`.
     *
     * By default the tab content will only be rendered when the tab is active. If set to `true` the tab will also be rendered if inactive.
     *
     * > This can also be enabled for all `<AriaTabPanel />` components with the prop `forceRenderTabPanel` on `<AriaTabs />`.
     *
     * @argument forceRender
     * @type Boolean
     * @default false
     */
    forceRender: false,

    /**
     * Defaults to `"ember-tabs__tab-panel--selected"`.
     *
     * Provide a custom class name for the active tab panel.
     *
     * > This option can also be set for all `<AriaTabPanel />` components with the prop `selectedTabPanelClassName` on `<AriaTabs />`.
     *
     * @argument selectedClassName
     * @type String
     * @default "ember-tabs__tab-panel--selected"
     */
    selectedClassName: null,
    role: Ember.computed({
      get() {
        return 'tabpanel';
      }

    }).readOnly(),
    nodeIndex: Ember.computed('element', 'panelNodes.[]', function () {
      return this.panelNodes.indexOf(this.element);
    }),
    tabId: Ember.computed('nodeIndex', 'tabNodes.[]', function () {
      let tab = this.tabNodes[this.nodeIndex];
      return tab ? tab.id : null;
    }),
    selected: Ember.computed('nodeIndex', 'selectedIndex', function () {
      return this.nodeIndex === this.selectedIndex;
    }),
    _selectedClassName: Ember.computed('selected', 'selectedTabPanelClassName', 'selectedClassName', function () {
      return this.selected ? this.selectedClassName || this.selectedTabPanelClassName || `${DEFAULT_CLASS}--selected` : '';
    }),
    shouldYield: Ember.computed.or('selected', 'forceRender'),

    init() {
      this._super(...arguments); // Set defaults


      Ember.setProperties(this, {
        tabNodes: [],
        panelNodes: []
      });
    }

  });

  _exports.default = _default;
});