define("iris/templates/components/forms/acl-competency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C4WARy0k",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"bs-form\",null,[[\"model\"],[[24,[\"model\"]]]],{\"statements\":[[4,\"form-group\",null,[[\"property\",\"label\"],[\"name\",[28,\"t\",[\"general.name\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"bs-input\",null,[[\"value\",\"disabled\"],[[24,[\"model\",\"name\"]],[24,[\"model\",\"builtIn\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"form-group\",null,[[\"property\",\"label\"],[\"accounts\",[28,\"t\",[\"service-level.users\"],null]]],{\"statements\":[[4,\"iris-power-select-multiple\",null,[[\"options\",\"selected\",\"closeOnSelect\",\"searchField\",\"onchange\"],[[28,\"sort-by\",[\"longName\",[24,[\"accounts\"]]],null],[24,[\"model\",\"accounts\"]],false,\"longName\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\",\"accounts\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[5,\"avatar\",[],[[\"@image\",\"@name\",\"@class\"],[[23,1,[\"userPhoto\"]],[23,1,[\"longName\"]],\"user-photo\"]]],[0,\"\\n      \"],[1,[23,1,[\"longName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"  \\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/forms/acl-competency.hbs"
    }
  });

  _exports.default = _default;
});