define("ember-plupload/computed/w", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  var toArray = function (value) {
    if (typeof value === 'string') {
      value = Ember.String.w(value);
    }

    return Ember.A(value);
  };

  function _default(defaultValue) {
    defaultValue = defaultValue || [];
    return Ember.computed({
      get() {
        return toArray(defaultValue);
      },

      set(key, value) {
        return toArray(value);
      }

    });
  }
});