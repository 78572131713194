define("iris/components/modals/cmdb-mismatch-partner/tree/node/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0b65wLnl",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[7,\"li\",true],[10,\"class\",\"ml-4 pl-4 border-l-2 border-gray-300\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex items-center space-x-2\"],[8],[0,\"\\n\\n    \"],[7,\"span\",true],[10,\"class\",\"text-gray-500\"],[8],[5,\"fa-icon\",[],[[\"@icon\"],[\"file-o\"]]],[9],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[[12,\"target\",\"_blank\"]],[[\"@route\",\"@models\"],[\"cmdb.entity-types.entities.entity.attributes\",[28,\"array\",[[24,[\"node\",\"entityType\",\"id\"]],[24,[\"node\",\"id\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[24,[\"node\",\"name\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"span\",true],[10,\"class\",\"italic\"],[8],[0,\"(\"],[1,[24,[\"node\",\"entityType\",\"name\"]],false],[0,\")\"],[9],[0,\"\\n\\n    \"],[7,\"span\",true],[10,\"class\",\"font-bold\"],[8],[1,[24,[\"node\",\"partner\",\"name\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"hasChildren\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\",true],[10,\"class\",\"mt-2\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"node\",\"children\"]]],null,{\"statements\":[[0,\"        \"],[5,\"modals/cmdb-mismatch-partner/tree/node\",[],[[\"@node\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/cmdb-mismatch-partner/tree/node/template.hbs"
    }
  });

  _exports.default = _default;
});