define("ember-keyboard/mixins/activate-keyboard-on-insert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      (false && !(false) && Ember.deprecate('`EKOnInsertMixin` of ember-keyboard is deprecated. You can achieve this behavior by using the `on-key` helper, or by using `@keyResponder` in conjunction with a `did-insert` modifier.', false, {
        id: 'ember-keyboard.activate-keyboard-on-insert-mixin',
        for: 'ember-keyboard',
        since: '6.0.2',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#activate-keyboard-on-insert-mixin'
      }));
      return this._super(...arguments);
    },

    activateKeyboardWhenPresent: Ember.on('didInsertElement', function () {
      Ember.set(this, 'keyboardActivated', true);
    })
  });

  _exports.default = _default;
});