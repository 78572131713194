define('ember-pirate/helpers/arr', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arr = arr;
  function arr(params = []) {
    let result = Ember.A([]);
    result.pushObjects(params);
    return result;
  }

  exports.default = Ember.Helper.helper(arr);
});