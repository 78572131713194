define("iris/templates/components/option-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tRYX6Xy/",
    "block": "{\"symbols\":[],\"statements\":[[5,\"avatar\",[],[[\"@image\",\"@name\",\"@class\"],[[24,[\"model\",\"userPhoto\"]],[24,[\"model\",\"longName\"]],\"user-photo\"]]],[0,\"\\n\"],[1,[24,[\"model\",\"longName\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/components/option-user.hbs"
    }
  });

  _exports.default = _default;
});