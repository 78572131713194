define('@salsify/ember-block-slots/helpers/block-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.blockParams = blockParams;


  /**
   * Per yield slot parameters made available to an associated block slot
   *
   * e.g.
   *
   * {{#yield-slot 'foo' (block-params 'a' 'b' 'c')}}
   *   <div>{{yield}}</div>
   * {{/yield-slot}}
   *
   * would be available as
   *
   * {{#block-slot 'foo' as |x y z|}}
   *   {{x}} {{y}} {{z}}
   * {{/block-slot}}
   *
   * and would result in DOM
   *
   * <div>a b c</div>
   */
  function blockParams(params) {
    return Ember.A(params.slice());
  }

  exports.default = Ember.Helper.helper(blockParams);
});