define("ember-table/components/ember-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W89VVxzy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ember-table-overflow\"],[11,\"id\",[29,[[23,0,[\"elementId\"]],\"-overflow\"]]],[8],[0,\"\\n  \"],[7,\"table\",true],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"api\",\"head\",\"body\",\"foot\",\"loadingMore\"],[[23,0,[\"api\"]],[28,\"component\",[\"ember-thead\"],[[\"api\"],[[23,0,[\"api\"]]]]],[28,\"component\",[\"ember-tbody\"],[[\"api\"],[[23,0,[\"api\"]]]]],[28,\"component\",[\"ember-tfoot\"],[[\"api\"],[[23,0,[\"api\"]]]]],[28,\"component\",[\"ember-table-loading-more\"],[[\"api\"],[[23,0,[\"api\"]]]]]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"-ember-table-private/scroll-indicators\",null,[[\"api\"],[[23,0,[\"api\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-table/template.hbs"
    }
  });

  _exports.default = _default;
});