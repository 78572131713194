define("iris/templates/settings/project/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TeqCZmE1",
    "block": "{\"symbols\":[],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@defaultOrder\",\"@excludeFields\"],[\"file-tag\",\"settings.project.tags.edit\",\"createDate DESC\",[28,\"array\",[\"files\"],null]]]],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/project/tags.hbs"
    }
  });

  _exports.default = _default;
});