define("iris/routes/operation/duties", ["exports", "iris/mixins/protected-route"], function (_exports, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    api: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        solverGroups: this.api.memberAction('duty', 'get-solver-groups', null, {
          method: 'GET'
        }),
        events: this.api.memberAction('account', 'get-account-events', null, {
          method: 'GET'
        })
      });
    }

  });

  _exports.default = _default;
});