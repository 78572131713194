define("ember-provider/inject", ["exports", "ember-provider/-private/provider-injection"], function (_exports, _providerInjection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = inject;

  /**
   * Inject a Provider into a host object
   *
   * @param identifier the Provider's identifier on the registry
   * @param options
   */
  function inject(identifier) {
    return new _providerInjection.default(identifier);
  }
});