define('ember-cronstrue/helpers/ember-cronstrue', ['exports', 'cronstrue', 'ember-cronstrue/configuration'], function (exports, _cronstrue, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.emberCronstrue = emberCronstrue;
  function emberCronstrue(params) {
    return params.length && params[0] ? _cronstrue.default.toString(params[0], {
      locale: params.length > 1 && params[1] ? params[1] : _configuration.default.defaultLocale
    }) : '';
  }

  exports.default = Ember.Helper.helper(emberCronstrue);
});