define("iris/components/file-prefix/edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FilePrefixEdit = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class FilePrefixEdit extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "flashMessages", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _defineProperty(this, "_prefix", null);
    }

    didReceiveAttrs() {
      this.set('_prefix', { ...this.data.prefix
      });
    }

    validate() {
      const {
        _prefix: {
          project,
          operational,
          sameAsProject
        }
      } = this;
      const indexPattern = /\{index(:\d+)?\}/;

      if (typeof project === 'string' && project.trim() !== '' && !indexPattern.test(project)) {
        this.flashMessages.danger(this.intl.t('components.file-prefix.validation.project-not-empty'), {
          title: this.intl.t('general.error'),
          icon: 'warning'
        });
        return;
      }

      if (!sameAsProject && typeof operational === 'string' && operational.trim() !== '' && !indexPattern.test(operational)) {
        this.flashMessages.danger(this.intl.t('components.file-prefix.validation.operational-not-empty'), {
          title: this.intl.t('general.error'),
          icon: 'warning'
        });
        return;
      }

      this.close(this._prefix);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "validate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype)), _class));
  _exports.default = FilePrefixEdit;
});