define("iris/components/seasonal-skin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2oQZpXff",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"season\"]],\"winter\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"seasonal-skin/winter\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,0,[\"season\"]],\"autumn\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"seasonal-skin/autumn\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/seasonal-skin/template.hbs"
    }
  });

  _exports.default = _default;
});