define("ember-table/components/ember-tr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zlexPoKn",
    "block": "{\"symbols\":[\"api\",\"&default\"],\"statements\":[[4,\"each\",[[23,0,[\"cells\"]]],null,{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[4,\"if\",[[23,0,[\"isHeader\"]]],null,{\"statements\":[[0,\"      \"],[14,2,[[28,\"hash\",null,[[\"columnValue\",\"columnMeta\",\"sorts\",\"sendUpdateSort\",\"rowMeta\",\"rowsCount\",\"cell\"],[[23,1,[\"columnValue\"]],[23,1,[\"columnMeta\"]],[23,1,[\"sorts\"]],[23,1,[\"sendUpdateSort\"]],[23,1,[\"rowMeta\"]],[23,1,[\"rowsCount\"]],[28,\"component\",[\"ember-th\"],[[\"api\"],[[23,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[14,2,[[28,\"hash\",null,[[\"api\",\"cellValue\",\"cellMeta\",\"columnValue\",\"columnMeta\",\"rowValue\",\"rowMeta\",\"rowsCount\",\"cell\"],[[23,1,[]],[23,1,[\"cellValue\"]],[23,1,[\"cellMeta\"]],[23,1,[\"columnValue\"]],[23,1,[\"columnMeta\"]],[23,1,[\"rowValue\"]],[23,1,[\"rowMeta\"]],[23,1,[\"rowsCount\"]],[28,\"component\",[\"ember-td\"],[[\"api\"],[[23,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isHeader\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ember-th\",null,[[\"api\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"ember-td\",null,[[\"api\"],[[23,1,[]]]]],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-tr/template.hbs"
    }
  });

  _exports.default = _default;
});