define("iris/models/wazuh-report-line", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "ember-data/relationships"], function (_exports, _model, _attr, _emberCpValidations, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _model.default.extend(Validations, {
    condition: (0, _attr.default)('string'),
    cve: (0, _attr.default)('string'),
    severity: (0, _attr.default)('string'),
    os: (0, _attr.default)('string'),
    package: (0, _attr.default)('string'),
    packageVersion: (0, _attr.default)('string'),
    agent: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    createDate: (0, _attr.default)('date'),
    entity: (0, _relationships.belongsTo)('entity')
  });

  _exports.default = _default;
});