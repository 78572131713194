define("ember-keyboard/initializers/ember-keyboard-first-responder-inputs", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    if (application) {
      let config = application.resolveRegistration('config:environment') || {};
      let emberKeyboardConfig = config.emberKeyboard || {};
      let disableInputsInitializer = Boolean(emberKeyboardConfig.disableInputsInitializer);

      if (disableInputsInitializer) {
        return;
      }
    }

    (false && !(false) && Ember.deprecate('The `ember-keyboard-first-responder-inputs` initializer is deprecated and will be removed in 7.0. Please use the `on-key` modifier with your text fields instead.', false, {
      id: 'ember-keyboard.first-responder-inputs',
      for: 'ember-keyboard',
      since: '6.0.2',
      until: '7.0.0',
      url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#first-responder-inputs'
    }));
    const TextField = false ? Ember._LegacyTextField.extend() : Ember.TextField;
    const TextArea = false ? Ember._LegacyTextArea.extend() : Ember.TextArea;
    TextField.reopen(_emberKeyboard.EKMixin, _emberKeyboard.EKFirstResponderOnFocusMixin);
    TextArea.reopen(_emberKeyboard.EKMixin, _emberKeyboard.EKFirstResponderOnFocusMixin);
  }

  var _default = {
    name: 'ember-keyboard-first-responder-inputs',
    initialize: initialize
  };
  _exports.default = _default;
});