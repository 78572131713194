define("iris/lib/filters/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OptionsResourceAction = _exports.Operator = _exports.LoopBackValueFormat = _exports.InputType = _exports.FilterValidator = _exports.FilterType = _exports.DisplayValueFormat = _exports.Direction = _exports.AttributeVisibility = _exports.AttributeType = _exports.AttributePlacement = _exports.AttributeMapper = void 0;
  let AttributePlacement;
  _exports.AttributePlacement = AttributePlacement;

  (function (AttributePlacement) {
    AttributePlacement["Column"] = "column";
    AttributePlacement["CustomAttribute"] = "custom-attribute";
  })(AttributePlacement || (_exports.AttributePlacement = AttributePlacement = {}));

  let AttributeType;
  _exports.AttributeType = AttributeType;

  (function (AttributeType) {
    AttributeType["Array"] = "array";
    AttributeType["BelongsTo"] = "belongsTo";
    AttributeType["Boolean"] = "boolean";
    AttributeType["Date"] = "date";
    AttributeType["HasMany"] = "hasMany";
    AttributeType["Number"] = "number";
    AttributeType["String"] = "string";
    AttributeType["Textarea"] = "textarea";
  })(AttributeType || (_exports.AttributeType = AttributeType = {}));

  let AttributeMapper;
  _exports.AttributeMapper = AttributeMapper;

  (function (AttributeMapper) {
    AttributeMapper["string"] = "string";
    AttributeMapper["textarea"] = "string";
    AttributeMapper["date"] = "date";
    AttributeMapper["datetime"] = "date";
    AttributeMapper["number"] = "number";
    AttributeMapper["boolean"] = "boolean";
  })(AttributeMapper || (_exports.AttributeMapper = AttributeMapper = {}));

  let FilterType;
  _exports.FilterType = FilterType;

  (function (FilterType) {
    FilterType["Boolean"] = "boolean";
    FilterType["Contains"] = "contains";
    FilterType["ContainsAny"] = "contains-any";
    FilterType["Equal"] = "equal";
    FilterType["NotEqual"] = "neq";
    FilterType["GreaterThan"] = "greater-than";
    FilterType["LessThan"] = "less-than";
    FilterType["NotNull"] = "not-null";
    FilterType["Null"] = "null";
  })(FilterType || (_exports.FilterType = FilterType = {}));

  let Operator;
  _exports.Operator = Operator;

  (function (Operator) {
    Operator["Ilike"] = "ilike";
    Operator["LessThan"] = "lt";
    Operator["GreaterThan"] = "gt";
    Operator["Equal"] = "eq";
    Operator["NotEqual"] = "neq";
    Operator["In"] = "inq";
  })(Operator || (_exports.Operator = Operator = {}));

  let InputType;
  _exports.InputType = InputType;

  (function (InputType) {
    InputType["Text"] = "text";
    InputType["Number"] = "number";
    InputType["Date"] = "date";
    InputType["Checkbox"] = "checkbox";
    InputType["Select"] = "select";
    InputType["MultiSelect"] = "multiselect";
    InputType["Null"] = "null";
  })(InputType || (_exports.InputType = InputType = {}));

  let LoopBackValueFormat;
  _exports.LoopBackValueFormat = LoopBackValueFormat;

  (function (LoopBackValueFormat) {
    LoopBackValueFormat["Exact"] = "exact";
    LoopBackValueFormat["Null"] = "null";
    LoopBackValueFormat["Ilike"] = "ilike";
    LoopBackValueFormat["BelongsTo"] = "belongsTo";
    LoopBackValueFormat["Array"] = "array";
  })(LoopBackValueFormat || (_exports.LoopBackValueFormat = LoopBackValueFormat = {}));

  let DisplayValueFormat;
  _exports.DisplayValueFormat = DisplayValueFormat;

  (function (DisplayValueFormat) {
    DisplayValueFormat["Exact"] = "exact";
    DisplayValueFormat["Date"] = "date";
  })(DisplayValueFormat || (_exports.DisplayValueFormat = DisplayValueFormat = {}));

  let FilterValidator;
  /**
   * Set the visibility of a column using this enum.
   */

  _exports.FilterValidator = FilterValidator;

  (function (FilterValidator) {
    FilterValidator["Array"] = "array";
    FilterValidator["Boolean"] = "boolean";
    FilterValidator["Date"] = "date";
    FilterValidator["Null"] = "null";
    FilterValidator["Number"] = "number";
    FilterValidator["String"] = "string";
  })(FilterValidator || (_exports.FilterValidator = FilterValidator = {}));

  let AttributeVisibility;
  /**
   *
   */

  _exports.AttributeVisibility = AttributeVisibility;

  (function (AttributeVisibility) {
    AttributeVisibility["Filter"] = "filter";
    AttributeVisibility["Table"] = "table";
    AttributeVisibility["Both"] = "both";
    AttributeVisibility["Export"] = "export";
  })(AttributeVisibility || (_exports.AttributeVisibility = AttributeVisibility = {}));

  let Direction;
  _exports.Direction = Direction;

  (function (Direction) {
    Direction["Asc"] = "asc";
    Direction["Desc"] = "desc";
  })(Direction || (_exports.Direction = Direction = {}));

  let OptionsResourceAction;
  _exports.OptionsResourceAction = OptionsResourceAction;

  (function (OptionsResourceAction) {
    OptionsResourceAction["FindAll"] = "findAll";
    OptionsResourceAction["Query"] = "query";
  })(OptionsResourceAction || (_exports.OptionsResourceAction = OptionsResourceAction = {}));
});