define("ember-plupload/system/sum-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(target, key) {
    return Ember.A(target).reduce(function (E, obj) {
      return E + Ember.get(obj, key);
    }, 0);
  }
});