define("iris/components/modals/select-project-ticket-template-group/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SelectProjectTicketTemplateGroupModal = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('projectTicketTemplateGroup.projectTicketTemplates.length'), _dec3 = Ember.computed.alias('data.isOperational'), _dec4 = Ember.computed.mapBy('productResults', 'name'), _dec5 = Ember._action, _dec6 = Ember._action, (_class = class SelectProjectTicketTemplateGroupModal extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "projectTicketTemplateGroup", null);

      _defineProperty(this, "createNewProductResult", false);

      _defineProperty(this, "overwriteProductResultName", false);

      _defineProperty(this, "productResults", []);

      _defineProperty(this, "productResultName", '');

      _defineProperty(this, "productResultNameValid", false);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "ticketCount", _descriptor2, this);

      _initializerDefineProperty(this, "isOperational", _descriptor3, this);

      _initializerDefineProperty(this, "productResultNames", _descriptor4, this);
    }

    async didReceiveAttrs() {
      await this.loadProductResults.perform();
    }

    *loadProductResults() {
      const productResult = yield this.api.query('fileProductResult', {
        filter: {
          where: {
            fileId: this.data.fileId
          }
        }
      });
      this.set('productResults', productResult);
    }

    checkProductResultNameValid(name) {
      let valid = false;

      if (name) {
        valid = !this.productResultNames.find(pr => pr === name);
      }

      this.set('productResultNameValid', valid);
    }

    setProductResultName(name) {
      this.checkProductResultNameValid(name);
      this.set('productResultName', name);
    }

    setProjectTicketTemplateGroup(selected) {
      this.set('projectTicketTemplateGroup', selected);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ticketCount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isOperational", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "productResultNames", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadProductResults", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadProductResults"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setProductResultName", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setProductResultName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setProjectTicketTemplateGroup", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setProjectTicketTemplateGroup"), _class.prototype)), _class));
  _exports.default = SelectProjectTicketTemplateGroupModal;
});