define("iris/helpers/sum-resource-plans", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sumArray = sumArray;

  function sumArray(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.extend({
    recomputeOnArrayChange: Ember.observer('_array.[]', 'refresh', function () {
      this.recompute();
    }),
    compute: function (_ref) {
      let [cards, stickyCards, resourcePeriod, dailyAllocatedWorkTime, refresh] = _ref;

      if (!cards) {
        return 0;
      }

      this.set('_array', cards);
      this.set('_refresh', refresh);
      let plans = cards.filter(item => {
        return item.currentPlanPlannedHours;
      });
      const sum = plans.reduce((acc, curr) => {
        return acc + (parseInt(curr['currentPlanPlannedHours']) || 0);
      }, 0);
      const stickyDates = stickyCards.map(sticky => sticky.dates).flat();
      const comparisonValues = stickyDates.map(v => v.valueOf());
      const distinctStickyDates = stickyDates.filter((v, i) => comparisonValues.indexOf(v.valueOf()) == i);
      return sum + distinctStickyDates.length * dailyAllocatedWorkTime || 0;
    }
  });

  _exports.default = _default;
});