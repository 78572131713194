define("ember-aria-tabs/templates/components/aria-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PQjcAxsB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"tabList\",\"tabPanel\"],[[28,\"component\",[\"aria-tab-list\"],[[\"disabledTabClassName\",\"focus\",\"onClick\",\"onKeyDown\",\"panelNodes\",\"parentGuid\",\"selectedIndex\",\"selectedTabClassName\",\"tabNodes\"],[[23,0,[\"disabledTabClassName\"]],[23,0,[\"focus\"]],[28,\"action\",[[23,0,[]],\"handleClick\"],null],[28,\"action\",[[23,0,[]],\"handleKeyDown\"],null],[23,0,[\"panelNodes\"]],[23,0,[\"elementId\"]],[23,0,[\"selectedIndex\"]],[23,0,[\"selectedTabClassName\"]],[23,0,[\"tabNodes\"]]]]],[28,\"component\",[\"aria-tab-panel\"],[[\"forceRender\",\"panelNodes\",\"parentGuid\",\"selectedTabPanelClassName\",\"selectedIndex\",\"tabNodes\"],[[23,0,[\"forceRenderTabPanel\"]],[23,0,[\"panelNodes\"]],[23,0,[\"elementId\"]],[23,0,[\"selectedTabPanelClassName\"]],[23,0,[\"selectedIndex\"]],[23,0,[\"tabNodes\"]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-aria-tabs/templates/components/aria-tabs.hbs"
    }
  });

  _exports.default = _default;
});