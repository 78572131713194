define("iris/components/project/project-tags/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProjectProjectTags = (_dec = Ember.inject.service, _dec2 = Ember.computed('options.@each.name', 'selectedTags.@each.id'), _dec3 = Ember.computed('sortedTags', 'searchTerm.[]'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ProjectProjectTags extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "searchTerm", '');

      _defineProperty(this, "searchProperty", 'name');

      _defineProperty(this, "options", []);

      _defineProperty(this, "selectedTags", []);

      _initializerDefineProperty(this, "api", _descriptor, this);
    }

    didReceiveAttrs() {
      this.loadSelectedTags.perform();
    }

    *loadTags() {
      const tags = yield this.api.findAll('fileTag');
      this.set('options', tags);
    }

    *loadSelectedTags() {
      const rel = this.project.hasMany('fileTags');
      yield rel.reload();
      const selectedTags = yield this.project.get('fileTags');
      this.set('selectedTags', selectedTags);
    }

    get sortedTags() {
      let options = Ember.A(this.get('options'));
      let selectedTags = Ember.A(this.get('selectedTags')); // Filter selected and unselected tags

      let selected = options.filter(tag => selectedTags.includes(tag));
      let unselected = options.filter(tag => !selectedTags.includes(tag)); // Sort both arrays by name

      selected = selected.sortBy('name');
      unselected = unselected.sortBy('name'); // Combine the arrays: selected tags first, then unselected

      return [...selected, ...unselected];
    }

    get filteredOptions() {
      if (!this.searchTerm) {
        return this.sortedTags;
      }

      return this.options.filter(option => Ember.get(option, this.searchProperty).toLowerCase().includes(this.searchTerm.toLowerCase()));
    }

    async toggleOption(option) {
      const selected = this.selectedTags.includes(option);
      const data = {
        fileId: this.project.id,
        tagId: option.id
      };

      if (selected) {
        await this.api.collectionAction('fileTagAssoc', 'remove-tag', {
          method: 'POST',
          data
        });
      } else {
        await this.api.collectionAction('fileTagAssoc', 'add-tag', {
          method: 'POST',
          data
        });
      }

      await this.loadSelectedTags.perform();
    }

    close(dd) {
      dd.actions.close();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadTags", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadTags"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadSelectedTags", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadSelectedTags"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortedTags", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "sortedTags"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filteredOptions", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "filteredOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleOption", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  _exports.default = ProjectProjectTags;
});