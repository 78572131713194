define("ember-plupload/test-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addFiles = addFiles;

  /* global moxie */
  function FakeFile(attrs) {
    this.id = Ember.generateGuid();
    attrs.plupload.total.size += attrs.size;
    Ember.assign(this, attrs);
  }

  FakeFile.prototype = {
    upload(settings) {
      this.settings = settings;
      this.percent = 0;
    },

    respondWith(status, headers, body) {
      let contentType = (headers['Content-Type'] || '').split(';'); // Serialize if JSON

      if (contentType.indexOf('application/json') !== -1 || contentType.indexOf('text/javascript') !== -1 || contentType.indexOf('application/javascript') !== -1) {
        body = JSON.stringify(body);
      }

      let responseHeaders = Object.keys(headers).map(function (key) {
        return `${key}: ${headers[key]}`;
      }).join('\n');
      Ember.run(() => {
        this.queue.fileUploaded(this.plupload, this, {
          status,
          responseHeaders,
          response: body
        });
      });
    },

    get progress() {
      return this.percent;
    },

    set progress(value) {
      this.percent = value;
      Ember.run(() => {
        this.plupload.total.loaded += this.size * (value / 100);
        this.queue.progressDidChange(this.plupload, this);
      });
    },

    getSource() {
      return this;
    }

  };

  moxie.file.FileReader = function () {};

  moxie.file.FileReader.prototype = {
    read(type, source) {
      (false && !(source[type]) && Ember.assert(`"${source.name}" doesn't have a ${type} for the file to read
When calling addFiles(), provide the following property:

addFiles(this.container, "${source.queueName}", {
  name: "${source.name}",
  size: ${source.size},
  ${type}: Ember.RSVP.resolve("your source here")
})`, source[type]));
      source[type].then(result => {
        this.result = result;
        this.onloadend();
      }, error => {
        this.error = error;
        this.onerror();
      });
    },

    readAsArrayBuffer(source) {
      this.read('arrayBuffer', source);
    },

    readAsDataURL(source) {
      this.read('dataURL', source);
    },

    readAsBinaryString(source) {
      this.read('binaryString', source);
    },

    readAsText(source) {
      this.read('text', source);
    }

  };

  function addFiles(queue) {
    for (var _len = arguments.length, files = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      files[_key - 1] = arguments[_key];
    }

    let queueMap = queue.get('queues');
    let finalQueue = null;

    for (let queue of queueMap.values()) {
      finalQueue = queue;
    }

    if (finalQueue === null) {
      throw new Error(`Queue does not exist`);
    }

    let plupload = finalQueue;
    let name = finalQueue.name;
    files = files.map(function (file) {
      return new FakeFile(Ember.assign({
        queue,
        plupload,
        queueName: name
      }, file));
    });
    Ember.run(function () {
      queue.filesAdded(plupload, files);
    });
    return files;
  }
});