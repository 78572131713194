define("@kockpit/ember-gantt/templates/components/gantt-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XE7iEoSa",
    "block": "{\"symbols\":[\"timeline\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"gantt-chart-inner\"],[8],[0,\"\\n\\n\"],[4,\"gantt-timeline\",null,[[\"chart\"],[[23,0,[]]]],{\"statements\":[[0,\"    \"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"header\"]],\"expected `timeline.header` to be a contextual component but found a string. Did you mean `(component timeline.header)`? ('@kockpit/ember-gantt/templates/components/gantt-chart.hbs' @ L5:C7) \"],null]],null,{\"statements\":[[14,2,[[28,\"hash\",null,[[\"header\"],[[28,\"component\",[\"gantt-generic-content\"],null]]]]]]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"gantt-titles-background\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"gantt-lines\"],[8],[0,\"\\n    \"],[14,2,[[28,\"hash\",null,[[\"line\"],[[28,\"component\",[\"gantt-line\"],[[\"chart\"],[[23,0,[]]]]]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@kockpit/ember-gantt/templates/components/gantt-chart.hbs"
    }
  });

  _exports.default = _default;
});