define("iris/components/useful-links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pAGNgd6c",
    "block": "{\"symbols\":[\"link\"],\"statements\":[[7,\"span\",false],[12,\"class\",\"btn btn-default gap-4 items-center justify-center rounded-3xl text-xxl mb-8\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"addItem\"],null]]],[8],[0,\"\\n  \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"external-link\"]]],[0,\"\\n  \"],[1,[28,\"t\",[\"components.useful-links.component-name\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,0,[\"links\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"group\"],[8],[0,\"\\n    \"],[7,\"a\",true],[11,\"href\",[23,1,[\"url\"]]],[10,\"target\",\"_blank\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\n    \"],[7,\"span\",false],[12,\"class\",\"fa fa-pencil cursor-pointer opacity-0 group-hover:opacity-100\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"editLink\",[23,1,[]]],null]]],[8],[9],[0,\"\\n\\n    \"],[7,\"span\",false],[12,\"class\",\"fa fa-trash cursor-pointer opacity-0 group-hover:opacity-100\"],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"deleteLink\",[23,1,[]]],null]]],[8],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/useful-links/template.hbs"
    }
  });

  _exports.default = _default;
});