define("iris/components/dashboard/work-logs/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardWorkLogs = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed(), _dec5 = Ember.computed('sessionAccount.account.theme'), _dec6 = Ember.computed('now'), _dec7 = Ember.computed('now'), _dec8 = Ember.computed('now', 'numberOfDays'), _dec9 = Ember.computed('week'), _dec10 = Ember.computed('week', 'workLogs', 'events'), _dec11 = Ember.computed('logsOfWeekDays'), _dec12 = Ember.computed('values'), _dec13 = Ember.computed('labels', 'datasets'), _dec14 = Ember._action, _dec15 = Ember._action, (_class = class DashboardWorkLogs extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _defineProperty(this, "now", (0, _moment.default)());

      _defineProperty(this, "df", 'YYYY-MM-DD HH:mm:ss');

      _defineProperty(this, "workLogs", []);

      _defineProperty(this, "events", []);

      _defineProperty(this, "numberOfDays", 7);
    }

    get options() {
      return {
        elements: {
          circles: true
        },
        tooltips: {
          enabled: false
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              fontSize: 10,
              autoSkip: false,
              maxRotation: 0
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              color: '#cbd8e4',
              drawBorder: false,
              tickMarkLength: 1,
              zeroLineColor: 'rgba(255,255,255,0.5)'
            },
            ticks: {
              suggestedMax: this.sessionAccount.account.get('dailyAllocatedWorkTime'),
              beginAtZero: true
            }
          }]
        }
      };
    } // ------------------ EC TASKS -----------------


    *getWorkLogs() {
      const workLogs = yield this.store.query('workLog', {
        filter: {
          where: {
            beginDate: {
              between: this.dateRange
            },
            userId: this.sessionAccount.account.id
          }
        }
      });
      const events = yield this.store.query('event', {
        filter: {
          include: ['eventDays'],
          where: {
            and: [{
              userId: this.sessionAccount.account.id
            }, {
              isAccepted: true
            }]
          }
        }
      });
      this.set('workLogs', workLogs);
      this.set('events', events);
    }

    get chevronBGColor() {
      return this.sessionAccount.account.theme ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300';
    } // ------------------ COMPUTED -----------------


    get isThisWeek() {
      return (0, _moment.default)().week() === this.now.week();
    }

    get dateRange() {
      const {
        now,
        df
      } = this;
      return [now.startOf('week').format(df), now.endOf('week').format(df)];
    }

    get week() {
      let start = this.now.startOf('week');
      let end = start.clone().add(this.numberOfDays, 'd');
      let days = [];
      let day = start;

      while (day < end) {
        days[day.format('L')] = {
          worklogValue: 0,
          eventValue: 0
        };
        day = day.clone().add(1, 'd');
      }

      return days;
    }

    get labels() {
      return Object.keys(this.week).map(date => [(0, _moment.default)(date, 'L').format('dddd').capitalize(), `(${date})`]);
    }

    get logsOfWeekDays() {
      const {
        week,
        workLogs,
        events
      } = this;
      const mergedHours = { ...week
      };

      for (const day of Object.keys(mergedHours)) {
        const workLogsOnDay = workLogs.filter(wl => {
          return (0, _moment.default)(wl.beginDate.toISOString()).isSame((0, _moment.default)(day, 'L'), 'd');
        });
        const sumsOfHours = workLogsOnDay.reduce((a, b) => {
          return a + b.spentTime + b.publicSpentTime;
        }, 0);
        mergedHours[day].worklogValue = sumsOfHours;
      }

      events.forEach(event => {
        event.eventDays.forEach(eventDay => {
          const eventDate = (0, _moment.default)(eventDay.date).format('L');

          if (mergedHours.hasOwnProperty(eventDate)) {
            mergedHours[eventDate].eventValue = this.sessionAccount.account.get('dailyAllocatedWorkTime');
          }
        });
      });
      return mergedHours;
    }

    get values() {
      const logs = this.logsOfWeekDays;
      return Object.keys(logs).map(day => ({
        worklogValue: logs[day].worklogValue,
        eventValue: logs[day].eventValue
      }));
    }

    get datasets() {
      const values = this.values;
      return [{
        label: 'Events',
        backgroundColor: '#7AA2CC',
        data: values.map(d => d.eventValue),
        stack: 'Stack 0'
      }, {
        label: 'Work Logs',
        backgroundColor: '#7BC425',
        data: values.map(d => d.worklogValue),
        stack: 'Stack 0'
      }];
    }

    get data() {
      return {
        labels: this.labels,
        datasets: this.datasets
      };
    } // ------------------ ACTIONS -----------------


    async stepForward() {
      this.set('now', this.get('now').clone().add(1, 'w'));
      this.getWorkLogs.perform();
    }

    async stepBack() {
      this.set('now', this.get('now').clone().add(-1, 'w'));
      this.getWorkLogs.perform();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "options", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getWorkLogs", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "getWorkLogs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chevronBGColor", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "chevronBGColor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isThisWeek", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isThisWeek"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateRange", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "dateRange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "week", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "week"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "labels", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "labels"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logsOfWeekDays", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "logsOfWeekDays"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "values", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "values"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "datasets", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "datasets"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "data", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "data"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stepForward", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "stepForward"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stepBack", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "stepBack"), _class.prototype)), _class));
  _exports.default = DashboardWorkLogs;
});