define("ember-table/components/ember-th/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vNkQco/U",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[23,0,[\"columnValue\"]],[23,0,[\"columnMeta\"]],[23,0,[\"rowMeta\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[23,0,[\"columnValue\",\"name\"]],false],[0,\"\\n\\n  \"],[1,[28,\"ember-th/sort-indicator\",null,[[\"columnMeta\"],[[23,0,[\"columnMeta\"]]]]],false],[0,\"\\n\\n  \"],[1,[28,\"ember-th/resize-handle\",null,[[\"columnMeta\"],[[23,0,[\"columnMeta\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-th/template.hbs"
    }
  });

  _exports.default = _default;
});