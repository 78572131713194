define('ember-cronstrue/configuration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const DEFAULTS = {
    defaultLocale: 'en'
  };

  /**
    Ember cRonstrue's configuration object.
    @class Configuration
    @extends Object
    @module ember-cronstrue/configuration
    @public
  */
  exports.default = {
    /**
      The default Locale of the application as configured in `config/environment.js`.
      Supported values are:
      * en - English
      * nl - Dutch
      * fr - French
      * de - German
      * it - Italian
      * nb - Norwegian
      * pl - Polish
      * pt_BR - Portuguese (Brazil)
      * ro - Romanian
      * ru - Russian
      * es - Spanish
      * sv - Swedish
      * tr - Turkish
      * uk - Ukrainian
      * zh_CN - Chinese (Simplified)
      @property defaultLocale
      @static
      @type String
      @default 'en'
      @public
    */
    defaultLocale: DEFAULTS.defaultLocale,

    load(config) {
      for (let property in this) {
        if (this.hasOwnProperty(property) && Ember.typeOf(this[property]) !== 'function') {
          this[property] = Ember.getWithDefault(config, property, DEFAULTS[property]);
        }
      }
    }
  };
});