define('ember-countdown/components/countdown-string', ['exports', 'ember-countdown/templates/components/countdown-string', 'countdownjs', 'ember-countdown/components/countdown-component'], function (exports, _countdownString, _countdownjs, _countdownComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _countdownComponent.default.extend({
    layout: _countdownString.default,

    countdownText() {
      _countdownjs.default.setLabels(this.get('singularLabel'), this.get('pluralLabel'), this.get('lastLabel'), this.get('delimLabel'), this.get('emptyLabel'));
      const newCountdown = (0, _countdownjs.default)(this.get('parsedStartDate'), this.get('parsedEndDate'), this.get('units'), this.get('max'));

      if (this.get('suffix') && newCountdown.value > 0) {
        this.set('text', `${newCountdown.toString()} ${this.get('suffixFromNow')}`);
      } else if (this.get('suffix') && newCountdown.value < 0) {
        this.set('text', `${newCountdown.toString()} ${this.get('suffixToNow')}`);
      } else {
        this.set('text', newCountdown.toString());
      }
    }
  });
});