define("iris/components/useful-links/component", ["exports", "ember-concurrency", "iris/lib/modals"], function (_exports, _emberConcurrency, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UsefulLinks = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class UsefulLinks extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "sourceType", null);

      _defineProperty(this, "sourceId", null);

      _defineProperty(this, "links", null);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "modals", _descriptor3, this);
    }

    async didReceiveAttrs() {
      await this.loadLinks.perform();
    }

    *loadLinks() {
      const {
        sourceType,
        sourceId
      } = this;
      const links = yield this.api.query('usefulLink', {
        filter: {
          where: {
            sourceType,
            sourceId
          }
        }
      });
      this.set('links', links);
    }

    *saveLink(link) {
      link.set('sourceId', this.sourceId);
      const save = yield this.modals.open('useful-links/item', {
        title: this.intl.t('components.useful-links.link'),
        link
      }, {
        className: _modals.ModalLayoutClassName.XL
      });

      if (save === false) {
        if (link.id) {
          link.rollbackAttributes();
        } else {
          yield link.destroyRecord();
        }

        return;
      }

      yield link.save();
      yield this.loadLinks.perform();
    }

    async addItem() {
      const {
        sourceId,
        sourceType
      } = this;
      const link = this.store.createRecord('usefulLink', {
        sourceId,
        sourceType
      });
      await this.saveLink.perform(link);
    }

    async editLink(link) {
      await this.saveLink.perform(link);
    }

    async deleteLink(link) {
      const {
        flashMessages,
        intl
      } = this;
      let shouldDelete = await this.modals.open('beta/modals/confirmation', {
        title: intl.t('general.deletion'),
        body: intl.t('modals.delete-confirmation')
      });

      if (shouldDelete) {
        try {
          await link.destroyRecord(true);
          flashMessages.info(intl.t('general.delete.success'), {
            title: intl.t('general.delete.success-title'),
            icon: 'times'
          });
        } catch (error) {
          flashMessages.danger(intl.t('general.delete.error'), {
            title: intl.t('general.delete.error'),
            icon: 'warning'
          });
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadLinks", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadLinks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveLink", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "saveLink"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addItem", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editLink", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "editLink"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteLink", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "deleteLink"), _class.prototype)), _class));
  _exports.default = UsefulLinks;
});