define("iris/controllers/human-resource-management/my-events", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.daysToRanges = void 0;
  _exports.mapDaysToEvents = mapDaysToEvents;
  _exports.mapStartEndToDays = mapStartEndToDays;
  _exports.toCalendarSavedEvent = toCalendarSavedEvent;
  let ctx = {};

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    routerService: Ember.inject.service('router'),
    flashMessages: Ember.inject.service(),

    async init() {
      this._super(...arguments);

      ctx = this;
      const publicHolidays = await this.get('store').query('publicHoliday', {
        filter: {
          order: 'date'
        }
      });
      this.set('publicHolidays', publicHolidays);
      this.setCurrentUserAsSelected();
      this.refreshEvents();
    },

    legendModal: false,
    selectableUserIds: Ember.computed.mapBy('model.selectableUsers', 'id'),
    currentUser: Ember.computed('session.data.authenticated.userId', function () {
      let currentUserId = this.get('session.data.authenticated.userId');
      return this.get('store').find('account', currentUserId);
    }),
    publicHolidays: Ember.A(),
    calendarEvents: Ember.computed('events.[]', function () {
      return toCalendarSavedEvent(this.events);
    }),
    events: null,
    selectedUsers: Ember.A(),

    async setCurrentUserAsSelected() {
      debugger;
      const currentUserId = this.get('session.data.authenticated.userId');
      const currentUser = await this.store.find('account', currentUserId);

      if (!currentUser) {
        return;
      }

      this.set('selectedUsers', Ember.A([currentUser]));
      this.refreshEvents();
    },

    //calendar related settings
    height: 700,
    customButtons: Ember.computed(function () {
      return {
        addEventButton: {
          text: this.intl.t('hrm.createEvent'),
          click: () => {
            this.send('openEventModal');
          }
        },
        ownEvents: {
          text: this.intl.t('hrm.ownEvents'),
          click: () => {
            let currentUserId = this.get('session.data.authenticated.userId');
            let currentUser = this.get('model.selectableUsers').find(function (user) {
              return user.id === currentUserId;
            });
            this.set('selectedUsers', [currentUser]);
            this.refreshEvents();
          }
        },
        customNext: {
          text: '>',
          click: () => {
            this.notifyPropertyChange('events');
            $('.full-calendar').fullCalendar('next');
          }
        },
        customPrev: {
          text: '<',
          click: () => {
            this.notifyPropertyChange('events');
            $('.full-calendar').fullCalendar('prev');
          }
        },
        openLegend: {
          text: this.intl.t('hrm.types'),
          click: () => {
            this.set('legendModal', true);
          }
        }
      };
    }),

    eventClick(info) {
      ctx.send('transitionTo', {
        route: 'human-resource-management.my-events.edit',
        segment: info.id
      });
    },

    eventRender: function (event, element) {
      let icon = 'question';

      if (event.isAccepted) {
        icon = 'ok';
      }

      if (event.isAccepted === false) {
        icon = 'remove';
      }

      element.find('.fc-title').prepend(`<i class='glyphicon glyphicon-${icon}-sign'></i>  <b>${event.userName}</b> - `);
    },
    dayRender: function (date, cell) {
      let publicHolidays = ctx.get('publicHolidays');
      let cellDay = (0, _moment.default)(date).subtract(1, 'd');

      if ((0, _moment.default)(date).day() === 0 || (0, _moment.default)(date).day() === 6) {
        cell.css('background', '#7AA2CC');
      }

      for (let i = 0; i < publicHolidays.length; i++) {
        let date = publicHolidays.objectAt(i).get('date').toISOString();

        if (cellDay.isSame((0, _moment.default)(date), 'day')) {
          if (!publicHolidays.objectAt(i).get('isWorkDay')) {
            cell.css('background', '#7BC425');
            return;
          } else {
            cell.css('background', '#ddd');
          }
        }
      }
    },
    buttonText: Ember.computed(function () {
      return {
        today: this.intl.t('components.fullcalendar.today'),
        month: this.intl.t('components.fullcalendar.month'),
        listWeek: this.intl.t('components.fullcalendar.listWeek'),
        agendaWeek: this.intl.t('components.fullcalendar.week'),
        day: this.intl.t('components.fullcalendar.day')
      };
    }),
    header: {
      left: 'ownEvents',
      center: 'title',
      right: 'openLegend today month,listWeek customPrev,customNext'
    },
    queryEvents: async (store, selectedUserIds, currentUserId) => {
      if (!selectedUserIds || !selectedUserIds.length) {
        return [];
      }

      if (!selectedUserIds.includes(currentUserId)) {
        currentUserId = '000';
      }

      let filter = {
        filter: {
          include: ['user', 'eventType', 'eventDays'],
          where: {
            or: [{
              userId: currentUserId
            }, {
              and: [{
                userId: {
                  inq: selectedUserIds
                }
              }, {
                isAccepted: true
              }]
            }]
          }
        }
      };
      let events = await store.query('event', filter);
      return events;
    },

    async refreshEvents() {
      if (this.selectedUsers.length === 0) {
        return;
      }

      let selectedUserIds = this.selectedUsers.map(user => user.id);
      let currentUserId = ctx.get('session.data.authenticated.userId');
      await new Promise(r => Ember.run.later(this, r, 1000));
      this.set('events', await this.queryEvents(this.store, selectedUserIds, currentUserId));
    },

    actions: {
      select: function (start, end) {
        if (start.isSameOrBefore((0, _moment.default)())) {
          $('.full-calendar').fullCalendar('unselect');
          return;
        }

        const currentUserId = ctx.get('session.data.authenticated.userId');
        const currentUserAlreadyAcceptedEventDays = this.events.filter(event => event.user.get('id') === currentUserId && event.isAccepted).flatMap(event => event.eventDays.map(eventDay => eventDay.date));

        for (const alreadyAcceptedDay of currentUserAlreadyAcceptedEventDays) {
          console.log('already acc day', (0, _moment.default)(alreadyAcceptedDay).utc());
          console.log('start', start);
          console.log('end', end);

          if ((0, _moment.default)(alreadyAcceptedDay).add(2, 'h').isBetween(start, end, undefined, '[]')) {
            $('.full-calendar').fullCalendar('unselect');
            this.flashMessages.warning(this.intl.t('hrm.already-accepted-holiday'), {
              title: this.intl.t('general.warning'),
              icon: 'warning',
              sticky: true
            });
            return;
          }
        }

        this.send('openEventModal', start, end);
      },

      openEventModal(start, end) {
        if (!start || !end) {
          this.transitionToRoute('/human-resource-management/my-events/new?start=0&end=0');
        } else {
          this.transitionToRoute(encodeURI(`/human-resource-management/my-events/new?start=${start.toISOString()}&end=${end.toISOString()}`));
        }
      },

      toggleLegendModal() {
        this.set('legendModal', !this.get('legendModal'));
      },

      async selectedUsersChanged(selectedUsers) {
        this.set('selectedUsers', selectedUsers);
        this.refreshEvents();
      }

    }
  });

  _exports.default = _default;

  function mapStartEndToDays(start, end) {
    let temp = (0, _moment.default)(start);
    let days = [];

    while (temp.isBefore(end)) {
      days.push(temp.format('YYYY-MM-DD'));
      temp.add(1, 'd');
    }

    return days;
  }

  function mapDaysToEvents(days) {
    if (!days || !days.length) {
      return [];
    }

    let events = [];
    let momentDayList = days.map(day => (0, _moment.default)(day)).sort((a, b) => a.diff(b, 'd'));
    let tempStart = momentDayList[0];

    for (let i = 0; i < momentDayList.length - 1; i++) {
      if (momentDayList[i + 1].diff(momentDayList[i], 'd') > 1) {
        events.push({
          start: tempStart,
          end: momentDayList[i].clone().add(1, 'd')
        });
        tempStart = momentDayList[i + 1];
      }
    }

    events.push({
      start: tempStart,
      end: momentDayList[momentDayList.length - 1].clone().add(1, 'd')
    });
    return events;
  }

  function toCalendarSavedEvent(events) {
    if (!events) {
      return;
    }

    return events.map(event => {
      let days = event.get('eventDays').map(eventDay => eventDay.date);
      return mapDaysToEvents(days).map(_ref => {
        let {
          start,
          end
        } = _ref;
        return {
          id: event.id,
          title: event.title,
          userName: event.get('user.longName'),
          start,
          end,
          color: event.get('eventType.color'),
          allDay: true,
          isAccepted: event.isAccepted
        };
      });
    }).flat();
  }

  const daysToRanges = days => {
    return days.sort().reduce((acc, day) => {
      if (acc.length === 0) {
        acc.push([day]);
        return acc;
      }

      let [currentGroup] = acc.slice(-1);
      let [lastDayInCurrentGroup] = currentGroup.slice(-1); // Add a day to the last day of the current group

      if ((0, _moment.default)(lastDayInCurrentGroup).clone().add(1, 'days').isSame((0, _moment.default)(day))) {
        currentGroup.push(day);
        return acc;
      }

      acc.push([day]);
      return acc;
    }, []).map(group => {
      let [start] = group;
      let [end] = group.slice(-1);

      if (start === end) {
        return {
          start
        };
      }

      return {
        start,
        end
      };
    });
  };

  _exports.daysToRanges = daysToRanges;
});