define("iris/components/seasonal-skin/autumn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q021ByKu",
    "block": "{\"symbols\":[\"empty\"],\"statements\":[[7,\"a\",false],[3,\"on\",[\"click\",[28,\"action\",[[23,0,[]],\"toggleLeaves\"],null]]],[8],[0,\"\\n  \"],[7,\"img\",true],[10,\"src\",\"assets/img/baby_yoda_autunm.png\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"id\",\"leaves\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"repeat\",[[23,0,[\"leafCount\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/seasonal-skin/autumn/template.hbs"
    }
  });

  _exports.default = _default;
});