define("ember-plupload/services/uploader", ["exports", "ember-plupload/system/upload-queue", "ember-plupload/system/flatten"], function (_exports, _uploadQueue, _flatten) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
      @private
      Setup a map of uploaders so they may be
      accessed by name via the `find` method.
     */
    init() {
      this._super(...arguments);

      Ember.set(this, 'queues', new Map());
      Ember.set(this, 'all', Ember.A());
    },

    files: Ember.computed('all.@each.length', {
      get() {
        return (0, _flatten.default)(Ember.get(this, 'all').invoke('toArray'));
      }

    }),
    size: Ember.computed('all.@each.size', {
      get() {
        return Ember.A(Ember.get(this, 'all').getEach('size')).reduce(function (E, x) {
          return E + x;
        }, 0);
      }

    }),
    loaded: Ember.computed('all.@each.loaded', {
      get() {
        return Ember.A(Ember.get(this, 'all').getEach('loaded')).reduce(function (E, x) {
          return E + x;
        }, 0);
      }

    }),
    progress: Ember.computed('size', 'loaded', function () {
      let percent = Ember.get(this, 'loaded') / Ember.get(this, 'size') || 0;
      return Math.floor(percent * 100);
    }),

    /**
      Return or instantiate a new plupload instance
      for an upload queue.
       @method find
      @param {String} name The name of the queue to find
      @param {Object} [config] The configuration to use for the uploader
     */
    findOrCreate(name, component, config) {
      var queue;
      let queues = Ember.get(this, 'queues');

      if (queues.has(name)) {
        queue = queues.get(name);

        if (config != null) {
          Ember.set(queue, 'target', component);
          queue.configure(config);
        }
      } else {
        queue = _uploadQueue.default.create({
          name: name,
          target: component
        });
        Ember.get(this, 'all').pushObject(queue);
        queues.set(name, queue);
        queue.configure(config);
      }

      return queue;
    }

  });

  _exports.default = _default;
});