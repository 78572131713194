define("iris/components/tables/wazuh-report/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qgm6sHT2",
    "block": "{\"symbols\":[\"c\",\"@filter\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@noCreate\",\"@noActions\",\"@isRender\",\"@externalRowActions\",\"@externalBulkActions\",\"@defaultFilters\",\"@neededFields\",\"@columnOverwrites\"],[\"wazuh-report-line\",true,true,true,[23,0,[\"rowActions\"]],[23,0,[\"bulkActions\"]],[23,2,[]],[23,0,[\"neededFields\"]],[23,0,[\"columns\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"severity\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"border flex-1 px-4 py-1 flex items-center justify-center w-1/2\\n      font-bold rounded-full\\n      \",[28,\"if\",[[28,\"eq\",[[23,1,[\"row\",\"severity\"]],\"Critical\"],null],\"border-scarlet-600 text-danger\",[28,\"if\",[[28,\"eq\",[[23,1,[\"row\",\"severity\"]],\"High\"],null],\"border-orange-600 text-warning\",\"dark:border-gray-500\"],null]],null]]]],[8],[0,\"\\n      \"],[1,[23,1,[\"row\",\"severity\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"state\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"border flex-1 px-4 py-1 flex items-center justify-center w-1/2\\n      font-bold rounded-full\\n      \",[28,\"if\",[[28,\"eq\",[[23,1,[\"row\",\"state\"]],\"inactive\"],null],\"dark:border-gray-500\",\"border-green-600 text-success\"],null]]]],[8],[0,\"\\n      \"],[1,[23,1,[\"row\",\"state\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/tables/wazuh-report/template.hbs"
    }
  });

  _exports.default = _default;
});