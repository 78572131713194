define("iris/routes/finances/invoices/correcting-invoice", ["exports", "iris/routes/finances/invoices/new", "iris/mixins/protected-route"], function (_exports, _new, _protectedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend(_protectedRoute.default, {
    intl: Ember.inject.service(),
    correcting: true,
    templateName: 'finances/invoices/new',

    async createInvoice(params) {
      let store = this.store;
      let originalInvoice = await store.findRecord('invoice', params.invoice_id);
      let invoiceType = await store.findRecord('invoiceType', 'SYS_03'); // burnt in

      let correctingInvoice = await originalInvoice.copy(true, {
        ignoreAttributes: ['createdBy', 'sentBy']
      });
      correctingInvoice.set('invoiceType', invoiceType);
      correctingInvoice.set('parent', originalInvoice);
      correctingInvoice.set('datePaid', null);
      correctingInvoice.set('transactionId', null);
      correctingInvoice.set('navStatus', null);
      let items = await correctingInvoice.get('items');
      let originalItems = await originalInvoice.get('items'); // remove empty item

      let empty = items.find(item => {
        return !item.get('quantity');
      });
      items.removeObject(empty);
      items.forEach((item, i) => {
        let parent = originalItems.objectAt(i);
        item.set('parent', parent);
        item.set('isCorrectionItem', true);
        item.set('quantity', item.get('quantity') * -1);
      });
      correctingInvoice.set('accountNumber', null);
      return correctingInvoice;
    },

    setupController(controller, model) {
      this._super(controller, model);

      const accountNumber = model.invoice.get('parent.accountNumber');
      controller.set('title', this.intl.t('invoice.correcting-invoice', {
        accountNumber
      }));
    }

  });

  _exports.default = _default;
});