define("ember-plupload/components/pl-uploader", ["exports", "jquery", "dinosheets", "ember-plupload/system/trim", "ember-plupload/computed/w"], function (_exports, _jquery, _dinosheets, _trim, _w) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var keys = Object.keys;

  var isDragAndDropSupported = function () {
    var supported = null;
    return function (runtimes) {
      if (runtimes.indexOf('html5') === -1) {
        return false;
      }

      if (supported == null) {
        supported = 'draggable' in document.createElement('span');
      }

      return supported;
    };
  }();

  var styleSheet;

  var sharedStyleSheet = function () {
    if (styleSheet == null) {
      styleSheet = new _dinosheets.default();
    }

    return styleSheet;
  };

  var slice = Array.prototype.slice;

  var _default = Ember.Component.extend({
    classNames: ['pl-uploader'],
    name: null,
    'for-dropzone': null,
    onfileadd: null,
    onerror: null,
    uploader: Ember.inject.service(),

    /**
      A cascading list of runtimes to fallback on to
      for uploading files with.
       @property runtimes
      @type String[]
      @default ['html5', 'html4', 'flash', 'silverlight']
     */
    runtimes: (0, _w.default)(['html5', 'html4', 'flash', 'silverlight']),
    extensions: (0, _w.default)(),
    'max-file-size': 0,
    'no-duplicates': false,
    multiple: true,
    'unique-names': false,
    'send-browser-cookies': false,
    'send-file-name': null,
    dropzone: Ember.computed('for-dropzone', {
      get() {
        var dropzone = {};
        var id = Ember.get(this, 'for-dropzone') || 'dropzone-for-' + Ember.get(this, 'elementId');
        dropzone.enabled = false;

        if (isDragAndDropSupported(Ember.get(this, 'runtimes'))) {
          dropzone.enabled = true;
          dropzone.id = id;
          dropzone.data = null;
          dropzone['drag-and-drop'] = {
            'dropzone-id': id,
            'drag-data': null
          };
        }

        return dropzone;
      }

    }),
    config: Ember.computed({
      get() {
        var config = {
          url: true,
          // Required to init plupload
          browse_button: Ember.get(this, 'for'),
          filters: {
            max_file_size: Ember.get(this, 'max-file-size'),
            prevent_duplicates: Ember.get(this, 'no-duplicates')
          },
          multi_selection: Ember.get(this, 'multiple'),
          runtimes: Ember.get(this, 'runtimes').join(','),
          container: Ember.get(this, 'elementId'),
          flash_swf_url: this.BASE_URL + 'Moxie.swf',
          silverlight_xap_url: this.BASE_URL + 'Moxie.xap',
          unique_names: Ember.get(this, 'unique-names'),
          required_features: {}
        };

        if (Ember.get(this, 'send-browser-cookies')) {
          config.required_features.send_browser_cookies = true;
        }

        if (Ember.get(this, 'send-file-name') != null) {
          config.send_file_name = Ember.get(this, 'send-file-name');
        }

        var filters = Ember.get(this, 'fileFilters') || {};
        keys(filters).forEach(filter => {
          if (Ember.get(this, filter)) {
            config.filters[filter] = Ember.get(this, filter);
          }
        });

        if (isDragAndDropSupported(Ember.get(this, 'runtimes'))) {
          config.drop_element = Ember.get(this, 'dropzone.id');
        }

        if (Ember.get(this, 'extensions.length')) {
          config.filters.mime_types = [{
            extensions: Ember.get(this, 'extensions').map(function (ext) {
              return ext.toLowerCase();
            }).join(',')
          }];
        }

        return config;
      }

    }),

    didInsertElement() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'attachUploader');
      Ember.run.scheduleOnce('afterRender', this, 'setupDragListeners');
    },

    attachUploader() {
      let uploader = Ember.get(this, 'uploader');
      let name = Ember.get(this, 'name');
      let queue = uploader.findOrCreate(name, this, Ember.get(this, 'config'));
      Ember.set(this, 'queue', queue); // Send up the pluploader object so the app implementing this component has access to it

      let pluploader = queue.get('queues.firstObject');

      if (this.onInitOfUploader) {
        this.onInitOfUploader(pluploader, queue, this);
      }

      this._dragInProgress = false;

      this._invalidateDragData();
    },

    setupDragListeners() {
      var dropzoneId = Ember.get(this, 'dropzone.id');

      if (dropzoneId) {
        var handlers = this.eventHandlers = {
          dragenter: Ember.run.bind(this, 'enteredDropzone'),
          dragleave: Ember.run.bind(this, 'leftDropzone')
        };
        keys(handlers).forEach(function (key) {
          (0, _jquery.default)(document).on(key, '#' + dropzoneId, handlers[key]);
        });
      }
    },

    willDestroyElement() {
      this.detachUploader();
      this.teardownDragListeners();
    },

    detachUploader() {
      let queue = Ember.get(this, 'queue');

      if (queue) {
        queue.orphan();
        Ember.set(this, 'queue', null);
      }

      let sheet = sharedStyleSheet();
      sheet.css(`#${Ember.get(this, 'dropzone.id')} *`, null);
      sheet.applyStyles();
    },

    teardownDragListeners() {
      let dropzoneId = Ember.get(this, 'dropzone.id');

      if (dropzoneId) {
        let handlers = this.eventHandlers;
        keys(handlers).forEach(function (key) {
          (0, _jquery.default)(document).off(key, '#' + dropzoneId, handlers[key]);
        });
        this.eventHandlers = null;
      }
    },

    dragData: null,

    enteredDropzone(_ref) {
      let {
        originalEvent: evt
      } = _ref;

      if (this._dragInProgress === false) {
        this._dragInProgress = true;
        this.activateDropzone(evt);
      }
    },

    leftDropzone() {
      if (this._dragInProgress === true) {
        this._dragInProgress = false;
        this.deactivateDropzone();
      }
    },

    activateDropzone(evt) {
      let sheet = sharedStyleSheet();
      sheet.css(`#${Ember.get(this, 'dropzone.id')} *`, {
        pointerEvents: 'none'
      });
      Ember.run.scheduleOnce('render', sheet, 'applyStyles');
      Ember.set(this, 'dragData', Ember.get(evt, 'dataTransfer'));
    },

    deactivateDropzone() {
      let sheet = sharedStyleSheet();
      sheet.css(`#${Ember.get(this, 'dropzone.id')} *`, null);
      Ember.run.scheduleOnce('render', sheet, 'applyStyles');
      this._dragInProgress = false;
      Ember.set(this, 'dragData', null);
    },

    _invalidateDragData: Ember.observer('queue.length', function () {
      // Looks like someone dropped a file
      const filesAdded = Ember.get(this, 'queue.length') > this._queued;

      const filesDropped = Ember.get(this, 'queue.length') === this._queued;

      if ((filesAdded || filesDropped) && Ember.get(this, 'dragData')) {
        this.deactivateDropzone();
      }

      this._queued = Ember.get(this, 'queue.length');
      Ember.run.scheduleOnce('afterRender', this, 'refreshQueue');
    }),

    refreshQueue() {
      let queue = this.get('queue');

      if (queue) {
        queue.refresh();
      }
    },

    setDragDataValidity: Ember.observer('dragData', Ember.on('init', function () {
      if (!isDragAndDropSupported(Ember.get(this, 'runtimes'))) {
        return;
      }

      let data = Ember.get(this, 'dragData');
      let extensions = Ember.get(this, 'extensions');
      let isValid = true; // Validate

      if (extensions.length) {
        isValid = slice.call(Ember.get(data || {}, 'items') || []).every(function (item) {
          let fileType = (0, _trim.default)(item.type).toLowerCase();
          return extensions.any(function (ext) {
            return new RegExp(ext + '$').test(fileType);
          });
        });
      }

      if (data) {
        Ember.set(this, 'dropzone.active', true);
        Ember.set(this, 'dropzone.valid', isValid);
      } else {
        Ember.set(this, 'dropzone.active', false);
        Ember.set(this, 'dropzone.valid', null);
      }
    }))
  });

  _exports.default = _default;
});