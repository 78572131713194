define("ember-keycloak-auth/templates/components/keycloak-session-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yoHm28BW",
    "block": "{\"symbols\":[\"profile\",\"role\"],\"statements\":[[4,\"if\",[[24,[\"session\",\"ready\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-info\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"loadUserProfile\"]],[8],[0,\"Load user profile\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"with\",[[24,[\"session\",\"profile\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"table\",true],[10,\"class\",\"table\"],[8],[0,\"\\n      \"],[7,\"tbody\",true],[8],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"th\",true],[8],[0,\"Id\"],[9],[0,\"\\n          \"],[7,\"td\",true],[10,\"class\",\"text-nowrap\"],[8],[1,[23,1,[\"id\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"th\",true],[8],[0,\"Username\"],[9],[0,\"\\n          \"],[7,\"td\",true],[10,\"class\",\"text-nowrap\"],[8],[1,[23,1,[\"username\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"th\",true],[8],[0,\"Email\"],[9],[0,\"\\n          \"],[7,\"td\",true],[10,\"class\",\"text-nowrap\"],[8],[1,[23,1,[\"email\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"th\",true],[8],[0,\"Roles\"],[9],[0,\"\\n          \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"roles\"]]],null,{\"statements\":[[0,\"              \"],[7,\"p\",true],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  No session\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-keycloak-auth/templates/components/keycloak-session-profile.hbs"
    }
  });

  _exports.default = _default;
});