define("iris/helpers/sum-available-work-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sumArray = sumArray;

  function sumArray(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.extend({
    compute: function (_ref) {
      let [dailyAllocatedWorkTime, startDate, endDate, publicHolidays] = _ref;
      let sum = 0;
      let numberizedDAWT = parseFloat(dailyAllocatedWorkTime);
      let tempDate = (0, _moment.default)(startDate).clone();

      while (tempDate.isSameOrBefore((0, _moment.default)(endDate), 'day')) {
        let foundPublicHoliday = publicHolidays.find(pb => (0, _moment.default)(pb.date).isSame(tempDate, 'd'));

        if (foundPublicHoliday) {
          if (foundPublicHoliday.isWorkDay) {
            sum += numberizedDAWT;
          }
        } else if (tempDate.day() !== 0 && tempDate.day() !== 6) {
          sum += numberizedDAWT;
        }

        tempDate = tempDate.add(1, 'd');
      }

      return sum;
    }
  });

  _exports.default = _default;
});