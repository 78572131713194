define("@kockpit/ember-gantt/components/gantt-timeline", ["exports", "@kockpit/ember-gantt/utils/date-util", "@kockpit/ember-gantt/templates/components/gantt-timeline"], function (_exports, _dateUtil, _ganttTimeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _ganttTimeline.default,
    classNames: ['gantt-timeline'],
    chart: null,
    viewStartDate: Ember.computed.alias('chart.viewStartDate'),
    viewEndDate: Ember.computed.alias('chart.viewEndDate'),
    // in-page styles
    dayWidth: Ember.computed.alias('chart.dayWidth'),
    dayWidthPx: Ember.computed('dayWidth', function () {
      return Ember.String.htmlSafe(`${Ember.get(this, 'dayWidth')}px`);
    }),
    cwWidthPx: Ember.computed('dayWidth', function () {
      let width = Ember.get(this, 'dayWidth') * 7;
      return Ember.String.htmlSafe(`${width}px`);
    }),
    // dayClasses - special day classes (e.g. today)
    showToday: Ember.computed.alias('chart.showToday'),
    dayClasses: Ember.computed.alias('chart.dayClasses'),
    specialDays: Ember.computed('dayClasses', function () {
      // special timestamp index
      let days = {};
      Ember.get(this, 'dayClasses').forEach(day => {
        days[_dateUtil.default.getNewDate(day.date).getTime()] = day;
      });
      return days;
    }),
    // sticky header
    headerElement: null,
    headerTitle: Ember.computed.alias('chart.headerTitle'),
    scrollLeft: Ember.computed.alias('chart.scrollLeft'),
    stickyOffset: Ember.computed.alias('chart.stickyOffset'),
    stickyStyle: Ember.String.htmlSafe(''),
    stickyPlaceholderStyle: Ember.String.htmlSafe(''),
    isSticky: false,

    // use document scroll event to check for sticky
    init() {
      this._super(...arguments);

      this._handleDocScroll = Ember.run.bind(this, this.checkSticky);
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.set(this, 'headerElement', this.element.querySelector('.gantt-chart-header')); // init sticky

      if (!Ember.isNone(Ember.get(this, 'stickyOffset'))) {
        document.addEventListener('scroll', this._handleDocScroll);
      } // init timeline scale


      if (Ember.get(this, 'autoTimeline')) {
        this.evaluateTimlineElements();
      }
    },

    willDestroyelement() {
      this._super(...arguments);

      if (!Ember.isNone(Ember.get(this, 'stickyOffset'))) {
        document.removeEventListener('scroll', this._handleDocScroll);
      }
    },

    // STICKY
    // -------------------
    checkSticky() {
      let headerElement = Ember.get(this, 'headerElement');
      let chartElement = Ember.get(this, 'chart.element');
      let offset = 0;
      let chartBottom = 1;

      if (headerElement && chartElement) {
        offset = headerElement.getBoundingClientRect().top;
        let chartOffset = chartElement.getBoundingClientRect();
        chartBottom = chartOffset.top + chartOffset.height - 100 || 1;
      }

      if (!Ember.get(this, 'isSticky') && offset < Ember.get(this, 'stickyOffset') && chartBottom >= 100) {
        this.makeSticky();
      } else if (Ember.get(this, 'isSticky') && (offset > Ember.get(this, 'stickyOffset') || chartBottom < 100)) {
        this.resetSticky();
      }
    },

    onResize: Ember.observer('ganttWidth', function () {
      this.updateSticky();
    }),

    makeSticky() {
      Ember.set(this, 'isSticky', true);
      this.updateSticky();
    },

    updateSticky() {
      if (Ember.get(this, 'isSticky')) {
        let stickyOffset = Ember.get(this, 'stickyOffset');
        let ganttWidth = Ember.get(this, 'chart.ganttWidth');
        let ganttLeft = Ember.get(this, 'chart.element').getBoundingClientRect().left;
        let headerHeight = Ember.get(this, 'headerElement.offsetHeight');
        Ember.set(this, 'stickyStyle', Ember.String.htmlSafe(`top:${stickyOffset}px;left:${ganttLeft}px;width:${ganttWidth}px;height:${headerHeight}px;`));
        Ember.set(this, 'stickyPlaceholderStyle', Ember.String.htmlSafe(`height:${headerHeight}px;`));
      }
    },

    resetSticky() {
      Ember.set(this, 'isSticky', false);
      Ember.set(this, 'stickyStyle', Ember.String.htmlSafe(''));
      Ember.set(this, 'stickyPlaceholderStyle', Ember.String.htmlSafe(''));
    },

    scaleWidth: 0,
    // is calculated in scale-grid generation
    // timeline scroll needs to be manually adjusted, as position-fixed does not inherit scrolling
    scaleStyle: Ember.computed('scaleWidth', 'isSticky', 'scrollLeft', function () {
      // total width
      let style = `width:${Ember.get(this, 'scaleWidth')}px;`;

      if (Ember.get(this, 'isSticky')) {
        style += `left:-${Ember.get(this, 'scrollLeft')}px;`;
      }

      return Ember.String.htmlSafe(style);
    }),
    // Activate automatical timeline view adjustments, based on dayWidth
    autoTimeline: Ember.computed.alias('chart.autoTimeline'),
    timelineDay: Ember.computed.alias('chart.timelineDay'),
    timelineCW: Ember.computed.alias('chart.timelineCW'),
    timelineMonth: Ember.computed.alias('chart.timelineMonth'),
    timelineMonthShort: Ember.computed.alias('chart.timelineMonthShort'),
    timelineYear: Ember.computed.alias('chart.timelineYear'),
    autoViewObs: Ember.observer('dayWidth', 'autoTimeline', function () {
      if (Ember.get(this, 'autoTimeline')) {
        this.evaluateTimlineElements();
      }
    }),

    evaluateTimlineElements() {
      let dayWidth = Ember.get(this, 'dayWidth');
      let views = {
        timelineDay: true,
        timelineCW: true,
        timelineMonth: true,
        timelineMonthShort: false,
        timelineYear: false
      };

      if (dayWidth < 20) {
        // cw's instead of days
        views.timelineDay = false;
        views.timelineCW = true;
      }

      if (dayWidth < 15) {// months
        // views.timelineMonth = true;
      }

      if (dayWidth < 10) {
        // months (small)
        views.timelineYear = true;
        views.timelineMonthShort = true;
      }

      if (dayWidth < 5) {
        // year/month
        views.timelineYear = true;
        views.timelineCW = false;
      }

      if (dayWidth < 2) {
        // year only
        views.timelineYear = true;
        views.timelineMonth = false;
      }

      Ember.setProperties(this, views);
    },

    calculateScaleWidth(dayWidth, start, end) {
      return dayWidth * parseInt(_dateUtil.default.diffDays(start, end, true));
    },

    // FIXME: workaround for ember/no-side-effects
    updateScaleWidth(scaleWidth) {
      Ember.set(this, 'scaleWidth', scaleWidth);
    },

    timelineScale: Ember.computed('viewStartDate', 'viewEndDate', 'dayWidth', 'specialDays', 'chart.ganttWidth', function () {
      const chart = Ember.get(this, 'chart');

      let start = _dateUtil.default.getNewDate(Ember.get(this, 'viewStartDate')),
          end = _dateUtil.default.getNewDate(Ember.get(this, 'viewEndDate')),
          dayWidth = Ember.get(this, 'dayWidth');

      if (!start || !end || !(start < end)) {
        return [];
      } // calculate total scale width


      let scaleWidth = this.calculateScaleWidth(dayWidth, start, end);

      if (scaleWidth < Ember.get(chart, 'ganttWidth')) {
        end = chart.offsetToDate(Ember.get(chart, 'ganttWidth') * 1.5);
        scaleWidth = this.calculateScaleWidth(dayWidth, start, end);
      }

      this.updateScaleWidth(scaleWidth);
      return {
        months: _dateUtil.default.monthsInPeriod(start, end, dayWidth, Ember.get(this, 'specialDays')),
        calendarWeeks: Ember.get(this, 'timelineCW') ? _dateUtil.default.calendarWeeksInPeriod(start, end, dayWidth) : null,
        years: Ember.get(this, 'timelineYear') ? _dateUtil.default.yearsInPeriod(start, end, dayWidth) : null
      };
    })
  });

  _exports.default = _default;
});