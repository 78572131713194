define("ember-changeset/utils/computed/inflate", ["exports", "ember-changeset/utils/is-object", "ember-deep-set"], function (_exports, _isObject, _emberDeepSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = inflate;
  const {
    keys
  } = Object;
  /**
   * Inflate an Object, optionally transforming each key's value by
   * `transform` function.
   */

  function inflate(dependentKey) {
    let transform = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : a => a;
    return Ember.computed(dependentKey, function () {
      let obj = Ember.get(this, dependentKey);
      Ember.runInDebug(() => {
        keys(obj).forEach(key => {
          key.split('.').forEach((_part, i, allParts) => {
            if (i < allParts.length - 1) {
              let path = allParts.slice(0, i + 1).join('.');
              let msg = `Path ${path} leading up to ${key} must be an Object if specified.`;
              (false && !((0, _isObject.default)(obj[path]) || Ember.isBlank(obj[path])) && Ember.assert(msg, (0, _isObject.default)(obj[path]) || Ember.isBlank(obj[path])));
            }
          });
        });
      });
      let result = keys(obj).sort().reduce((inflatedObj, key) => {
        (0, _emberDeepSet.default)(inflatedObj, key, transform(obj[key]));
        return inflatedObj;
      }, {});
      return result;
    }).readOnly();
  }
});