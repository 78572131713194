define("ember-plupload/system/flatten", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function flatten(array) {
    var flattened = [];

    for (let i = 0, len = array.length; i < len; i++) {
      let value = array[i];

      if (Ember.isArray(value)) {
        flattened.push(...flatten(value));
      } else {
        flattened.push(value);
      }
    }

    return flattened;
  }

  var _default = flatten;
  _exports.default = _default;
});