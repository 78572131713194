define("iris/components/modals/cmdb-mismatch-partner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mQRkmBYD",
    "block": "{\"symbols\":[\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"cmdb.mismatch-partner.modal-name\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"italic mb-12\"],[8],[1,[28,\"t\",[\"cmdb.mismatch-partner.modal-info\"],null],false],[9],[0,\"\\n    \"],[5,\"modals/cmdb-mismatch-partner/tree\",[],[[\"@nodes\"],[[23,1,[\"mismatchPartners\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@disabled\",\"@text\"],[\"info\",[28,\"action\",[[23,0,[]],\"inheritPartner\"],null],[23,0,[\"disabled\"]],[28,\"or\",[[23,1,[\"cancelButtonLabel\"]],[28,\"t\",[\"cmdb.mismatch-partner.button-inheritance\"],null]],null]]]],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@disabled\",\"@text\"],[\"primary\",[28,\"fn\",[[23,2,[]],true],null],[23,0,[\"disabled\"]],[28,\"or\",[[23,1,[\"cancelButtonLabel\"]],[28,\"t\",[\"general.continue\"],null]],null]]]],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@disabled\",\"@text\"],[\"default\",[28,\"fn\",[[23,2,[]],false],null],[23,0,[\"disabled\"]],[28,\"or\",[[23,1,[\"cancelButtonLabel\"]],[28,\"t\",[\"general.abort\"],null]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/cmdb-mismatch-partner/template.hbs"
    }
  });

  _exports.default = _default;
});