define("iris/components/modals/cmdb-mismatch-partner/tree/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "asPCSMED",
    "block": "{\"symbols\":[\"node\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"jstree jstree-3 jstree-default\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"jstree-container-ul jstree-children\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"nodes\"]]],null,{\"statements\":[[0,\"      \"],[5,\"modals/cmdb-mismatch-partner/tree/node\",[],[[\"@node\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/modals/cmdb-mismatch-partner/tree/template.hbs"
    }
  });

  _exports.default = _default;
});