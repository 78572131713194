define("ember-promise-modals/modal", ["exports", "@ember/test-waiters"], function (_exports, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    _result: undefined,
    _deferredOutAnimation: undefined,
    _componentInstance: undefined,

    init() {
      this._super(...arguments);

      this._service = this.service;
      this._name = this.name;
      this._data = this.data;
      this._options = this.options ?? {};
      this._deferred = Ember.RSVP.defer();
    },

    result: Ember.computed.reads('_result'),
    isClosing: Ember.computed('_deferredOutAnimation', function () {
      return Boolean(this._deferredOutAnimation);
    }),

    close(result) {
      if (this._componentInstance) {
        this._componentInstance.closeModal(result);
      }
    },

    then(onFulfilled, onRejected) {
      return this._deferred.promise.then(onFulfilled, onRejected);
    },

    _resolve(result) {
      if (!this._deferredOutAnimation) {
        Ember.set(this, '_deferredOutAnimation', Ember.RSVP.defer());

        if (this._options.onAnimationModalOutEnd) {
          this._deferredOutAnimation.promise.then(() => this._options.onAnimationModalOutEnd()).catch(() => {});
        }

        this._result = result;

        this._deferred.resolve(result);

        (0, _testWaiters.waitForPromise)(this._deferredOutAnimation.promise);
      }
    },

    _remove() {
      this._service._stack.removeObject(this);

      if (this._service._stack.length === 0) {
        this._service._onLastModalRemoved();
      }

      this._componentInstance = undefined;

      if (this._deferredOutAnimation) {
        this._deferredOutAnimation.resolve();
      }
    }

  });

  _exports.default = _default;
});