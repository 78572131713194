define('ember-pad/helpers/pad-start', ['exports', 'ember-pad/utils/pad'], function (exports, _pad) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.padStart = padStart;
  function padStart(params) {
    return (0, _pad.padStart)(params[0], params[1], params[2]);
  }

  exports.default = Ember.Helper.helper(padStart);
});