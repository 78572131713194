define("iris/models/useful-link", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 254
    }), (0, _emberCpValidations.validator)('actual-length', {
      min: 1
    })],
    url: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('format', {
      regex: /\b(https?|ftp|file):\/\/[\-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[\-A-Za-z0-9+&@#\/%=~_|]/,
      messageKey: 'errors.url'
    })],
    sourceType: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    sourceId: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    sourceType: (0, _attr.default)('string'),
    sourceId: (0, _attr.default)('string')
  });

  _exports.default = _default;
});