define("iris/components/forms/entity-form-hardware", ["exports", "iris/lib/modals"], function (_exports, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    api: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    entityForm: Ember.inject.service(),
    intl: Ember.inject.service(),
    modals: Ember.inject.service(),
    mode: 'entity',
    showUploadModal: false,
    tabs: Ember.computed('mode', 'model.monitoringStartDate', 'model.wazuh', function () {
      let mode = this.get('mode');
      let monitoring = this.get('model.monitoringStartDate');
      let wazuh = this.get('model.wazuh');
      let res = [];

      if (mode === 'entity') {
        res = [{
          name: this.intl.t('entity-type.entityAttributes'),
          route: 'cmdb.entity-types.entities.' + mode + '.attributes'
        }, {
          name: this.intl.t('entity-type.relations'),
          route: 'cmdb.entity-types.entities.' + mode + '.relations'
        }, {
          name: this.intl.t('cmdb.software'),
          route: 'cmdb.entity-types.entities.' + mode + '.software'
        }, {
          name: this.intl.t('cmdb.licence'),
          route: 'cmdb.entity-types.entities.' + mode + '.licenses'
        }, {
          name: this.intl.t('entity-type.otherData'),
          route: 'cmdb.entity-types.entities.' + mode + '.overview'
        }, {
          name: this.intl.t('entity-type.contacts'),
          route: 'cmdb.entity-types.entities.' + mode + '.contacts'
        }, {
          name: this.intl.t('entity-type.locations'),
          route: 'cmdb.entity-types.entities.' + mode + '.sites'
        }, {
          name: this.intl.t('entity-type.moves'),
          route: 'cmdb.entity-types.entities.' + mode + '.moves'
        }, {
          name: this.intl.t('forms.wiki-knowledge-base'),
          route: 'cmdb.entity-types.entities.' + mode + '.xwiki'
        }, {
          name: this.intl.t('projects.files.tabs.attachments'),
          route: 'cmdb.entity-types.entities.' + mode + '.documents'
        }];

        if (monitoring) {
          res.splice(3, 0, {
            name: this.intl.t('cmdb-visualisation.procedure'),
            route: 'cmdb.entity-types.entities.' + mode + '.procedures'
          });
        }

        if (wazuh) {
          res.splice(3, 0, {
            name: this.intl.t('menus.cmdb.wazuh'),
            route: 'cmdb.entity-types.entities.' + mode + '.wazuh'
          });
        }
      } else {
        res = [{
          name: this.intl.t('entity-type.entityAttributes'),
          route: 'cmdb.entity-types.entities.' + mode + '.attributes'
        }, {
          name: this.intl.t('entity-type.relations'),
          route: 'cmdb.entity-types.entities.' + mode + '.relations'
        }, {
          name: this.intl.t('cmdb.software'),
          route: 'cmdb.entity-types.entities.' + mode + '.software'
        }, {
          name: this.intl.t('entity-type.otherData'),
          route: 'cmdb.entity-types.entities.' + mode + '.overview'
        }, {
          name: this.intl.t('entity-type.contacts'),
          route: 'cmdb.entity-types.entities.' + mode + '.contacts'
        }, {
          name: this.intl.t('entity-type.locations'),
          route: 'cmdb.entity-types.entities.' + mode + '.sites'
        }];
      }

      return res;
    }),
    siteZoneOptions: Ember.computed('siteZones.[]', 'model.partner.id', 'sessionAccount.region', function () {
      let region = this.get('sessionAccount.region');
      let result = Ember.A();
      region.get('ownerPartner').then(ownerPartner => {
        if (ownerPartner.id != this.get('model.partner.id')) {
          ownerPartner.get('siteZones').then(siteZones => {
            siteZones.forEach(siteZone => {
              if (siteZone.get('default')) {
                result.pushObject(siteZone);
              }
            });
          });
        }
      });
      let partnerZones = this.get('siteZones').filter(siteZone => {
        return siteZone.get('partner.id') === this.get('model.partner.id');
      });
      result.pushObjects(partnerZones);
      return result;
    }),
    newManufacturerBox: false,
    newTypeBox: false,
    actions: {
      async beforeSave(entity, _, cb) {
        const mismatchPartners = await this.api.memberAction('entity', 'check-mismatch-partner', entity.id, {
          method: 'GET'
        });

        if (Object.keys(mismatchPartners).length === 0) {
          return cb();
        }

        const partnerId = entity.belongsTo('partner').id();
        const result = await this.modals.open('modals/cmdb-mismatch-partner', {
          mismatchPartners,
          partnerId
        }, {
          className: _modals.ModalLayoutClassName.L
        });

        if (result === false) {
          return;
        }

        cb();
      },

      zoneAlert: function () {
        const flashMessages = Ember.get(this, 'flashMessages');
        flashMessages.warning(this.intl.t('entity.monitoringWarning'), {
          title: this.intl.t('general.warning'),
          icon: 'warning',
          sticky: true
        });
      },
      changeZone: function (selected) {
        this.set('model.mustDeletWhenUpdateMonitoring', 1);

        if (this.get('mode') === 'entity' && this.get('model.monitoringStartDate')) {
          this.send('zoneAlert');
        }

        this.set('model.siteZone', selected);
      },
      changePartner: function (selected) {
        this.entityForm.setPartner(selected);
      },
      newManufacturer: function () {
        let manufacturer = this.get('store').createRecord('entityManufacturer');
        this.set('manufacturer', manufacturer);
        this.toggleProperty('newManufacturerBox');
      },
      newType: function () {
        let manufacturer = this.get('model.manufacturer');
        let type = this.get('store').createRecord('entityProductType', {
          manufacturer: manufacturer
        });
        this.set('type', type);
        this.toggleProperty('newTypeBox');
      },

      closeManufacturerModal(savedRecord) {
        let model = this.get('model');

        if (model.get('isNew')) {
          model.set('manufacturer', savedRecord);
          model.set('type', null);
        }

        this.set('newManufacturerBox', false);
      },

      closeTypeModal(savedRecord) {
        let model = this.get('model');
        model.set('type', savedRecord);
        this.set('newTypeBox', false);
      },

      changeManufacturer: function (selected) {
        this.set('model.manufacturer', selected);
        this.set('model.type', null);
      },
      toggleSwitch: function () {
        if (this.get('model.monitoring')) {
          this.set('model.monitoring', 0);
        } else {
          this.set('model.monitoring', 1);
        }
      },
      toggleIcinga: function () {
        if (this.get('model.icinga2')) {
          this.set('model.icinga2', 0);
        } else {
          this.set('model.icinga2', 1);
        }
      },
      stopMonitoring: function (entity) {
        const flashMessages = Ember.get(this, 'flashMessages');
        entity.save(true).then(savedEntity => {
          this.get('ajax').call('POST', 'entity', savedEntity.get('id'), 'stop-monitoring').then(res => {
            entity.set('monitoringStartDate', null);
            this.get('router').transitionTo('cmdb.entity-types.entities.entity.attributes', savedEntity.get('entityType.id'), savedEntity.get('id'));
          }).catch(err => {
            console.error(err);
            let errorMessages = '';

            if (err.errors) {
              err.errors.forEach(error => {
                errorMessages += error.detail + '.';
              });
            } else {
              errorMessages += err;
            }

            flashMessages.danger(errorMessages, {
              title: 'Stop monitoring',
              icon: 'warning',
              sticky: true
            });
          });
        });
      },
      toggleNameField: function () {
        if (!this.get('model.generateName')) {
          this.set('model.name', '');

          var _this = this;
        } else {
          this.set('model.name', '-');
        }
      },

      showUploadModal(isModalVisible) {
        this.set('showUploadModal', isModalVisible);
      }

    },
    partnerHash: Ember.computed('model', function () {
      return {
        and: [{
          deletedAt: null
        }, {
          isActive: 1
        }]
      };
    })
  });

  _exports.default = _default;
});