define("@kockpit/ember-gantt/templates/components/gantt-line-inline-childs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yoDRLoFM",
    "block": "{\"symbols\":[\"period\"],\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"periods\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"style\",[23,1,[\"style\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@kockpit/ember-gantt/templates/components/gantt-line-inline-childs.hbs"
    }
  });

  _exports.default = _default;
});