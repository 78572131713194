define("ember-keycloak-auth/components/keycloak-session-status", ["exports", "ember-keycloak-auth/templates/components/keycloak-session-status"], function (_exports, _keycloakSessionStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _keycloakSessionStatus.default,
    session: Ember.inject.service('keycloak-session'),
    actions: {
      refresh() {
        this.get('session').updateToken().then(result => {
          Ember.debug(result);
        });
      },

      login() {
        this.get('session').login();
      },

      logout() {
        this.get('session').logout();
      }

    }
  });

  _exports.default = _default;
});