define('ember-array-contains-helper/helpers/array-contains', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    /**
     * Test if an array contains an object, eventually based on one of this object property / value
     *
     * @method compute
     * @param {Array} params array and value to test. Array can be null, undefined or valid Array.
     * value could be a literal or an object
     * @param {Object} hash named arguments accepted by this helper (``property``)
     * @return {Boolean}
     * - true if:
     *   - the array in ``params[0]`` contains the object value in ``params[1]``
     *   - the array in ``params[0]`` contains an object holding a property named ``hash.property`` with value equals to ``params[1]``
     * - false otherwise and if the array in ``params[0]`` is null or undefined
     *
     * @throws {Ember.Error} if params is null or not an array or if the given array (from ``params[0]``) is
     * not null and not an array.
     */
    compute(params, hash = {}) {
      Ember.assert('params should be a not null valid array', Ember.isArray(params));

      let [array, value] = params;

      // if array undefined or null, we test against an empty array. This is particularily usefull
      // if the test occurs before a promise is resolved, for example
      if (Ember.isNone(array)) {
        array = Ember.A([]);
      }

      Ember.assert('First parameter should be a valid array', Ember.isArray(array));

      const property = hash.property;
      let contained = false;
      this.setupRecompute(array, property);

      // Wrap into an Ember.Array to use advanced methods while supporting disabling prototype extensions
      // Note: This operation does not modify the original array
      let wrappedArray = Ember.A(array);

      if (property) {
        // Property provided, test the property
        contained = wrappedArray.isAny(property, value);
      } else {
        // No property provided, test the full object
        contained = wrappedArray.includes(value);
      }

      return contained;
    },

    destroy() {
      if (this.teardown) {
        this.teardown();
      }
      if (this.teardownProperty) {
        this.teardownProperty();
      }
      this._super(...arguments);
    },

    /**
     * Install and clean observers to be able to recompute result if array changes :
     * content added or removed or observed property modified.
     *
     * @method setupRecompute
     * @param {Array} array The given array
     * @param {String} property Name of the property to test
     * @private
     */
    setupRecompute(array, property) {
      Ember.set(this, '_array', array);

      // Remove existing observers, if any
      if (this.teardown) {
        this.teardown();
      }
      if (this.teardownProperty) {
        this.teardownProperty();
      }

      // Install observer on the array itself : run when adding / removing items to the array
      let arrayPath = '_array.[]';
      Ember.addObserver(this, arrayPath, this, this.recompute);
      // define method to remove observer
      this.teardown = () => {
        Ember.removeObserver(this, arrayPath, this, this.recompute);
      };

      if (property) {
        // Install observer on the given property, if any
        let propertyPath = `_array.@each.${property}`;
        Ember.addObserver(this, propertyPath, this, this.recompute);
        // define method to remove observer
        this.teardownProperty = () => {
          Ember.removeObserver(this, propertyPath, this, this.recompute);
        };
      }
    }
  });
});