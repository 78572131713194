define("iris/templates/settings/acl/roles/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iAuojSZJ",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[23,0,[\"model\",\"isLoaded\"]]],null,{\"statements\":[[0,\"  \"],[5,\"auto/form\",[],[[\"@model\",\"@route\",\"@frame\",\"@proportion\"],[[23,0,[\"model\"]],\"settings.acl.roles\",true,\"2-6\"]],{\"statements\":[[0,\"\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\"],[[23,0,[\"model\"]],\"name\",\"2-6\"]]],[0,\"\\n    \"],[5,\"form-group\",[],[[\"@property\",\"@label\",\"@proportion\"],[\"account\",[28,\"t\",[\"competency.accounts\"],null],\"2-6\"]],{\"statements\":[[0,\"\\n      \"],[5,\"input/select/lazy\",[],[[\"@modelName\",\"@multiple\",\"@selected\",\"@placeholder\",\"@searchProperty\",\"@order\",\"@onchange\"],[\"account\",true,[23,0,[\"model\",\"accounts\"]],[28,\"t\",[\"appcomponenthrm.select-user\"],null],\"longName\",\"longName\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"model\",\"accounts\"]]],null]],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"span\",true],[8],[0,\"\\n            \"],[5,\"avatar\",[],[[\"@image\",\"@name\",\"@class\"],[[23,1,[\"userPhoto\"]],[23,1,[\"longName\"]],\"user-avatar\"]]],[0,\"\\n          \"],[1,[23,1,[\"longName\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\\n      \"]],\"parameters\":[1]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"auto/form/field\",[],[[\"@model\",\"@property\",\"@proportion\"],[[23,0,[\"model\"]],\"description\",\"2-6\"]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/acl/roles/edit.hbs"
    }
  });

  _exports.default = _default;
});