define('ember-timepicker/components/ember-timepicker', ['exports', 'ember-timepicker/templates/components/ember-timepicker'], function (exports, _emberTimepicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    layout: _emberTimepicker.default,

    classNames: ['ember-timepicker'],

    timepicker: null,
    options: {},

    didInsertElement() {
      this._super(...arguments);

      // Connect the `onChange` action to this library's `change` event.
      const options = Ember.assign({
        change: (...args) => this.get('onChange')(...args)
      }, this.get('options'));

      // Create a new instance of the timepicker.
      const timepicker = this.$().timepicker(options);
      this.set('timepicker', timepicker);
    },

    willDestroyElement() {
      this._super(...arguments);

      const timepicker = this.get('timepicker');

      // If the timepicker exists, destroy it on our way out
      if (timepicker.data('TimePicker') !== null) {
        timepicker.timepicker().destroy();
      }
    }
  });
});