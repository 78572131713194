define("ember-promise-modals/services/modals", ["exports", "ember-promise-modals/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    count: Ember.computed('_stack.@each.isClosing', function () {
      return this._stack.filter(modal => !modal.isClosing).length;
    }),
    top: Ember.computed.alias('_stack.lastObject'),
    clickOutsideDeactivates: true,

    init() {
      this._super(...arguments);

      this._stack = Ember.A([]);
    },

    willDestroy() {
      this._onLastModalRemoved();
    },

    open(name, data, options) {
      let modal = _modal.default.create({
        service: this,
        name,
        data,
        options
      });

      this._stack.pushObject(modal);

      if (this._stack.length === 1) {
        this._onFirstModalAdded();
      }

      return modal;
    },

    _onFirstModalAdded() {
      document.body.classList.add('epm-scrolling-disabled');
    },

    _onLastModalRemoved() {
      document.body.classList.remove('epm-scrolling-disabled');
    },

    _onModalAnimationStart() {
      document.body.classList.add('epm-animating');
    },

    _onModalAnimationEnd() {
      document.body.classList.remove('epm-animating');
    }

  });

  _exports.default = _default;
});