define("iris/components/kanban/column/component", ["exports", "@salsify/ember-block-slots"], function (_exports, _emberBlockSlots) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let KanbanColumn = (_dec = Ember.computed('column.cards.@each', 'column', 'selectedSortProperty', 'reverseSort'), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class KanbanColumn extends Ember.Component.extend(_emberBlockSlots.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "searchOpened", false);

      _defineProperty(this, "sortOpened", false);

      _defineProperty(this, "selectedSortProperty", null);

      _defineProperty(this, "scrolled", false);

      _defineProperty(this, "reverseSort", false);
    }

    init() {
      super.init(...arguments);
      let foundSearchElement = this.searchList.find(element => element.columnId === this.column.id);

      if (foundSearchElement) {
        this.searchOpened = true;
        this.column.search = foundSearchElement.searchTerm;
        this.searchAction();
      }

      let scrollElement = document.getElementById(`scroll-back-container-${this.column.id}`);
      scrollElement.addEventListener('scroll', event => {
        this.set('scrolled', scrollElement.scrollTop === 0 ? false : true);
      });
    }

    get displayCards() {
      let cards = [...this.column.cards];

      if (this.searchOpened && this.column.search) {
        cards = this.filterCards();
      }

      cards = this.sortCards(cards);
      return cards;
    }

    async openSearch() {
      this.set('searchOpened', true);
    }

    async openSort() {
      this.set('sortOpened', true);
    }

    async closeSort() {
      this.set('selectedSortProperty', null);
      this.set('sortOpened', false);
    }

    async reverseSort() {
      this.set('reverseSort', !this.reverseSort);
    }

    async closeSearch() {
      this.set('searchOpened', false);
      this.set('column.search', null);
      let foundSearchElement = this.searchList.find(element => element.columnId === this.column.id);

      if (foundSearchElement) {
        this.searchList.removeObject(foundSearchElement);
      }

      this.notifyPropertyChange('displayCards');
    }

    async searchAction() {
      Ember.run.debounce(this, () => {
        this.notifyPropertyChange('displayCards');
      }, {}, 300);
    }

    filterCards() {
      const splittedProps = this.searchProperties.map(prop => prop.split('.'));
      let filteredCards = [];

      for (const splittedProp of splittedProps) {
        let tempfilteredCards = this.column.cards.filter(card => {
          let temp = card;
          splittedProp.forEach(prop => {
            temp = temp[prop];
          });
          return temp && temp.toLowerCase().includes(this.column.search.toLowerCase());
        });
        filteredCards = filteredCards.concat(tempfilteredCards).filter((item, idx, self) => self.indexOf(item) === idx);
      }

      let foundSearchElement = this.searchList.find(element => element.columnId === this.column.id);

      if (foundSearchElement) {
        foundSearchElement.searchTerm = this.column.search;
      } else {
        this.searchList.addObject({
          columnId: this.column.id,
          searchTerm: this.column.search
        });
      }

      return filteredCards;
    }

    sortCards(cards) {
      if (this.sortOpened && this.selectedSortProperty) {
        if (this.selectedSortProperty.type === 'number') {
          cards = cards.sort((a, b) => {
            const aValue = a[this.selectedSortProperty.property];
            const bValue = b[this.selectedSortProperty.property];
            if (aValue === null && bValue === null) return 0;
            if (aValue === null) return -1;
            if (bValue === null) return 1;
            return parseInt(aValue) - parseInt(bValue);
          });
        } else {
          cards = cards.sortBy(this.selectedSortProperty.property);
        }

        if (this.reverseSort) {
          cards = cards.reverse();
        }

        if (this.selectedSortProperty.beforeNull) {
          cards = cards.sort((a, b) => {
            if (a[this.selectedSortProperty.property] === null) return 1;
            if (b[this.selectedSortProperty.property] === null) return -1;
            return 0;
          });
        }
      } else {
        cards.sort((a, b) => {
          if (a.currentPlanId) {
            return -1;
          } else {
            return 1;
          }
        });
      }

      return cards;
    }

    async dragEndAction(args) {
      let {
        sourceList,
        sourceIndex,
        targetList,
        targetIndex,
        draggedItem
      } = args;
      if (sourceList === targetList && sourceIndex === targetIndex) return;
      const item = sourceList.objectAt(sourceIndex);
      await sourceList.removeAt(sourceIndex);
      await targetList.insertAt(targetIndex, item);

      if (this.saveAction) {
        await this.saveAction(args);
      }

      if (this.sortOpened) {
        this.set('sortOpened', false);
        this.set('selectedSortProperty', null);
      }
    }

    async scrollToTop() {
      $(`#scroll-back-container-${this.column.id}`).animate({
        scrollTop: 0
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "displayCards", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "displayCards"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openSearch", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "openSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openSort", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "openSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeSort", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "closeSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reverseSort", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "reverseSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeSearch", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "closeSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchAction", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "searchAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragEndAction", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "dragEndAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scrollToTop", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToTop"), _class.prototype)), _class));
  _exports.default = KanbanColumn;
});