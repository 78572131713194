define("ember-plupload/system/make-file-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(name, filterBody) {
    plupload.addFileFilter(name, function (configValue, file, callback) {
      var deferred = Ember.RSVP.defer();
      var self = this;
      filterBody(configValue, file, deferred.resolve, deferred.reject);
      deferred.promise.then(function () {
        callback(true);
      }, function (code, message) {
        self.trigger('Error', {
          code: code,
          message: message,
          file: file
        });
        callback(false);
      });
    });
  }
});