define("ember-provider/components/get-provider", ["exports", "ember-provider", "ember-provider/templates/components/get-provider"], function (_exports, _emberProvider, _getProvider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GetProvider = Ember.Component.extend({
    layout: _getProvider.default,
    provider: undefined,
    providerInstance: undefined,

    init() {
      this._super(...arguments);

      (false && !(!!this.provider) && Ember.assert('A `provider` property must be present', !!this.provider));
      Ember.defineProperty(this, 'providerInstance', (0, _emberProvider.inject)(this.provider));
    }

  });
  GetProvider.reopenClass({
    positionalParams: ['provider']
  });
  var _default = GetProvider;
  _exports.default = _default;
});