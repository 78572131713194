define("@kockpit/ember-gantt/components/gantt-line-title", ["exports", "@kockpit/ember-gantt/templates/components/gantt-line-title"], function (_exports, _ganttLineTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _ganttLineTitle.default,
    classNames: ['gantt-line-title']
  });

  _exports.default = _default;
});