window.EmberENV = (function(EmberENV, extra) {
  for (var key in extra) {
    EmberENV[key] = extra[key];
  }

  return EmberENV;
})(window.EmberENV || {}, {"EXTEND_PROTOTYPES":{"String":true,"Array":true,"Function":true},"_ENABLE_BINDING_SUPPORT":false,"FEATURES":{},"_JQUERY_INTEGRATION":true});

var runningTests = false;


