define("ember-provider/-private/provider-injection", ["exports", "ember-provider/-private/find-parent-provider"], function (_exports, _findParentProvider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INJECTED_PROVIDERS = new WeakMap();

  class ProviderInjection extends Ember.ComputedProperty {
    constructor(identifier) {
      function getter() {
        const owner = Ember.getOwner(this);
        const registration = `provider:${identifier}`;
        const ProviderKlass = owner.lookup(registration);
        let provider;
        let isOwner = true;
        const possibleProvider = this instanceof Ember.Component ? (0, _findParentProvider.default)(INJECTED_PROVIDERS, this, ProviderKlass) : undefined;

        if (possibleProvider) {
          isOwner = false;
          provider = possibleProvider;
        } else {
          provider = ProviderKlass.create(owner.ownerInjection());
        }

        (false && !(!!provider) && Ember.assert(`Could not resolve \`${registration}\``, !!provider));

        if (isOwner) {
          // Modify the host class to also destroy the provider
          const originalDestroy = this.willDestroy;

          this.willDestroy = () => {
            if (!provider.get('isDestroying') && !provider.get('isDestroyed')) {
              provider.destroy();
            }

            originalDestroy.apply(this, arguments);
          }; // Keep track of providers in an object


          let providerList;

          if (INJECTED_PROVIDERS.has(this)) {
            providerList = INJECTED_PROVIDERS.get(this);
          } else {
            providerList = [];
            INJECTED_PROVIDERS.set(this, providerList);
          }

          providerList.push(provider);
        }

        return provider;
      } // @ts-ignore


      super(getter, {
        dependentKeys: [],
        readOnly: true
      });
    }

    setup(proto, propertyName) {
      // In Ember versions below 3.0, there is no `setup` method defined on the super class,
      // but if a subclass provides it it will be called
      if (true) {
        // @ts-ignore
        super.setup(...arguments);
      }

      Ember.addListener(proto, 'init', null, function () {
        // @ts-ignore
        this.get(propertyName);
      });
    }

  }

  var _default = ProviderInjection;
  _exports.default = _default;
});