define("ember-promise-modals/templates/components/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f0Yd79WJ",
    "block": "{\"symbols\":[\"@modal\"],\"statements\":[[7,\"div\",true],[11,\"id\",[23,0,[\"modalElementId\"]]],[11,\"class\",[29,[\"epm-modal \",[23,0,[\"optionsClassName\"]],\" \",[23,0,[\"animatingOutClass\"]]]]],[8],[0,\"\\n  \"],[1,[28,\"component\",[[23,1,[\"_name\"]]],[[\"data\",\"close\"],[[23,1,[\"_data\"]],[28,\"action\",[[23,0,[]],\"close\"],null]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-promise-modals/templates/components/modal.hbs"
    }
  });

  _exports.default = _default;
});