define("ember-cropperjs/components/image-cropper-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    A component used for responding to cropper events. Typically used via the image-cropper
    contextual component with positional parameters like so:
  
    ```hbs
    {{#image-cropper
      alt='sinbad'
      source='sinbad.jpg'
      options=(hash
        viewMode=2
        width=256
        height=256)
    as |cropper|}}
  
    {{cropper.on 'crop' (action 'cropImage')}}
  
    {{/image-cropper}}
    ```
    @class ImageCropperOn
    @public
  */
  const ImageCropperOnComponent = Ember.Component.extend({
    tagName: '',
    cropper: null,
    eventSource: null,

    /**
      The [event from Cropper.js](https://github.com/fengyuanchen/cropperjs#events) to listen for.
       @argument event
      @type String
    */
    event: null,

    /**
      The action to call when the event is triggered.
       @argument action
      @type Action
    */
    action: null,
    _prevEvent: null,

    init() {
      this._super(...arguments);

      this._boundOnEvent = Ember.run.bind(this, this._onEvent);
    },

    _normalizedEvent: Ember.computed('event', function () {
      const event = Ember.get(this, 'event');
      (false && !(typeof event === 'string') && Ember.assert(`image-cropper-event requires event to be a string, was ${event}`, typeof event === 'string'));
      return event.toLowerCase();
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      const {
        eventSource,
        _normalizedEvent,
        _prevEvent,
        action
      } = Ember.getProperties(this, 'eventSource', '_normalizedEvent', '_prevEvent', 'action');
      (false && !(Ember.isPresent(eventSource)) && Ember.assert('image-cropper-event requires an eventSource', Ember.isPresent(eventSource)));
      (false && !(Ember.isPresent(action)) && Ember.assert('image-cropper-event requires an action', Ember.isPresent(action)));

      if (_normalizedEvent !== _prevEvent) {
        if (_prevEvent) {
          eventSource.removeEventListener(_prevEvent, this._boundOnEvent);
        }

        this._prevEvent = _normalizedEvent;
        eventSource.addEventListener(_normalizedEvent, this._boundOnEvent);
      }
    },

    willDestroy() {
      this._super(...arguments);

      const {
        eventSource,
        _prevEvent
      } = Ember.getProperties(this, 'eventSource', '_prevEvent');

      if (eventSource && _prevEvent) {
        eventSource.removeEventListener(_prevEvent, this._boundOnEvent);
      }
    },

    _onEvent() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      const {
        action
      } = this;

      if (action) {
        action(this.cropper, ...arguments);
      }
    }

  });
  ImageCropperOnComponent.reopenClass({
    positionalParams: ['event', 'action']
  });
  var _default = ImageCropperOnComponent;
  _exports.default = _default;
});