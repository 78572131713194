define("iris/templates/cmdb/entity-types/entities/entity/wazuh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dynUafBU",
    "block": "{\"symbols\":[],\"statements\":[[5,\"tables/wazuh-report\",[],[[\"@filter\"],[[28,\"hash\",null,[[\"current\",\"entityId\"],[true,[23,0,[\"model\",\"id\"]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/entity-types/entities/entity/wazuh.hbs"
    }
  });

  _exports.default = _default;
});