define("iris/templates/settings/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Efg71gA+",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[5,\"auto/grid\",[],[[\"@modelName\",\"@route\",\"@defaultOrder\",\"@neededFields\"],[\"account\",\"settings.users.edit\",\"createDate DESC\",[28,\"array\",[\"longName\",\"lastPasswdModify\",\"email\",\"language\",\"enabled\",\"contact\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[\"column\",\"name\"]],\"longName\"],null]],null,{\"statements\":[[0,\"      \"],[5,\"avatar\",[],[[\"@image\",\"@name\",\"@class\"],[[23,1,[\"row\",\"userPhoto\"]],[23,1,[\"row\",\"longName\"]],\"user-photo\"]]],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"settings.users.edit\",[23,1,[\"row\",\"id\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[23,1,[\"row\",\"longName\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,[23,1,[\"c\",\"cell\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/settings/users.hbs"
    }
  });

  _exports.default = _default;
});