define("ember-provider/index", ["exports", "ember-provider/provider", "ember-provider/inject"], function (_exports, _provider, _inject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Provider", {
    enumerable: true,
    get: function () {
      return _provider.default;
    }
  });
  Object.defineProperty(_exports, "inject", {
    enumerable: true,
    get: function () {
      return _inject.default;
    }
  });
});