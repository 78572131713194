define("iris/routes/cmdb/entity-types/entities/entity", ["exports", "iris/mixins/protected-route", "iris/lib/modals"], function (_exports, _protectedRoute, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_protectedRoute.default, {
    entityForm: Ember.inject.service(),
    api: Ember.inject.service(),
    modals: Ember.inject.service(),

    async model(params) {
      var store = this.store;
      let entityTypeId = this.paramsFor('cmdb.entity-types.entities').entity_type_id;
      let entityId = params.entity_id;

      let _hash = await Ember.RSVP.hash({
        columns: store.query('entityAttribute', {
          filter: {
            where: {
              entityTypeId
            },
            include: 'related',
            order: 'orderIndex'
          }
        }),
        values: store.query('entityValue', {
          filter: {
            where: {
              entityId: entityId
            },
            include: ['related', 'parent']
          }
        }),
        entity: store.find('entity', entityId),
        entityType: store.find('entityType', entityTypeId),
        entityProcedures: store.query('entityProcedure', {
          filter: {
            where: {
              or: [{
                entityId
              }, {
                realEntityId: entityId
              }]
            },
            include: ['notificationWarning', 'notificationCritical', 'notificationGroups', 'solvingGroup']
          }
        }),
        entityRelations: store.query('entityRelation', {
          filter: {
            where: {
              or: [{
                masterEntityId: entityId
              }, {
                slaveEntityId: entityId
              }]
            }
          }
        }),
        siteZones: store.query('siteZone', {
          filter: {
            include: 'partner',
            order: 'name'
          }
        }),
        entityTypes: store.query('entityType', {
          filter: {
            where: {
              and: [{
                codeStore: 0
              }, {
                template: 0
              }]
            },
            order: 'longName'
          }
        })
      });

      await getSoftwares(_hash.entity);
      await replaceEntityProcedures(_hash.entity, _hash.entityProcedures);
      await loadEntityProcedureNotifications(_hash.entityProcedures);
      return _hash;
    },

    setupController: function (controller, model) {
      this._super(controller, model);

      this.entityForm.setModel(model.entity);
      const parentController = this.controllerFor('cmdb.entity-types.index');
      parentController.set('spinner', false);
    },
    afterModel: function (model) {
      let store = this.store;

      try {
        model.entity.get('entityValues').then(values => {
          model.columns.forEach(col => {
            let notFind = true;
            values.forEach(val => {
              if (val.get('entityAttribute.id') === col.id) {
                if ((col.get('type') === 'datetime' || col.get('type') === 'date') && val.get('value')) {
                  val.set('value', new Date(val.get('value')));
                }

                notFind = false;
              }
            });

            if (notFind) {
              let record = store.createRecord('entityValue', {
                disableDefault: true,
                value: null,
                entity: model.entity,
                entityAttribute: col,
                orderIndex: col.orderIndex
              });
              values.pushObject(record);
            }
          });
        });
      } catch (err) {
        // This error is thrown when a newly created entity is being edited right
        // after creation. Repro steps:
        // 1. Create a new entity using /cmdb/entity-types/new-entity
        // 2. Click the entity from the list that was just created
        // 3. *BOOM*
        //
        // The above does not happen when an existing entity is selected at 2.
        let targetRoute = 'cmdb.entity-types.entities.entity.attributes';
        let entityTypeId = this.paramsFor('cmdb.entity-types.entities').entity_type_id;
        let entityId = model.entity.get('id');
        let targetPath = this.router.generate(targetRoute, entityTypeId, entityId); // @ts-ignore

        window.location.pathname = targetPath; // Hang around forever, as we don't want to show the partially rendered
        // entity page before the window.location.pathname kicks in

        return new Promise(() => {});
      }
    },
    actions: {
      async beforeSave(entity, cb) {
        const changedAttributes = entity.changedAttributes(); // monitoring did not changed

        if (!changedAttributes.monitoring) {
          return cb();
        }

        let [from, to] = changedAttributes.monitoring;

        if (!!to === false) {
          return cb();
        }

        const mismatchPartners = await this.api.memberAction('entity', 'check-mismatch-partner', entity.id, {
          method: 'GET'
        });

        if (Object.keys(mismatchPartners).length === 0) {
          return cb();
        }

        const partnerId = entity.belongsTo('partner').id();
        const result = await this.modals.open('modals/cmdb-mismatch-partner', {
          mismatchPartners,
          partnerId
        }, {
          className: _modals.ModalLayoutClassName.L
        });

        if (result === false) {
          return;
        }

        cb();
      },

      async cloneHost(model) {
        let masterRelations = await model.get('masterRelations');

        if (masterRelations) {
          await Promise.all(masterRelations.map(relation => relation.get('entityRelationType')));
        }

        let slaveRelations = await model.get('slaveRelation');

        if (slaveRelations) {
          await Promise.all(slaveRelations.map(relation => relation.get('entityRelationType')));
        }

        const clone = await model.copy(true, {
          overwrite: {
            name: `${model.get('name')} (copy)`,
            location: null,
            entityProcedures: Ember.A(),
            documents: Ember.A(),
            monitoringStartDate: null,
            monitoring: 0,
            icingaStatus: 'OK'
          }
        });
        const path = 'cmdb.entity-types.entities.new-entity.attributes';
        await this.transitionTo(path, model.get('entityType.id'));
        const controller = this.controllerFor('cmdb.entity-types.entities.new-entity');
        controller.set('model.entity', clone);
      }

    }
  });

  _exports.default = _default;

  const getSoftwares = async entity => {
    let softwares = await entity.get('children');

    for (const software of softwares.toArray()) {
      await getSoftwares(software);
    }

    entity.startTrack();
  };

  const replaceEntityProcedures = async (entity, newEntityProcedures) => {
    let entityProcedures = await entity.get('entityProcedures');
    entityProcedures.clear();
    entityProcedures.pushObjects(newEntityProcedures.toArray());
  };

  const loadEntityProcedureNotifications = async entityProcedures => {
    let promises = entityProcedures.map(ep => [ep.get('notificationWarning'), ep.get('notificationCritical')]).flat();
    return Promise.all(promises);
  };
});