define("ember-plupload/system/trim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var trim;

  if (String.prototype.trim) {
    trim = function (string) {
      return (string || '').trim();
    };
  } else {
    // Make sure we trim BOM and NBSP
    var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;

    trim = function (string) {
      return (string || '').replace(rtrim, '');
    };
  }

  var _default = trim;
  _exports.default = _default;
});