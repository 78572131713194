define('@salsify/ember-block-slots/components/block-slot', ['exports', '@salsify/ember-block-slots/templates/components/block-slot', '@salsify/ember-block-slots/mixins/slots', '@salsify/ember-block-slots/components/yield-slot'], function (exports, _blockSlot, _slots, _yieldSlot) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * A block slot provides content for a target yield slot with a matching name
   *
   * e.g. {{#block-slot 'foo'}} would provide content for {{#yield-slot 'foo'}}
   *
   * Block slots may also use block params, see addon/helpers/block-params.js
   */
  const BlockSlot = Ember.Component.extend({
    // == Component properties ==================================================

    layout: _blockSlot.default,
    tagName: '',

    // == Events ================================================================

    _didInsertElement: Ember.on('didInsertElement', function () {
      // Active the yield slot using the slots interface
      const slottedComponent = this.nearestOfType(_slots.default);
      if (slottedComponent && !slottedComponent._isRegistered(this._name)) {
        slottedComponent._activateSlot(this._name);
        // Store the slotted component for use during deactivation
        this.set('slottedComponent', slottedComponent);
        // Activation is done, the next pass will contain a local yieldSlot
        return;
      }

      // Find the yield slot for this block
      const yieldSlot = this.nearestOfType(_yieldSlot.default);

      if (yieldSlot) {
        // Store the activated yield slot for block params computed properties
        this.set('_yieldSlot', yieldSlot);

        // The slotted component will yield multiple times - once to register
        // the activate slots and once more per active slot - only display this
        // block when its associated slot is the one yielding
        const isTargetSlotYielding = yieldSlot._name === this._name;
        this.set('isTargetSlotYielding', isTargetSlotYielding);

        // If the associated slot has block params, create a computed property
        // for each block param.  Technically this could be an unlimited, but
        // hbs lacks a spread operator so params are currently limited to 9
        // (see the yield in the block-slot template)
        //
        // Spread PR: https://github.com/wycats/handlebars.js/pull/1149
        if (isTargetSlotYielding && Ember.isPresent(yieldSlot._blockParams)) {
          // p0 p1 p2...
          yieldSlot._blockParams.forEach((param, index) => {
            Ember.defineProperty(this, `p${index}`, Ember.computed.readOnly(`_yieldSlot._blockParams.${index}`));
          });
        }
      }
    }),

    _willDestroyElement: Ember.on('willDestroyElement', function () {
      const slottedComponent = this.get('slottedComponent');
      if (slottedComponent) {
        // Deactivate the yield slot using the slots interface when the block
        // is destroyed to allow the yield slot default {{else}} to take effect
        // dynamically
        slottedComponent._deactivateSlot(this._name);
      }
    })
  });

  BlockSlot.reopenClass({
    positionalParams: ['_name']
  });

  exports.default = BlockSlot;
});