define("ember-plupload/system/upload-queue", ["exports", "jquery", "ember-copy", "ember-plupload/system/file", "ember-plupload/system/trim", "ember-plupload/system/sum-by"], function (_exports, _jquery, _emberCopy, _file, _trim, _sumBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const keys = Object.keys;

  var getHeader = function (headers, header) {
    let headerKeys = Ember.A(keys(headers));
    let headerIdx = headerKeys.map(s => s.toLowerCase()).indexOf(header.toLowerCase());

    if (headerIdx !== -1) {
      return headers[headerKeys[headerIdx]];
    }

    return null;
  };
  /**
  
    @namespace ember-plupload
    @class UploadQueue
    @extend Ember.ArrayProxy
   */


  var _default = Ember.ArrayProxy.extend({
    name: null,
    uploading: Ember.computed.bool('length'),
    queues: null,

    init() {
      Ember.set(this, 'queues', Ember.A([]));
      Ember.set(this, 'orphanedQueues', Ember.A([]));
      Ember.set(this, 'content', Ember.A([]));

      this._super();
    },

    configure() {
      let config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (config.browse_button) {
        (false && !(document.getElementById(config.browse_button)) && Ember.assert(`An element with the id "${config.browse_button}" is needed to match the uploader\'s for attribute.`, document.getElementById(config.browse_button)));
      }

      var uploader = new plupload.Uploader(config);
      uploader.bind('Init', Ember.run.bind(this, 'runtimeDidChange'));
      uploader.bind('FilesAdded', Ember.run.bind(this, 'filesAdded'));
      uploader.bind('FilesRemoved', Ember.run.bind(this, 'filesRemoved'));
      uploader.bind('BeforeUpload', Ember.run.bind(this, 'configureUpload'));
      uploader.bind('UploadProgress', Ember.run.bind(this, 'progressDidChange'));
      uploader.bind('FileUploaded', Ember.run.bind(this, 'fileUploaded'));
      uploader.bind('UploadComplete', Ember.run.bind(this, 'uploadComplete'));
      uploader.bind('Error', Ember.run.bind(this, 'onError'));
      Ember.get(this, 'queues').pushObject(uploader); // Set browse_button and drop_element as
      // references to the buttons so moxie doesn't
      // get confused when the dom might be detached

      uploader.settings.browse_button = [config.browse_button];

      if (config.drop_element) {
        uploader.settings.drop_element = [config.drop_element];
      }

      let settings = (0, _emberCopy.copy)(uploader.settings);
      delete settings.url;
      Ember.set(this, 'settings', settings);
      uploader.init();
      return uploader;
    },

    runtimeDidChange() {
      let $input = Ember.get(this, 'target').$('.moxie-shim input');
      let ruid = $input.attr('id');
      let I = moxie.runtime.Runtime.getInfo(ruid); // Polyfill mobile support

      if (I && !I.can('summon_file_dialog')) {
        $input.attr('capture', 'camera');
      }
    },

    /**
      Orphan the active plupload object so
      we garbage collect the queues.
     */
    orphan() {
      var orphans = Ember.get(this, 'orphanedQueues');
      var activeQueues = Ember.get(this, 'queues').filter(function (queue) {
        return orphans.indexOf(queue) === -1;
      });
      var freshestQueue = Ember.get(Ember.A(activeQueues), 'lastObject');

      if (Ember.get(freshestQueue, 'total.queued') > 0) {
        orphans.pushObject(freshestQueue);
      } else {
        this.garbageCollectUploader(freshestQueue);
      }
    },

    destroy() {
      this._super();

      Ember.get(this, 'queues').invoke('unbindAll');
      Ember.set(this, 'content', Ember.A([]));
      Ember.set(this, 'queues', null);
    },

    refresh() {
      Ember.get(this, 'queues').invoke('refresh');
    },

    size: Ember.computed({
      get: function _get() {
        return (0, _sumBy.default)(Ember.get(this, 'queues'), 'total.size') || 0;
      }
    }),
    loaded: Ember.computed({
      get: function _get() {
        return (0, _sumBy.default)(Ember.get(this, 'queues'), 'total.loaded') || 0;
      }
    }),
    progress: Ember.computed('size', 'loaded', {
      get: function _get() {
        let percent = Ember.get(this, 'loaded') / Ember.get(this, 'size') || 0;
        return Math.floor(percent * 100);
      }
    }),

    filesAdded(uploader, files) {
      for (let i = 0, len = files.length; i < len; i++) {
        var file = _file.default.create({
          uploader: uploader,
          file: files[i],
          queue: this
        });

        this.pushObject(file);
        Ember.get(this, 'target').onfileadd(file, {
          name: Ember.get(this, 'name'),
          uploader: uploader,
          queue: this
        });
        this.notifyPropertyChange('size');
        this.notifyPropertyChange('loaded');
      }
    },

    filesRemoved(uploader, files) {
      for (var i = 0, len = files.length; i < len; i++) {
        var file = this.findBy('id', files[i].id);

        if (file) {
          this.removeObject(file);
        }
      }

      this.notifyPropertyChange('size');
      this.notifyPropertyChange('loaded');
    },

    configureUpload(uploader, file) {
      file = this.findBy('id', file.id); // Reset settings for merging

      uploader.settings = (0, _emberCopy.copy)(Ember.get(this, 'settings'));
      Ember.assign(uploader.settings, file.settings);
      this.progressDidChange(uploader, file);
    },

    progressDidChange(uploader, file) {
      file = this.findBy('id', file.id);

      if (file) {
        file.notifyPropertyChange('progress');
      }

      this.notifyPropertyChange('size');
      this.notifyPropertyChange('loaded');
    },

    parseResponse(response) {
      var body = (0, _trim.default)(response.response);
      var rawHeaders = Ember.A(response.responseHeaders.split(/\n|\r/)).without('');
      var headers = rawHeaders.reduce(function (E, header) {
        var parts = header.split(/^([0-9A-Za-z_-]*:)/);

        if (parts.length > 0) {
          E[parts[1].slice(0, -1)] = (0, _trim.default)(parts[2]);
        }

        return E;
      }, {});
      let contentType = (getHeader(headers, 'Content-Type') || '').split(';'); // Parse body according to the Content-Type received by the server

      if (contentType.indexOf('text/html') !== -1) {
        body = _jquery.default.parseHTML(body);
      } else if (contentType.indexOf('text/xml') !== -1) {
        body = _jquery.default.parseXML(body);
      } else if (contentType.indexOf('application/json') !== -1 || contentType.indexOf('text/javascript') !== -1 || contentType.indexOf('application/javascript') !== -1) {
        body = _jquery.default.parseJSON(body);
      }

      return {
        status: response.status,
        body: body,
        headers: headers
      };
    },

    fileUploaded(uploader, file, response) {
      var results = this.parseResponse(response);
      file = this.findBy('id', file.id);

      if (file) {
        this.removeObject(file);
      } // NOTE: Plupload calls UploadProgress upon triggering FileUploaded,
      //       so we don't need to trigger a progress event


      if (Math.floor(results.status / 200) === 1) {
        file._deferred.resolve(results);
      } else {
        file._deferred.reject(results);
      } // Notify plupload that our browse_button may have
      // changed locations


      Ember.run.later(uploader, 'refresh', 750);
    },

    garbageCollectUploader(uploader) {
      Ember.get(this, 'queues').removeObject(uploader);
      Ember.get(this, 'orphanedQueues').removeObject(uploader);
      this.filterBy('uploader', uploader).invoke('destroy');
      uploader.unbindAll();
    },

    uploadComplete(uploader) {
      // Notify plupload that our browse_button may have
      // changed locations
      Ember.run.later(uploader, 'refresh', 750);
      this.notifyPropertyChange('loaded');
      this.notifyPropertyChange('size'); // Clean up the orphaned uploader and its files

      if (Ember.get(this, 'orphanedQueues').indexOf(uploader) !== -1) {
        this.garbageCollectUploader(uploader);
      }
    },

    onError(uploader, error) {
      if (error.file) {
        var file = this.findBy('id', error.file.id);

        if (file == null) {
          file = _file.default.create({
            uploader: uploader,
            file: error.file
          });
        }

        Ember.set(file, 'error', error);

        if (file._deferred) {
          file._deferred.reject(error); // This happended before the file got queued,
          // So we need to stub out `upload` and trigger
          // the queued event

        } else {
          file.upload = file.read = function () {
            Ember.run.debounce(uploader, 'refresh', 750);
            return Ember.RSVP.reject(error, `File: '${error.file.id}' ${error.message}`);
          };

          if (file) {
            file.destroy();
          }

          Ember.get(this, 'target').onfileadd(file, {
            name: Ember.get(this, 'name'),
            uploader: uploader,
            queue: this
          });
        }

        this.notifyPropertyChange('length');
        Ember.run.debounce(uploader, 'refresh', 750);
      } else {
        Ember.set(this, 'error', error);
        Ember.get(this, 'target').onerror(error);
      }
    }

  });

  _exports.default = _default;
});