define("ember-cropperjs/components/image-cropper", ["exports", "ember-cropperjs/templates/components/image-cropper"], function (_exports, _imageCropper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Properties that do not require a new Cropper instance, rather just need to call
  // a method on the existing instance
  const OPT_UPDATE_METHODS = {
    'aspectRatio': 'setAspectRatio'
  }; // Properties that require a completely new Cropper instance

  const OPTS_REQUIRE_NEW = ['cropBoxResizable', 'cropBoxMovable', 'zoomable'];
  /**
    A component that renders a cropper.
    ```hbs
    {{#image-cropper
      alt='sinbad'
      source='sinbad.jpg'
      options=(hash
        viewMode=2
        width=256
        height=256)}}
  
    {{!-- yielded content --}}
  
    {{/image-cropper}}
    ```
    @class ImageCropper
    @public
  */

  var _default = Ember.Component.extend({
    classNames: ['image-cropper'],
    layout: _imageCropper.default,

    /**
      The attribute defining the alternative text describing the cropper canvas.
       @argument alt
      @type String
    */
    alt: null,

    /**
      The image source to crop.
       @argument source
      @type String
    */
    source: null,

    /**
      The options to pass down to the Cropper.js instance. Use [Cropper.js options](https://github.com/fengyuanchen/cropperjs#options)
      for reference.
       @argument options
      @type Object
    */
    options: null,
    _Cropper: null,
    _cropper: null,
    _prevOptions: null,
    _prevSource: null,

    init() {
      this._super(...arguments);

      if (window && window.document) {
        emberAutoImportDynamic("cropperjs").then(module => {
          this._Cropper = module.default;
          Ember.run.join(() => this._setup());
        });
      }
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, this._setup);
    },

    didUpdateAttrs() {
      this._super(...arguments);

      const {
        _cropper
      } = this;

      if (_cropper === null || this._Cropper === null) {
        return;
      } // Check if the image source changed


      if (Ember.compare(Ember.get(this, 'source'), Ember.get(this, '_prevSource')) !== 0) {
        const source = Ember.get(this, 'source');

        _cropper.replace(source);

        Ember.set(this, '_prevSource', source);
      }

      const options = Ember.get(this, 'options');

      if (!options) {
        return;
      } // Requires to destroy and re-instantiate a new Cropper instance


      if (window && window.document) {
        if (OPTS_REQUIRE_NEW.some(opt => Ember.compare(options[opt], this._prevOptions[opt]) !== 0)) {
          // Note that .getData() will fail unless the cropper is already ready
          const shouldRetainData = _cropper.ready;
          const data = shouldRetainData ? _cropper.getData() : null;
          const canvasData = shouldRetainData ? _cropper.getCanvasData() : null;

          _cropper.destroy();

          const opts = Ember.assign({}, options);
          const source = Ember.get(this, 'source');
          const image = document.getElementById(`image-cropper-${Ember.get(this, 'elementId')}`);
          const newCropper = new this._Cropper(image, opts);

          if (shouldRetainData) {
            // Reset state that would be lost after re-initializing
            const reloadData = function () {
              newCropper.setCanvasData(canvasData); // According to the CropperJS docs, setData is only available if
              // viewMode is 1 or greater

              if (options.viewMode && options.viewMode >= 1) {
                newCropper.setData(data);
              } // Only need to do this once!


              image.removeEventListener("ready", reloadData, false);
            }; // We use the event listener instead of CropperJS's ready shortcut,
            // so that this doesn't override the options hash


            image.addEventListener("ready", reloadData, false); // Reload the image after init - this part shouldn't be in the ready
            // callback

            if (source) newCropper.replace(source);
          }

          Ember.setProperties(this, {
            _prevOptions: opts,
            _cropper: newCropper
          });
          return;
        }
      } // Diff the `options` hash for changes


      for (const opt in OPT_UPDATE_METHODS) {
        if (Ember.compare(options[opt], this._prevOptions[opt]) !== 0) {
          _cropper[OPT_UPDATE_METHODS[opt]](options[opt]);
        }
      }

      Ember.set(this, '_prevOptions', Ember.assign({}, options));
    },

    willDestroyElement() {
      this._super(...arguments);

      const _cropper = Ember.get(this, '_cropper');

      if (_cropper !== null) {
        _cropper.destroy();
      }
    },

    _setup() {
      if (this.isDestroyed || this.isDestroying || !this.element || this._Cropper === null || this._cropper !== null) {
        return;
      }

      if (window && window.document) {
        const image = document.getElementById(`image-cropper-${Ember.get(this, 'elementId')}`);
        const options = Ember.get(this, 'options'); // Need a copy because Cropper does not seem to like the Ember EmptyObject that is created from the `{{hash}}` helper

        const opts = Ember.assign({}, options);
        Ember.setProperties(this, {
          _cropper: new this._Cropper(image, opts),
          _prevOptions: opts,
          _prevSource: Ember.get(this, 'source')
        });
      }
    }

  });

  _exports.default = _default;
});