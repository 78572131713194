define("iris/components/file-prefix/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H2GclUsE",
    "block": "{\"symbols\":[\"@property\",\"@proportion\"],\"statements\":[[5,\"form-group\",[],[[\"@property\",\"@label\",\"@proportion\"],[[23,1,[]],[28,\"t\",[\"partner.filePrefix\"],null],[23,2,[]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex-grow\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[23,0,[\"prefix\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"italic\"],[8],[1,[28,\"t\",[\"components.file-prefix.no-data\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"prefix\",\"project\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[8],[0,\"\\n            \"],[5,\"fa-icon\",[[12,\"title\",[28,\"t\",[\"components.file-prefix.project-file\"],null]]],[[\"@icon\"],[\"file-text-o\"]]],[0,\"\\n            \"],[1,[23,0,[\"prefix\",\"project\"]],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[23,0,[\"prefix\",\"sameAsProject\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,0,[\"prefix\",\"operational\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[8],[0,\"\\n              \"],[5,\"fa-icon\",[[12,\"title\",[28,\"t\",[\"components.file-prefix.operational-file\"],null]]],[[\"@icon\"],[\"file-text\"]]],[0,\"\\n              \"],[1,[23,0,[\"prefix\",\"operational\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@text\",\"@action\",\"@icon\"],[\"success\",[28,\"t\",[\"credit-value-day.button\"],null],[28,\"action\",[[23,0,[]],\"edit\"],null],\"glyphicon-plus\"]]],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/file-prefix/template.hbs"
    }
  });

  _exports.default = _default;
});