define("ember-cropperjs/templates/components/image-cropper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q5cjhxMb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"img\",true],[11,\"id\",[28,\"concat\",[\"image-cropper-\",[24,[\"elementId\"]]],null]],[10,\"class\",\"max-w-full\"],[11,\"src\",[22,\"source\"]],[11,\"alt\",[22,\"alt\"]],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"_cropper\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"cropper\",\"call\",\"on\"],[[24,[\"_cropper\"]],[28,\"component\",[\"image-cropper-call\"],[[\"obj\"],[[24,[\"_cropper\"]]]]],[28,\"component\",[\"image-cropper-on\"],[[\"cropper\",\"eventSource\"],[[24,[\"_cropper\"]],[24,[\"_cropper\",\"element\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cropperjs/templates/components/image-cropper.hbs"
    }
  });

  _exports.default = _default;
});