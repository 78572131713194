define('@salsify/ember-block-slots/components/yield-slot', ['exports', '@salsify/ember-block-slots/templates/components/yield-slot', '@salsify/ember-block-slots/mixins/slots'], function (exports, _yieldSlot, _slots) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * {{yield-slot}} provides a target for {{block-slot}} content
   *
   * Yield slots may also have a default, specified using the {{else}} helper
   * as per https://guides.emberjs.com/v2.6.0/components/block-params/#toc_supporting-both-block-and-non-block-component-usage-in-one-template
   *
   * e.g.
   *
   * // my-component.hbs
   * {{yield}}
   *
   * {{#yield-slot 'foo'}}
   *   {{yield}}
   * {{else}}
   *   <div>Default</div>
   * {{/yield-slot}}
   *
   * when used without a {{#block-slot 'foo'}}
   *
   * // my-route.hbs
   * {{my-component}}
   *
   * would result in DOM
   *
   * <div>Default</div>
   */
  const YieldSlotComponent = Ember.Component.extend({
    // == Component properties ==================================================

    layout: _yieldSlot.default,
    tagName: '',

    // == Computed properties ===================================================

    // Use nearestOfType to determine the parent view, just in case there are
    // other components sandwiched between the block slot and the Slots mixin
    _parentView: Ember.computed(function () {
      return this.nearestOfType(_slots.default);
    }),

    // A yield slot is considered active if a block slot registered a matching
    // name against the parent component with the Slots mixin
    isActive: Ember.computed('_parentView._slots.[]', '_name', function () {
      let parentSlots = this.get('_parentView._slots');
      return parentSlots && parentSlots.includes(this.get('_name'));
    })
  });

  YieldSlotComponent.reopenClass({
    positionalParams: ['_name', '_blockParams']
  });

  exports.default = YieldSlotComponent;
});