/* globals Ember */

/* eslint-disable ember/new-module-imports */
(() => {
  let originalObserver = Ember.observer;

  Ember.observer = function () {
    for (var _len = arguments.length, observerArgs = new Array(_len), _key = 0; _key < _len; _key++) {
      observerArgs[_key] = arguments[_key];
    }

    let funcOrDef = observerArgs.pop();
    (false && !(typeof funcOrDef === 'function' || typeof funcOrDef === 'object' && funcOrDef !== null) && Ember.assert('observer must be provided a function or an observer definition', typeof funcOrDef === 'function' || typeof funcOrDef === 'object' && funcOrDef !== null));
    let isRFC494API = typeof funcOrDef === 'object' && funcOrDef !== null;
    (false && !(isRFC494API !== true || typeof funcOrDef.sync === 'boolean') && Ember.assert('observer called without sync', isRFC494API !== true || typeof funcOrDef.sync === 'boolean'));

    if (isRFC494API) {
      let {
        dependentKeys,
        fn,
        sync
      } = funcOrDef;
      return originalObserver.apply(Ember, [...dependentKeys, function () {
        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        if (sync) {
          fn.apply(this, args);
        } else {
          // using Ember.run.backburner.schedule instead of Ember.run.schedule specifically to ensure
          // that the autorun assertion (only present in Ember < 3.4) does not get fired for async
          // observers
          Ember.run.backburner.schedule('actions', this, fn, ...args);
        }
      }]);
    } else {
      return originalObserver.apply(Ember, arguments);
    }
  };
})();