define("ember-render-helpers/helpers/did-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * This helper is activated only on _updates_ to it's arguments (both positional
   * and named). It does not run during or after initial render, or before it is
   * un-rendered (removed from the DOM).
   */
  class DidUpdateHelper extends Ember.Helper {
    constructor() {
      super(...arguments);

      _defineProperty(this, "didRun", false);
    }

    compute(positional, named) {
      const fn = positional[0];
      (false && !(typeof fn === 'function') && Ember.assert(`\`{{did-update fn}}\` expects a function as the first parameter. You provided: ${fn}`, typeof fn === 'function'));

      if (!this.didRun) {
        this.didRun = true;
        return;
      }

      fn(positional.slice(1), named);
    }

  }

  _exports.default = DidUpdateHelper;
});