define("ember-power-select/components/power-select-multiple", ["exports", "@ember-decorators/component", "ember-power-select/templates/components/power-select-multiple", "ember-power-select/utils/computed-fallback-if-undefined"], function (_exports, _component, _powerSelectMultiple, _computedFallbackIfUndefined) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PowerSelectMultiple = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_powerSelectMultiple.default), _dec3 = (0, _computedFallbackIfUndefined.default)('power-select-multiple/trigger'), _dec4 = (0, _computedFallbackIfUndefined.default)(null), _dec5 = Ember.computed('triggerClass'), _dec6 = Ember.computed, _dec7 = Ember.computed('tabindex', 'searchEnabled', 'triggerComponent'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = (0, _computedFallbackIfUndefined.default)(function (option, select) {
    let newSelection = (select.selected || []).slice(0);
    let idx = -1;

    for (let i = 0; i < newSelection.length; i++) {
      if (Ember.isEqual(newSelection[i], option)) {
        idx = i;
        break;
      }
    }

    if (idx > -1) {
      newSelection.splice(idx, 1);
    } else {
      newSelection.push(option);
    }

    return newSelection;
  }), _dec(_class = _dec2(_class = (_class2 = class PowerSelectMultiple extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "triggerComponent", _descriptor, this);

      _initializerDefineProperty(this, "beforeOptionsComponent", _descriptor2, this);

      _initializerDefineProperty(this, "buildSelection", _descriptor3, this);
    }

    // CPs
    get concatenatedTriggerClass() {
      let classes = ['ember-power-select-multiple-trigger'];

      if (this.triggerClass) {
        classes.push(this.triggerClass);
      }

      return classes.join(' ');
    }

    get selected() {
      return [];
    }

    set selected(v) {
      if (v === null || v === undefined) {
        return [];
      }

      return v;
    }

    get computedTabIndex() {
      if (this.triggerComponent === 'power-select-multiple/trigger' && this.searchEnabled) {
        return '-1';
      } else {
        return this.tabindex || '0';
      }
    } // Actions


    handleOpen(select, e) {
      if (this.onOpen && this.onOpen(select, e) === false) {
        return false;
      }

      this.focusInput(select);
    }

    handleFocus(select, e) {
      if (this.onFocus) {
        this.onFocus(select, e);
      }

      this.focusInput(select);
    }

    handleKeydown(select, e) {
      if (this.onKeydown && this.onKeydown(select, e) === false) {
        e.stopPropagation();
        return false;
      }

      if (e.keyCode === 13 && select.isOpen) {
        e.stopPropagation();

        if (select.highlighted !== undefined) {
          if (!select.selected || select.selected.indexOf(select.highlighted) === -1) {
            select.actions.choose(select.highlighted, e);
            return false;
          } else {
            select.actions.close(e);
            return false;
          }
        } else {
          select.actions.close(e);
          return false;
        }
      }
    } // Methods


    focusInput(select) {
      if (select) {
        let input = document.querySelector(`#ember-power-select-trigger-multiple-input-${select.uniqueId}`);

        if (input) {
          input.focus();
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "triggerComponent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "beforeOptionsComponent", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "concatenatedTriggerClass", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "concatenatedTriggerClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selected", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "selected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "computedTabIndex", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "computedTabIndex"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleOpen", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleOpen"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleFocus", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFocus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeydown", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeydown"), _class2.prototype), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "buildSelection", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
  _exports.default = PowerSelectMultiple;
});