define("ember-keycloak-auth/templates/components/keycloak-session-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3lOm/K5o",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"session\",\"ready\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"session\",\"authenticated\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"logout\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-sign-out\"],[8],[9],[0,\" Sign out\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"login\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-sign-in\"],[8],[9],[0,\" Sign in\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  No session\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-keycloak-auth/templates/components/keycloak-session-link.hbs"
    }
  });

  _exports.default = _default;
});