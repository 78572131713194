define("@kockpit/ember-gantt/templates/components/gantt-milestone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aaEmUW5p",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"gantt-line-milestone-icon\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"gantt-line-milestone-title\"],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[1,[22,\"formattedDate\"],false],[9],[1,[22,\"title\"],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@kockpit/ember-gantt/templates/components/gantt-milestone.hbs"
    }
  });

  _exports.default = _default;
});