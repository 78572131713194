define("ember-changeset/utils/is-changeset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CHANGESET = void 0;
  _exports.default = isChangeset;
  const CHANGESET = '__CHANGESET__';
  _exports.CHANGESET = CHANGESET;

  function isChangeset(changeset) {
    return Ember.get(changeset, '__changeset__') === CHANGESET;
  }
});