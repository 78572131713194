define('ember-websockets/services/websockets', ['exports', 'ember-websockets/helpers/websocket-proxy', 'ember-websockets/helpers'], function (exports, _websocketProxy, _helpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /*
      A hash of open websocket connections. This
      allows multiple components to share the same connection.
       {
        'websocket-url': WebSocket Proxy object
      }
    */
    sockets: Ember.computed(function () {
      return {};
    }),

    /*
      socketFor returns a websocket proxy object. On this object there is a property `socket`
      which contains the actual websocket object. This websocket object is cached based off of the url meaning
      multiple requests for the same socket will return the same object.
    */
    socketFor(url, protocols = []) {
      /*
        Websockets allows either a string or array of strings to be passed as the second argument.
        Normalize both cases into an array of strings so we can just deal with arrays.
      */
      if (typeof protocols === 'string') {
        protocols = [protocols];
      }

      /*
        Normalize the url as native websockets add a / to the end of the url:
        http://example.com:8000 becomes: http://example.com:8000/
         Since the url will be used as a key will need to make sure that it does not
        contain '.' as it will throw ember off
      */
      const normalizedUrl = (0, _helpers.normalizeURL)(url);
      const cleanedUrl = (0, _helpers.cleanURL)(normalizedUrl);

      /*
       */
      const existingProxy = Ember.get(this, `sockets.${cleanedUrl}`);

      if (existingProxy && this.isWebSocketOpen(existingProxy.socket)) {
        return existingProxy;
      }

      /*
        we can get to this place if the websocket has been closed and we are trying to reopen
        or we are creating a proxy for the first time
      */
      const newWebSocket = this.createSocket(normalizedUrl, protocols);

      if (existingProxy) {
        /*
          If there is an existing socket in place we simply update the websocket object and not
          the whole proxy as we dont want to destroy the previous listeners.
        */
        Ember.set(existingProxy, 'socket', newWebSocket);
        return existingProxy;
      }

      const newProxy = this.createProxy(newWebSocket, protocols);

      Ember.set(this, `sockets.${cleanedUrl}`, newProxy);

      return newProxy;
    },

    closeSocketFor(url) {
      const cleanedUrl = (0, _helpers.cleanURL)((0, _helpers.normalizeURL)(url));
      const existingSocket = Ember.get(this, `sockets.${cleanedUrl}`);
      if (existingSocket) {
        existingSocket.socket.close();
      }
      delete Ember.get(this, 'sockets')[cleanedUrl];
    },

    isWebSocketOpen(websocket) {
      return websocket.readyState !== WebSocket.CLOSED;
    },

    createSocket(url, options) {
      return new WebSocket(url, options);
    },

    createProxy(socket, protocols) {
      return _websocketProxy.default.create({ content: this, protocols, socket });
    }
  });
});