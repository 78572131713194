define("ember-keycloak-auth/mixins/keycloak-authenticated-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Ember Mixin that can be combined with an ember route. It is intended to check that the keycloak session is fresh
   * before a route transition. If the keycloak session is not valid the keycloak session will redirect the browser
   * (by default to the keycloak log in page.
   */
  var _default = Ember.Mixin.create({
    session: Ember.inject.service('keycloak-session'),

    beforeModel(transition) {
      this._super(...arguments);

      let session = this.get('session');
      return session.checkTransition(transition);
    }

  });

  _exports.default = _default;
});