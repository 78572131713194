define('ember-pad/helpers/pad-end', ['exports', 'ember-pad/utils/pad'], function (exports, _pad) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.padEnd = padEnd;
  function padEnd(params) {
    return (0, _pad.padEnd)(params[0], params[1], params[2]);
  }

  exports.default = Ember.Helper.helper(padEnd);
});