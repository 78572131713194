define("iris/components/avatar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Xcwt/YQ",
    "block": "{\"symbols\":[],\"statements\":[[2,\"  If image is not set, fallback to initials \"],[0,\"\\n\"],[5,\"ember-initials\",[],[[\"@image\",\"@name\",\"@alt\",\"@class\",\"@seedText\"],[[23,0,[\"img\"]],[23,0,[\"avatarName\"]],[23,0,[\"name\"]],[23,0,[\"class\"]],[23,0,[\"name\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/avatar/template.hbs"
    }
  });

  _exports.default = _default;
});