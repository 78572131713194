define("iris/templates/cmdb/wazuh-reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DHqkvGyi",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"menus.wazuh-reports\"],null]],null],false],[0,\"\\n\"],[5,\"tables/wazuh-report\",[],[[\"@filter\"],[[28,\"hash\",null,[[\"current\"],[true]]]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/templates/cmdb/wazuh-reports.hbs"
    }
  });

  _exports.default = _default;
});