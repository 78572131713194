define("iris/components/forms/file-form/account-multi-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LI/+ablR",
    "block": "{\"symbols\":[\"q\",\"option\",\"@selected\",\"@placeholder\",\"@onchange\",\"@disabled\"],\"statements\":[[5,\"store/query\",[],[[\"@modelName\",\"@filter\"],[\"account\",[23,0,[\"searchCondition\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"style\",\"display: flex\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[28,\"if\",[[23,1,[\"error\"]],\"has-error\"],null]],[10,\"style\",\"flex-grow: 1;\"],[8],[0,\"\\n      \"],[5,\"power-select-multiple\",[],[[\"@searchEnabled\",\"@searchField\",\"@options\",\"@selected\",\"@placeholder\",\"@search\",\"@onChange\",\"@disabled\"],[true,\"name\",[28,\"sort-by\",[\"longName\",[28,\"compute\",[[28,\"action\",[[23,0,[]],\"excludeSelectedOptions\"],null],[23,3,[]],[23,1,[\"result\"]]],null]],null],[23,3,[]],[28,\"store-query-message\",[[23,4,[]]],[[\"query\"],[[23,1,[]]]]],[28,\"perform\",[[23,0,[\"setSearchTermTask\"]]],null],[23,5,[]],[23,6,[]]]],{\"statements\":[[0,\"\\n        \"],[7,\"span\",true],[8],[0,\"\\n            \"],[5,\"avatar\",[],[[\"@class\",\"@image\",\"@name\"],[\"user-avatar\",[23,2,[\"userPhoto\"]],[23,2,[\"longName\"]]]]],[0,\"\\n          \"],[1,[23,2,[\"longName\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[2]}],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"error\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",\"btn-link\"],[12,\"style\",\"color: #C36060\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,1,[\"trigger\"]]]],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"refresh\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/forms/file-form/account-multi-select/template.hbs"
    }
  });

  _exports.default = _default;
});