define("iris/components/useful-links/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KJMM3S5I",
    "block": "{\"symbols\":[\"link\",\"@data\",\"@close\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-content mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"h4 modal-title\"],[8],[0,\"\\n      \"],[1,[23,2,[\"title\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\"],[4,\"let\",[[23,2,[\"link\"]]],null,{\"statements\":[[0,\"      \"],[5,\"bs-form\",[[12,\"class\",\"form-bordered\"]],[[\"@model\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n        \"],[5,\"form-group\",[],[[\"@label\",\"@property\"],[[28,\"t\",[\"components.useful-links.name\"],null],\"name\"]],{\"statements\":[[0,\"\\n          \"],[5,\"input\",[],[[\"@value\",\"@class\"],[[23,1,[\"name\"]],\"form-control\"]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\\n        \"],[5,\"form-group\",[],[[\"@label\",\"@property\"],[[28,\"t\",[\"components.useful-links.url\"],null],\"url\"]],{\"statements\":[[0,\"\\n          \"],[5,\"input\",[],[[\"@value\",\"@class\"],[[23,1,[\"url\"]],\"form-control\"]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[5,\"iris-button\",[],[[\"@type\",\"@action\",\"@disabled\",\"@text\"],[\"primary\",[28,\"action\",[[23,0,[]],\"validate\"],null],[28,\"not\",[[28,\"and\",[[23,0,[\"link\",\"name\"]],[23,0,[\"link\",\"url\"]]],null]],null],[28,\"or\",[[23,2,[\"okButtonLabel\"]],[28,\"t\",[\"buttons.save\"],null]],null]]]],[0,\"\\n\\n    \"],[5,\"iris-button\",[],[[\"@action\",\"@text\"],[[28,\"fn\",[[23,3,[]],false],null],[28,\"or\",[[23,2,[\"okButtonLabel\"]],[28,\"t\",[\"buttons.close\"],null]],null]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "iris/components/useful-links/item/template.hbs"
    }
  });

  _exports.default = _default;
});