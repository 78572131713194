define("ember-table/components/ember-thead/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OeDNQDl9",
    "block": "{\"symbols\":[\"api\",\"&default\"],\"statements\":[[4,\"each\",[[23,0,[\"wrappedRows\"]]],null,{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[28,\"hash\",null,[[\"cells\",\"isHeader\",\"rowsCount\",\"row\"],[[23,1,[\"cells\"]],[23,1,[\"isHeader\"]],[23,1,[\"rowsCount\"]],[28,\"component\",[\"ember-tr\"],[[\"api\"],[[23,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"ember-tr\",null,[[\"api\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-thead/template.hbs"
    }
  });

  _exports.default = _default;
});