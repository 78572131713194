define("ember-keycloak-auth/services/keycloak-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Promise
  } = Ember.RSVP;

  var _default = Ember.Service.extend({
    routingService: Ember.inject.service('-routing'),
    name: 'keycloak session',

    /**
     * Value used in calls to KeyCloak.updateToken(minValidity)
     */
    minValidity: 30,

    /**
     * Bound property to track session state. Indicates that a keycloak session has been successfully created.
     */
    ready: false,

    /**
     * Bound property to track session state. Indicates that the session has authenticated.
     */
    authenticated: false,

    /**
     * Bound property to track session state. Track last activity time.
     */
    timestamp: null,

    /**
     * Keycloak.init() option. Should be one of 'check-sso' or 'login-required'.
     * See http://www.keycloak.org/documentation.html for complete details.
     */
    onLoad: 'login-required',

    /**
     * Keycloak.init() option. Should be one of 'query' or 'fragment'.
     * See http://www.keycloak.org/documentation.html for complete details.
     */
    responseMode: 'fragment',

    /**
     * Keycloak.init() option. Should be one of 'standard', 'implicit' or 'hybrid'.
     * See http://www.keycloak.org/documentation.html for complete details.
     */
    flow: 'standard',

    /**
     * Keycloak.init() option.
     */
    checkLoginIframe: true,

    /**
     * Keycloak.init() option.
     */
    checkLoginIframeInterval: 5,

    /**
     * Keycloak.login() option.
     */
    idpHint: null,

    /**
     * @param parameters constructor parameters for Keycloak object - see Keycloak JS adapter docs for details
     */
    installKeycloak(parameters) {
      Ember.debug('Keycloak session :: keycloak');
      let self = this;
      let keycloak = new Keycloak(parameters);

      keycloak.onReady = function (authenticated) {
        Ember.debug(`onReady ${authenticated}`);
        self.set('ready', true);
        self.set('authenticated', authenticated);
        self.set('timestamp', new Date());
      };

      keycloak.onAuthSuccess = function () {
        Ember.debug('onAuthSuccess');
        self.set('authenticated', true);
        self.set('timestamp', new Date());
      };

      keycloak.onAuthError = function () {
        Ember.debug('onAuthError');
        self.set('authenticated', false);
        self.set('timestamp', new Date());
      };

      keycloak.onAuthRefreshSuccess = function () {
        Ember.debug('onAuthRefreshSuccess');
        self.set('authenticated', true);
        self.set('timestamp', new Date());
      };

      keycloak.onAuthRefreshError = function () {
        Ember.debug('onAuthRefreshError');
        self.set('authenticated', false);
        self.set('timestamp', new Date());
        keycloak.clearToken();
      };

      keycloak.onTokenExpired = function () {
        Ember.debug('onTokenExpired');
        self.set('authenticated', false);
        self.set('timestamp', new Date());
      };

      keycloak.onAuthLogout = function () {
        Ember.debug('onAuthLogout');
        self.set('authenticated', false);
        self.set('timestamp', new Date());
      };

      Ember.Application.keycloak = keycloak;
      Ember.debug('Keycloak session :: init :: completed');
    },

    initKeycloak() {
      Ember.debug('Keycloak session :: prepare');
      let keycloak = this.get('keycloak');
      let options = this.getProperties('onLoad', 'responseMode', 'checkLoginIframe', 'checkLoginIframeInterval', 'flow'); //options['onLoad'] = this.get('onLoad');
      //options['responseMode'] = this.get('responseMode');
      //options['checkLoginIframe'] = this.get('checkLoginIframe');
      //options['checkLoginIframeInterval'] = this.get('checkLoginIframeInterval');
      //options['flow'] = this.get('flow');

      return new Promise((resolve, reject) => {
        keycloak.init(options).success(authenticated => {
          resolve(authenticated);
        }).error(reason => {
          reject(reason);
        });
      });
    },

    keycloak: Ember.computed('timestamp', () => Ember.Application.keycloak),
    subject: Ember.computed('timestamp', () => Ember.Application.keycloak.subject),
    refreshToken: Ember.computed('timestamp', () => Ember.Application.keycloak.refreshToken),
    token: Ember.computed('timestamp', () => Ember.Application.keycloak.token),
    tokenParsed: Ember.computed('timestamp', () => Ember.Application.keycloak.tokenParsed),

    hasRealmRole(role) {
      return Ember.Application.keycloak.hasRealmRole(role);
    },

    hasResourceRole(role, resource) {
      //If resource is null then clientId is used
      return Ember.Application.keycloak.hasResourceRole(role, resource);
    },

    updateToken() {
      // debug(`Keycloak session :: updateToken`);
      let minValidity = this.get('minValidity');
      let keycloak = this.get('keycloak');
      return new Promise((resolve, reject) => {
        keycloak.updateToken(minValidity).success(refreshed => {
          // debug(`update token resolved as success refreshed='${refreshed}'`);
          resolve(refreshed);
        }).error(() => {
          Ember.debug('update token resolved as error');
          reject(new Error('authentication token update failed'));
        });
      });
    },

    checkTransition(transition) {
      let self = this;
      let routingService = this.get('routingService');
      let router = this.get('routingService.router');
      let parser = this._parseRedirectUrl;
      return this.updateToken().then(null, reason => {
        Ember.debug(`Keycloak session :: checkTransition :: update token failed reason='${reason}'`);
        let redirectUri = parser(routingService, router, transition);
        return self.login(redirectUri);
      });
    },

    /**
     * Parses the redirect url from the intended route of a transition. WARNING : this relies on private methods in an
     * undocumented class.
     *
     * @param routingService
     * @param router
     * @param transition
     * @returns URL to include as the Keycloak redirect
     * @private
     */
    _parseRedirectUrl(routingService, router, transition) {
      /**
       * First check the intent for an explicit url
       */
      let url = transition.intent.url;

      if (url) {
        url = router.location.formatURL(url);
        Ember.debug(`Keycloak session :: parsing explicit intent URL from transition :: '${url}'`);
      } else {
        /**
         * If no explicit url try to generate one
         */
        url = routingService.generateURL(transition.targetName, transition.intent.contexts, transition.queryParams);
        Ember.debug(`Keycloak session :: parsing implicit intent URL from transition :: '${url}'`);
      }

      return `${window.location.origin}${url}`;
    },

    loadUserProfile() {
      let self = this;
      this.get('keycloak').loadUserProfile().success(profile => {
        Ember.debug(`Loaded profile for ${profile.id}`);
        self.set('profile', profile);
      });
    },

    /**
     * @param url optional redirect url - if not present the
     */
    login(redirectUri) {
      let keycloak = this.get('keycloak');
      let options = {
        redirectUri
      }; //Add idpHint to options, if it is populated

      if (this.get('idpHint')) {
        options['idpHint'] = this.get('idpHint');
      }

      Ember.debug(`Keycloak session :: login :: ${JSON.stringify(options)}`);
      return new Promise((resolve, reject) => {
        keycloak.login(options).success(() => {
          Ember.debug('Keycloak session :: login :: success');
          resolve('login OK');
        }).error(() => {
          Ember.debug('login error - this should never be possible');
          reject(new Error('login failed'));
        });
      });
    },

    /**
     * @param url optional redirect url - if not present the
     */
    logout(redirectUri) {
      let keycloak = this.get('keycloak');
      let options = {
        redirectUri
      };
      Ember.debug(`Keycloak session :: logout :: ${JSON.stringify(options)}`);
      return new Promise((resolve, reject) => {
        keycloak.logout(options).success(() => {
          Ember.debug('Keycloak session :: logout :: success');
          keycloak.clearToken();
          resolve('logout OK');
        }).error(() => {
          Ember.debug('logout error - this should never be possible');
          keycloak.clearToken();
          reject(new Error('logout failed'));
        });
      });
    }

  });

  _exports.default = _default;
});