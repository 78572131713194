define("iris/models/incoming-invoice", ["exports", "ember-data", "ember-data-copyable", "ember-data/attr", "bankers-rounding", "ember-data/relationships", "ember-cp-validations"], function (_exports, _emberData, _emberDataCopyable, _attr, _bankersRounding, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    accountNumber: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 30
    })],
    subject: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    partner: [(0, _emberCpValidations.validator)('presence', true)],
    exchangeRate: [(0, _emberCpValidations.validator)('presence', true)],
    currency: [(0, _emberCpValidations.validator)('presence', true)],
    paymentMethod: [(0, _emberCpValidations.validator)('presence', true)],
    invoiceOwner: [(0, _emberCpValidations.validator)('presence', true)],
    fileBusinessType: [(0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)],
    epr: [(0, _emberCpValidations.validator)('presence', true)],
    eprDate: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      // eslint-disable-next-line ember/require-computed-macros
      disabled: Ember.computed('model.epr', function () {
        return !this.get('model.epr');
      }).volatile(),
      dependentKeys: ['model.epr']
    })]
  });
  let now = new Date();

  var _default = _emberData.default.Model.extend(_emberDataCopyable.default, Validations, {
    accountNumber: (0, _attr.default)('string'),
    subject: (0, _attr.default)('string'),
    dateFulfilled: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    dateIssued: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    dateEntered: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    datePaymentDue: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    datePaid: (0, _attr.default)('date'),
    dateSettlement: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    netto: (0, _attr.default)('number'),
    vat: (0, _attr.default)('number'),
    paidBalance: (0, _attr.default)('number'),
    supplierDeliverNotes: (0, _attr.default)('string'),
    supplierProjectNumber: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    forwardedToAccounting: (0, _attr.default)('date'),
    internalAccounting: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    needsAction: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    checklist: (0, _attr.default)('json'),
    exchangeRate: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    originalInvoiceNumber: (0, _attr.default)('string'),
    navNetto: (0, _attr.default)('number'),
    navBrutto: (0, _attr.default)('number'),
    navDifference: (0, _attr.default)('number'),
    epr: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    eprDate: (0, _attr.default)('date', {
      defaultValue: function () {
        return now;
      }
    }),
    checked: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    info: (0, _attr.default)('json'),
    project: (0, _relationships.belongsTo)('project'),
    currency: (0, _relationships.belongsTo)('currency'),
    paymentMethod: (0, _relationships.belongsTo)('paymentMethod'),
    invoiceType: (0, _relationships.belongsTo)('invoiceType'),
    invoiceOwner: (0, _relationships.belongsTo)('partner'),
    partner: (0, _relationships.belongsTo)('partner'),
    enteredUser: (0, _relationships.belongsTo)('account'),
    fileBusinessType: (0, _relationships.belongsTo)('fileBusinessType'),
    dataSource: (0, _relationships.belongsTo)('dataSource'),
    originalInvoice: (0, _relationships.belongsTo)('incomingInvoice'),
    items: (0, _relationships.hasMany)('incomingInvoiceItem'),
    payments: (0, _relationships.hasMany)('incomingInvoicePayment'),
    documents: (0, _relationships.hasMany)('document'),
    // ***** computed properities *****
    isSimplified: Ember.computed.equal('invoiceType.id', 'SYS_07'),
    gross: Ember.computed('nett', 'vat', 'navBrutto', function () {
      return this.get('navBrutto') || this.get('netto') + this.get('vat');
    }),
    sumNet: Ember.computed('roundingDigits', 'items.[]', 'items.@each.quantity', 'items.@each.price', 'items.@each.discount', 'items.@each.vatRel', 'isSimplified', function () {
      const sum = this.get('items').reduce((prev, item) => {
        const n = item.get('price') * item.get('quantity') - item.get('discount');
        const v = 1 + item.get('vatRel.vatValue');
        const sum = this.isSimplified ? n / v : n;
        const rounded = (0, _bankersRounding.default)(prev + sum, 2);
        return rounded;
      }, 0);
      return (0, _bankersRounding.default)(sum, this.roundingDigits);
    }),
    roundingDigits: Ember.computed('currency.round', function () {
      return this.currency.get('round') || 0;
    }),
    sumGross: Ember.computed('items.[]', 'roundingDigits', 'items.@each.quantity', 'items.@each.price', 'items.@each.discount', 'items.@each.vatRel', 'isSimplified', function () {
      const sum = this.get('items').reduce((prev, item) => {
        const v = 1 + item.get('vatRel.vatValue');
        const n = item.get('price') * item.get('quantity') - item.get('discount');
        let sum = this.isSimplified ? n : n * v;
        return prev + sum;
      }, 0);
      return (0, _bankersRounding.default)(sum, this.roundingDigits);
    }),
    keepTrackPayments: Ember.computed.reads('accountNumber'),
    itemTypes: Ember.computed('items.@each.service', function () {
      let items = this.get('items');
      const services = items.filter(item => item.get('service'));
      items = items.toArray();
      if (services.length === items.length) return 'ALL_SERVICES';

      if (services.length === 0) {
        return 'ALL_HW';
      } else {
        return 'MIXED';
      }
    })
  });

  _exports.default = _default;
});