define("ember-provider/initializers/set-provider-registration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(owner) {
    owner.registerOptionsForType('provider', {
      instantiate: false
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});