define("iris/models/ticket-user-assoc", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "ember-data-copyable"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    user: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.internalWork')
    })],
    supplier: [(0, _emberCpValidations.validator)('ensure-loaded-relation'), (0, _emberCpValidations.validator)('presence', {
      presence: true,
      // eslint-disable-next-line ember/require-computed-macros
      disabled: Ember.computed('model.internalWork', function () {
        return this.get('model.internalWork');
      }).volatile(),
      dependentKeys: ['model.internalWork']
    })],
    startDate: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    deadline: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('inline', {
      validate(value, options, model, attribute) {
        return moment(value).isAfter(model.startDate) ? true : `A "Befejezés ideje" nem lehet kisebb, vagy ugyan az, mint a "Kezdés ideje"`;
      }

    })],
    plannedExpensesInHour: [(0, _emberCpValidations.validator)('planned-expenses')]
  });

  var _default = _model.default.extend(_emberDataCopyable.default, Validations, {
    plannedExpensesInHour: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    internalWork: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    userId: (0, _attr.default)('string'),
    workplaceAddress: (0, _relationships.belongsTo)('address'),
    user: (0, _relationships.belongsTo)('account'),
    supplier: (0, _relationships.belongsTo)('supplier'),
    ticket: (0, _relationships.belongsTo)('ticket'),
    ticketState: (0, _relationships.belongsTo)('ticketState'),
    startDate: (0, _attr.default)('date'),
    remainingTime: (0, _attr.default)('number'),
    deadline: (0, _attr.default)('date'),
    workLogs: Ember.computed.alias('ticket.workLogs'),
    filteredWorkLogs: Ember.computed('workLogs.[]', 'user', function () {
      const id = this.get('user.id');

      if (!this.get('workLogs')) {
        return Ember.A();
      }

      return this.get('workLogs').filter(workLog => {
        return workLog.belongsTo('user').id() === id;
      });
    }),
    mappedPublicSpentTime: Ember.computed.mapBy('filteredWorkLogs', 'publicSpentTime'),
    mappedSpentTime: Ember.computed.mapBy('filteredWorkLogs', 'spentTime'),
    sumPublicSpentTime: Ember.computed.sum('mappedPublicSpentTime'),
    sumSpentTime: Ember.computed.sum('mappedSpentTime'),
    allSpentTime: Ember.computed('sumPublicSpentTime', 'sumSpentTime', function () {
      return this.get('sumPublicSpentTime') + this.get('sumSpentTime');
    }),
    resourcePlans: (0, _relationships.hasMany)('resourcePlan'),
    availableSpentTimes: Ember.computed('allSpentTime', 'plannedExpensesInHour', function () {
      return this.plannedExpensesInHour - this.allSpentTime;
    }),

    /*
    sumSpentTime: attr('number'),
    sumPublicSpentTime: attr('number'),
    allSpentTime: attr('number'),
    */
    isValid: Ember.computed.alias('validations.isValid'),

    reset() {
      this._super();

      let offset = 15;
      let date = moment();
      let val = date.minutes();
      let roundedVal = Math.ceil((val + 1) / offset) * offset;
      date.minutes(roundedVal);
      this.set('startDate', date);
      this.set('deadline', date.add(2, 'w'));
      this.set('internalWork', true);
      this.unsetValidated();
    }

  });

  _exports.default = _default;
});