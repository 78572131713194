define("ember-cli-flash/components/flash-message", ["exports", "ember-cli-flash/templates/components/flash-message", "ember-cli-flash/utils/get-with-default"], function (_exports, _flashMessage, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    and,
    bool,
    readOnly,
    not
  } = Ember.computed;
  const {
    next,
    cancel
  } = Ember.run;

  var _default = Ember.Component.extend({
    layout: _flashMessage.default,
    active: false,
    messageStyle: 'bootstrap',
    classNames: ['flash-message'],
    classNameBindings: ['alertType', 'active', 'exiting'],
    attributeBindings: ['aria-label', 'aria-describedby', 'role'],
    showProgress: readOnly('flash.showProgress'),
    notExiting: not('exiting'),
    showProgressBar: and('showProgress', 'notExiting'),
    exiting: readOnly('flash.exiting'),
    hasBlock: bool('template').readOnly(),
    alertType: Ember.computed('flash.type', {
      get() {
        const flashType = (0, _getWithDefault.default)(this, 'flash.type', '');
        const messageStyle = (0, _getWithDefault.default)(this, 'messageStyle', '');
        let prefix = 'alert alert-';

        if (messageStyle === 'foundation') {
          prefix = 'alert-box ';
        }

        return `${prefix}${flashType}`;
      }

    }),
    flashType: Ember.computed('flash.type', {
      get() {
        const flashType = (0, _getWithDefault.default)(this, 'flash.type', '');
        return Ember.String.classify(flashType);
      }

    }),

    didInsertElement() {
      this._super(...arguments);

      const pendingSet = next(this, () => {
        Ember.set(this, 'active', true);
      });
      Ember.set(this, 'pendingSet', pendingSet);
      this.set('_mouseEnterHandler', this._mouseEnter.bind(this));
      this.set('_mouseLeaveHandler', this._mouseLeave.bind(this));
      this.element.addEventListener('mouseenter', this._mouseEnterHandler);
      this.element.addEventListener('mouseleave', this._mouseLeaveHandler);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.element.removeEventListener('mouseenter', this._mouseEnterHandler);
      this.element.removeEventListener('mouseleave', this._mouseLeaveHandler);
    },

    progressDuration: Ember.computed('flash.showProgress', {
      get() {
        if (!Ember.get(this, 'flash.showProgress')) {
          return false;
        }

        const duration = (0, _getWithDefault.default)(this, 'flash.timeout', 0);
        return Ember.String.htmlSafe(`transition-duration: ${duration}ms`);
      }

    }),

    click() {
      const destroyOnClick = (0, _getWithDefault.default)(this, 'flash.destroyOnClick', true);

      if (destroyOnClick) {
        this._destroyFlashMessage();
      }
    },

    _mouseEnter() {
      const flash = Ember.get(this, 'flash');

      if (Ember.isPresent(flash)) {
        flash.preventExit();
      }
    },

    _mouseLeave() {
      const flash = Ember.get(this, 'flash');

      if (Ember.isPresent(flash) && !Ember.get(flash, 'exiting')) {
        flash.allowExit();
      }
    },

    willDestroy() {
      this._super(...arguments);

      this._destroyFlashMessage();

      cancel(Ember.get(this, 'pendingSet'));
    },

    // private
    _destroyFlashMessage() {
      const flash = (0, _getWithDefault.default)(this, 'flash', false);

      if (flash) {
        flash.destroyMessage();
      }
    },

    actions: {
      close() {
        this._destroyFlashMessage();
      }

    }
  });

  _exports.default = _default;
});