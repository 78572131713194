define("@kockpit/ember-gantt/templates/components/gantt-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CL3Eu7Zk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"gantt-line\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"gantt-line-title\"],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1,[[28,\"hash\",null,[[\"title\"],[[28,\"component\",[\"gantt-line-title\"],[[\"line\"],[[23,0,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"gantt-line-timeline\"],[8],[0,\"\\n\\n\"],[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"gantt-line-bar \",[28,\"if\",[[24,[\"isEditable\"]],\"gantt-line-bar-editable\"],null]]]],[11,\"style\",[22,\"barStyle\"]],[11,\"title\",[22,\"barTitle\"]],[8],[0,\"\\n      \"],[14,1,[[28,\"hash\",null,[[\"inlineChilds\",\"barContent\"],[[28,\"component\",[\"gantt-line-inline-childs\"],[[\"parentLine\",\"chart\"],[[23,0,[]],[24,[\"chart\"]]]]],[28,\"component\",[\"gantt-line-barcontent\"],[[\"line\"],[[23,0,[]]]]]]]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isEditable\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"bar-resize-l\"],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"bar-resize-r\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[0,\"    \"],[14,1,[[28,\"hash\",null,[[\"milestone\"],[[28,\"component\",[\"gantt-milestone\"],[[\"line\",\"chart\"],[[23,0,[]],[24,[\"chart\"]]]]]]]]]],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\\n\\n\"],[7,\"div\",true],[10,\"class\",\"gantt-line-childs\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"childLine\"],[[28,\"component\",[\"gantt-line\"],[[\"chart\",\"parentLine\"],[[24,[\"chart\"]],[23,0,[]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@kockpit/ember-gantt/templates/components/gantt-line.hbs"
    }
  });

  _exports.default = _default;
});