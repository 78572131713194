define("ember-table/components/ember-tfoot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D8fLpeu5",
    "block": "{\"symbols\":[\"rowValue\",\"api\",\"&default\"],\"statements\":[[4,\"each\",[[23,0,[\"wrappedRowArray\"]]],null,{\"statements\":[[4,\"-ember-table-private/row-wrapper\",null,[[\"rowValue\",\"columns\",\"columnMetaCache\",\"rowMetaCache\",\"canSelect\",\"rowSelectionMode\",\"checkboxSelectionMode\",\"rowsCount\"],[[23,1,[]],[23,0,[\"columns\"]],[23,0,[\"columnMetaCache\"]],[23,0,[\"rowMetaCache\"]],[23,0,[\"canSelect\"]],[23,0,[\"rowSelectionMode\"]],[23,0,[\"checkboxSelectionMode\"]],[23,0,[\"wrappedRowArray\",\"length\"]]]],{\"statements\":[[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"      \"],[14,3,[[28,\"hash\",null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"rowsCount\",\"row\"],[[23,2,[\"rowValue\"]],[23,2,[\"rowMeta\"]],[23,2,[\"cells\"]],[23,2,[\"rowSelectionMode\"]],[23,2,[\"rowsCount\"]],[28,\"component\",[\"ember-tr\"],[[\"api\"],[[23,2,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"ember-tr\",null,[[\"api\"],[[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-table/components/ember-tfoot/template.hbs"
    }
  });

  _exports.default = _default;
});