define("ember-keycloak-auth/components/keycloak-session-profile", ["exports", "ember-keycloak-auth/templates/components/keycloak-session-profile"], function (_exports, _keycloakSessionProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _keycloakSessionProfile.default,
    token: null,
    session: Ember.inject.service('keycloak-session'),
    roles: Ember.computed('token', function () {
      const token = Ember.get(this, 'session.tokenParsed');
      const array = [];

      if (token) {
        const access = token['resource_access'];
        Object.keys(access).forEach(k => {
          const roles = access[k]['roles'];
          roles.forEach(r => array.push(`${k}/${r}`));
        });
      }

      return array;
    }),
    actions: {
      loadUserProfile() {
        this.get('session').loadUserProfile();
      }

    }
  });

  _exports.default = _default;
});