define("iris/components/beta/cmdb/component", ["exports", "@bower_components/file-saver", "deepmerge", "ember-concurrency", "iris/classes/filter/filter", "iris/config/environment", "iris/lib/filters", "iris/lib/filters/api", "iris/lib/filters/column", "iris/lib/filters/filter", "iris/lib/filters/storage", "iris/lib/filters/types", "iris/lib/grid", "iris/lib/modals", "iris/lib/table", "iris/utils/converter", "moment"], function (_exports, _fileSaver, _deepmerge, _emberConcurrency, _filter, _environment, _filters, _api, _column, _filter2, _storage, _types, _grid, _modals, _table, _converter, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const ATTRIBUTE_TYPE_CONVERSION_MAP = {
    [_filter.AttributeType.String]: _types.AttributeType.String,
    [_filter.AttributeType.Number]: _types.AttributeType.Number,
    [_filter.AttributeType.Checkbox]: _types.AttributeType.Boolean,
    [_filter.AttributeType.Date]: _types.AttributeType.Date,
    [_filter.AttributeType.DateTime]: _types.AttributeType.Date,
    [_filter.AttributeType.Array]: _types.AttributeType.String,
    [_filter.AttributeType.BelongsTo]: _types.AttributeType.String,
    [_filter.AttributeType.HasMany]: _types.AttributeType.String,
    [_filter.AttributeType.Textarea]: _types.AttributeType.String
  };
  let BetaCMDBComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = (0, _storage.useStorage)(), _dec8 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec9 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec10 = Ember.computed.alias('customAttributesTask.last.value'), _dec11 = Ember.computed.alias('entityAttributesTask.last.value'), _dec12 = (0, _column.columns)('customAttributes', 'entityAttributes', 'entityTypeId', function () {
    var _this$customAttribute;

    let categoryId;

    if (this.entityTypeId) {
      const entityType = this.store.peekRecord('entityType', this.entityTypeId);
      categoryId = entityType.belongsTo('entityTypeCategory').id();
    }

    const {
      intl
    } = this;
    let cols = [{
      name: 'categoryId',
      displayName: this.intl.t('cmdb-visualisation.category'),
      type: _types.AttributeType.BelongsTo,
      visibility: _types.AttributeVisibility.Filter,
      optionsResourceName: 'entityTypeCategory'
    }, {
      name: 'relation',
      displayName: this.intl.t('cmdb-visualisation.relation'),
      type: _types.AttributeType.BelongsTo,
      filterTypes: [_types.FilterType.Equal],
      visibility: _types.AttributeVisibility.Filter,
      inputComponentName: 'beta/cmdb/entity-lazy-select'
    }, {
      name: 'relation-master',
      displayName: this.intl.t('cmdb-visualisation.relation-master'),
      type: _types.AttributeType.BelongsTo,
      filterTypes: [_types.FilterType.Equal],
      visibility: _types.AttributeVisibility.Filter,
      inputComponentName: 'beta/cmdb/entity-lazy-select'
    }, {
      name: 'relation-slave',
      displayName: this.intl.t('cmdb-visualisation.relation-slave'),
      type: _types.AttributeType.BelongsTo,
      filterTypes: [_types.FilterType.Equal],
      visibility: _types.AttributeVisibility.Filter,
      inputComponentName: 'beta/cmdb/entity-lazy-select'
    }, {
      name: 'entityTypeCategory',
      displayName: this.intl.t('cmdb-visualisation.categoryName'),
      type: _types.AttributeType.String,
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'wazuh',
      displayName: this.intl.t('forms.wazuh'),
      type: _types.AttributeType.Boolean,
      visibility: _types.AttributeVisibility.Filter,

      formatLoopBackValue(value, filter, defaultFormatter) {
        if (filter.filterType === _types.FilterType.Boolean) {
          return value ? 1 : 0;
        }

        return defaultFormatter(value);
      }

    }, {
      name: 'monitoring',
      displayName: this.intl.t('cmdb-visualisation.monitoring'),
      type: _types.AttributeType.Boolean,
      visibility: _types.AttributeVisibility.Filter,

      formatLoopBackValue(value, filter, defaultFormatter) {
        if (filter.filterType === _types.FilterType.Boolean) {
          return value ? 1 : 0;
        }

        return defaultFormatter(value);
      }

    }, {
      name: 'monitoring',
      displayName: '',
      fixed: true,
      resizable: false,
      sortable: false,
      visibility: _types.AttributeVisibility.Table,
      width: _column.ColumnWidth.XS,
      exportable: false
    }, {
      name: 'name',
      displayName: this.intl.t('cmdb-visualisation.name'),
      fixed: true,
      width: _column.ColumnWidth.L,
      route: 'cmdb.entity-types.entities.entity.attributes',
      routeParams: (0, _table.rowValues)('entityTypeId', 'id')
    }, {
      name: 'entityType',
      displayName: this.intl.t('cmdb-visualisation.entityType'),
      visibility: _types.AttributeVisibility.Table
    }, {
      name: 'entityType',
      displayName: this.intl.t('cmdb-visualisation.entityTypeName'),
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'entityTypeId',
      displayName: this.intl.t('cmdb-visualisation.entityType'),
      type: _types.AttributeType.BelongsTo,
      visibility: _types.AttributeVisibility.Filter,
      belongsTo: [{
        categoryId: 'entityTypeCategoryId'
      }],
      createOptions: async (records, nameKey, defaultMapper) => {
        let etcs = await this.api.findAll('entityTypeCategory');
        let groups = etcs.map(etc => {
          return {
            groupName: etc.name,
            options: defaultMapper(records.filter(et => et.belongsTo('entityTypeCategory').id() === etc.id))
          };
        });
        let ungrouped = {
          groupName: 'Egyéb',
          options: defaultMapper(records.filter(et => !et.belongsTo('entityTypeCategory').id()))
        };
        return [...groups, ungrouped];
      }
    }, {
      name: 'partner',
      displayName: this.intl.t('cmdb.entity.partner-owner')
    }, {
      name: 'partnerId',
      displayName: this.intl.t('cmdb.entity.partner-owner'),
      type: _types.AttributeType.BelongsTo,
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'location',
      displayName: this.intl.t('cmdb-visualisation.location'),
      visibility: _types.AttributeVisibility.Filter
    }, {
      name: 'licenced',
      displayName: this.intl.t('cmdb-visualisation.license'),
      type: _types.AttributeType.Boolean,
      visibility: _types.AttributeVisibility.Filter
    }];

    if (!categoryId || categoryId.slice(-9) === '000000001') {
      //Hardware
      cols = [...cols, {
        name: 'serialNumber',
        displayName: this.intl.t('cmdb-visualisation.serialNumber')
      }, {
        name: 'department',
        displayName: this.intl.t('entity-license.department')
      }, {
        name: 'assignedPartner',
        displayName: intl.t('entity-move.type-options.user'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'assignedPartnerId',
        optionsResourceName: 'partner',
        displayName: intl.t('entity-move.type-options.user'),
        type: _types.AttributeType.BelongsTo,
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'assignedContact',
        displayName: this.intl.t('cmdb.entity.assigned-contact'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'assignedContact',
        displayName: this.intl.t('cmdb.entity.assigned-contact'),
        type: _types.AttributeType.BelongsTo,
        visibility: _types.AttributeVisibility.Filter,
        belongsTo: ['assignedPartnerId'],
        optionsResourceName: 'contact',
        optionsResourceAction: _types.OptionsResourceAction.Query,

        // The request sent as `{ contact: { eq: 'Contact Name' }}` where the
        // value is the name of the contact not the ID. To achieve this, we set
        // the option IDs to the names of the contacts queried for the select
        // input.
        createOptions(records, nameKey, defaultMapper) {
          return records.map(_ref => {
            let {
              name
            } = _ref;
            return {
              id: name,
              name
            };
          });
        }

      }, {
        name: 'contact',
        displayName: this.intl.t('cmdb.entity.owner-contact'),
        visibility: _types.AttributeVisibility.Table
      }, {
        name: 'contact',
        displayName: this.intl.t('cmdb.entity.owner-contact'),
        type: _types.AttributeType.BelongsTo,
        visibility: _types.AttributeVisibility.Filter,
        belongsTo: ['partnerId', 'departmentId'],
        optionsResourceAction: _types.OptionsResourceAction.Query,

        // The request sent as `{ contact: { eq: 'Contact Name' }}` where the
        // value is the name of the contact not the ID. To achieve this, we set
        // the option IDs to the names of the contacts queried for the select
        // input.
        createOptions(records, nameKey, defaultMapper) {
          return records.map(_ref2 => {
            let {
              name
            } = _ref2;
            return {
              id: name,
              name
            };
          });
        }

      }, {
        name: 'locationId',
        displayName: this.intl.t('cmdb-visualisation.location'),
        type: _types.AttributeType.BelongsTo,
        belongsTo: ['workSiteId'],
        visibility: _types.AttributeVisibility.Filter
      }, {
        name: 'manufacturer',
        displayName: this.intl.t('cmdb-visualisation.manufacturer')
      }, {
        name: 'productType',
        displayName: this.intl.t('cmdb-visualisation.productType')
      }, {
        name: 'status',
        displayName: this.intl.t('cmdb-visualisation.status'),
        type: _types.AttributeType.BelongsTo,
        optionsResourceName: 'entityStatus',

        // The request sent as `{ status: { eq: 'Entity Status Name' }}` where
        // the value is the name of the `entityStatus` not the ID. To achieve
        // this, we set the option IDs to the names of the entity statuses
        // queried for the select input.
        createOptions(records, nameKey, defaultMapper) {
          return records.map(_ref3 => {
            let {
              name
            } = _ref3;
            return {
              id: name,
              name
            };
          });
        }

      }, {
        name: 'createDate',
        displayName: this.intl.t('models.dateCreate'),
        type: _types.AttributeType.Date
      }];
    } else if (categoryId && categoryId.slice(-9) === '000000002') {
      //Software
      cols = [...cols, {
        name: 'version',
        displayName: this.intl.t('cmdb-visualisation.version')
      }, {
        name: 'productKey',
        displayName: this.intl.t('cmdb-visualisation.productKey')
      }];
    } else if (categoryId && categoryId.slice(-9) === '000000006') {
      //Licence
      cols = [...cols, {
        name: 'accountable',
        displayName: this.intl.t('cmdb-visualisation.accountable')
      }, {
        name: 'expirationDate',
        displayName: this.intl.t('cmdb-visualisation.expirationDate')
      }, {
        name: 'qty',
        displayName: this.intl.t('cmdb-visualisation.quantity')
      }, {
        name: 'availableQty',
        displayName: this.intl.t('cmdb-visualisation.available')
      }];
    }

    cols.concat(((_this$customAttribute = this.customAttributes) !== null && _this$customAttribute !== void 0 ? _this$customAttribute : []).map(_ref4 => {
      let {
        name,
        slug
      } = _ref4;
      return {
        name: slug,
        displayName: `${name} (${slug})`,
        visibility: _types.AttributeVisibility.Filter,
        fuzzySearch: false
      };
    }));

    if (this.entityAttributes) {
      for (const attribute of this.entityAttributes.toArray()) {
        const {
          listable,
          searchable
        } = attribute;

        if (listable !== true && searchable !== true) {
          continue;
        }

        const visibility = listable && searchable ? _types.AttributeVisibility.Both : listable ? _types.AttributeVisibility.Table : _types.AttributeVisibility.Filter;
        cols.push({
          name: attribute.property,
          displayName: `${attribute.name} (${attribute.property})`,
          visibility,
          fuzzySearch: false,
          type: ATTRIBUTE_TYPE_CONVERSION_MAP[attribute.type]
        });
      }
    }

    return cols;
  }), _dec13 = (0, _grid.bulkActions)(function () {
    return [{
      name: this.intl.t('cmdb.entity.handover'),
      icon: 'hand-o-right',

      async action(selection) {
        let entities = await this.api.where('entity', {
          id: {
            inq: selection.mapBy('id')
          }
        });
        let modalResult = await this.modals.open('beta/cmdb/modals/move-entities', {
          entities
        }, {
          className: _modals.ModalLayoutClassName.Grow
        });

        if (modalResult !== null && modalResult !== void 0 && modalResult.shouldRefreshRecords) {
          this.storage.refresh();
        }
      }

    }, {
      name: this.intl.t('cmdb-visualisation.refresh'),
      icon: 'refresh',

      async action(selection) {
        let syncEntityProceduresResult = await this.manageEntityProcedures(selection.mapBy('id')); // Refresh the storage, as procedures could've changed, and because
        // these are not ED records but merely rows of a view, they won't
        // update without requesting the data again.

        this.storage.refresh(); // I wish this could be async...
        // Keep selection only with the IDs that changed

        return selection.filter(record => syncEntityProceduresResult.includes(record.id));
      }

    }, {
      name: this.intl.t('buttons.start-monitoring'),
      icon: 'play',

      async action(selection) {
        let startMonitoringResults = await this.startMonitoringEntities(selection);
        let changedEntityIds = startMonitoringResults.filterBy('status', 'fulfilled').mapBy('value').filter(Boolean);
        let failedEntityIds = startMonitoringResults.filterBy('status', 'rejected').mapBy('reason');

        if (changedEntityIds.length) {
          await this.manageEntityProcedures(changedEntityIds);
        } // Return the failed selection


        return selection.filter(record => failedEntityIds.includes(record.id));
      }

    }, {
      name: this.intl.t('buttons.stop-monitoring'),
      icon: 'stop',

      async action(selection) {
        let stopMonitoringResults = await this.stopMonitoringEntities(selection);
        let failedEntityIds = stopMonitoringResults.filterBy('status', 'rejected').mapBy('reason'); // Return the failed selection

        return selection.filter(record => failedEntityIds.includes(record.id));
      }

    }, {
      name: this.intl.t('buttons.delete'),
      icon: 'trash',

      async action(selection) {
        let ids = selection.map(row => row.id);
        let entities = await this.api.where('entity', {
          id: {
            inq: ids
          }
        }); // TODO: avoid using the router to delete records

        this.router.send('delete', {
          afterDelete: () => this.storage.refresh()
        }, entities.toArray());
      }

    }, {
      name: this.intl.t('filters.export'),
      icon: 'download',

      async action(selection) {
        const groupedEntities = selection.reduce((acc, entity) => {
          const {
            entityTypeId
          } = entity;
          if (!acc[entityTypeId]) acc[entityTypeId] = [];
          acc[entityTypeId].push(entity.id);
          return acc;
        }, {});
        let response = await this.api.collectionAction('entityType', 'download-items', {
          method: 'POST',
          data: {
            groupedEntities
          }
        });
        (0, _fileSaver.saveAs)(_converter.default.b64toBlob(response, 'application/vnd.ms-excel'), `CMDB-export-${(0, _moment.default)().format('YYYY/MM/DD')}.xlsx`);
      }

    }];
  }), _dec14 = (0, _table.rowActions)(function () {
    return [{
      name: this.intl.t('incident-form.visualization'),
      icon: 'eye',
      route: 'cmdb.visualisation',
      routeParams: Ember.computed.alias('row.id')
    }, {
      name: this.intl.t('menus.impact-study'),
      icon: 'bullseye',
      route: 'cmdb.impact-study',
      routeParams: Ember.computed.alias('row.id')
    }];
  }), _dec15 = Ember.computed('entityTypeId'), _dec16 = Ember.computed('entityTypeId'), _dec17 = Ember.computed('columns', 'defaultFilters', 'storage.params'), _dec18 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, (_class = class BetaCMDBComponent extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "openNewItem", false);

      _defineProperty(this, "newItemModal", false);

      _defineProperty(this, "selectedEntityType", null);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "modals", _descriptor2, this);

      _initializerDefineProperty(this, "ajax", _descriptor3, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "intl", _descriptor6, this);

      _initializerDefineProperty(this, "storage", _descriptor7, this);

      _initializerDefineProperty(this, "customAttributes", _descriptor8, this);

      _initializerDefineProperty(this, "entityAttributes", _descriptor9, this);

      _initializerDefineProperty(this, "columns", _descriptor10, this);

      _initializerDefineProperty(this, "bulkActions", _descriptor11, this);

      _initializerDefineProperty(this, "rowActions", _descriptor12, this);

      _defineProperty(this, "predefinedViews", []);
    }

    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
      super.init(...arguments);
      this.customAttributesTask.perform();
    } // eslint-disable-next-line ember/no-component-lifecycle-hooks


    didReceiveAttrs() {
      super.didReceiveAttrs();
      this.entityAttributesTask.perform();
    }

    *customAttributesTask() {
      return yield this.api.query('customAttribute', {
        filter: {
          where: {
            model: 'entity'
          }
        }
      });
    }

    *entityAttributesTask() {
      if (this.entityTypeId) {
        return yield this.api.query('entityAttribute', {
          filter: {
            where: {
              entityTypeId: this.entityTypeId
            }
          }
        });
      } else {
        return null;
      }
    }

    get oldRoute() {
      return this.entityTypeId ? 'cmdb.entity-types.entities' : 'cmdb.entity-types';
    }

    get defaultFilters() {
      return filters => {
        let hasFilterForEntityType = filters.find(filter => ['entityTypeId', 'entityType'].includes(filter.attributeName));

        if (hasFilterForEntityType) {
          // When `entityType*` already exists, we don't want to filter for 'All'
          return [];
        }

        let column = (0, _filters.makeColumn)({
          name: 'entityTypeId'
        });
        let allEntityAttributesFilter = (0, _filters.makeFilterFromColumn)(column, {
          attributeName: 'entityTypeId',
          filterType: _types.FilterType.Equal,
          value: this.entityTypeId ? this.entityTypeId : 'All'
        });
        return [allEntityAttributesFilter];
      };
    }

    get fetchParams() {
      return (0, _filters.makeQueryObject)(this.columns, this.storage.params, {
        defaultFilters: this.defaultFilters,
        serializeCustomFilters: (customAttributeFilters, whereCondition) => {
          if (!(customAttributeFilters !== null && customAttributeFilters !== void 0 && customAttributeFilters.length)) {
            return whereCondition;
          }

          return (0, _deepmerge.default)(whereCondition, {
            and: customAttributeFilters.map(_api.makeLoopBackConditionFromFilter).map(f => ({
              ea: f
            }))
          });
        }
      });
    }

    *queryCustomFiltersTask(term) {
      let result = yield this.api.queryView('entityAttributes/getDistinctEntityAttributes', {
        filter: {
          q: term
        }
      });
      return result.result.data.map(item => {
        let attributeType = ATTRIBUTE_TYPE_CONVERSION_MAP[item.type];
        return (0, _filter2.makeCustomAttributeFilter)({
          displayName: `${item.name} (${item.property})`,
          attributeType,
          attributeName: item.property
        });
      });
    }

    async manageEntityProcedures(entityIds) {
      try {
        let changedEntityIds;

        if (_environment.default.environment === 'development') {
          // Since we don't have Icinga in development, just pretend all entities
          // have changed.
          changedEntityIds = entityIds;
        } else {
          let getProceduresRequests = entityIds.map(entityId => {
            return this.api.collectionAction('entity', 'get-procedures', {
              method: 'POST',
              data: {
                ids: [entityId]
              }
            });
          });
          let getProceduresResults = await Promise.all(getProceduresRequests);
          changedEntityIds = getProceduresResults.map(_ref5 => {
            let {
              result
            } = _ref5;
            return result[0];
          });
        }

        if (!changedEntityIds.length) {
          this.flashMessages.warning(this.intl.t('cmdb-visualisation.noChanges'), {
            title: this.intl.t('cmdb-visualisation.noChangesTitle'),
            icon: 'warning',
            sticky: false
          }); // If nothing changed, just return the original selection.

          return entityIds;
        } else {
          // This will open a modal, which is a promise, and it should return the
          // record IDs that were failed to update their monitoring status.
          return this.modals.open('beta/cmdb/modals/manage-entity-procedures', {
            entityIds: changedEntityIds
          }, {
            className: _modals.ModalLayoutClassName.Grow
          });
        }
      } catch (error) {
        console.error(error);
        this.flashMessages.danger(joinErrorMessages(error), {
          title: this.intl.t('cmdb-visualisation.error'),
          icon: 'warning',
          sticky: true
        });
      }
    }

    startMonitoringEntities(entities) {
      return Promise.allSettled(entities.map(async record => {
        try {
          let response = await this.api.memberAction('entity', 'start-monitoring', record.id, {
            method: 'POST'
          });
          this.flashMessages.warning(record.name, {
            title: this.intl.t('cmdb-visualisation.monitoring-ready'),
            icon: 'info',
            sticky: false
          });

          if (response.result) {
            return record.id;
          }
        } catch (error) {
          this.flashMessages.danger(joinErrorMessages(error), {
            title: this.intl.t('cmdb-visualisation.monitoring-error', {
              recordName: record.name
            }),
            icon: 'warning',
            sticky: true
          });
          return Promise.reject(record.id);
        }
      }));
    }

    stopMonitoringEntities(entities) {
      return Promise.allSettled(entities.map(async record => {
        try {
          let response = await this.api.memberAction('entity', 'stop-monitoring', record.id, {
            method: 'POST'
          });

          if (response.result) {
            this.flashMessages.warning(record.name, {
              title: this.intl.t('cmdb.hardwareStopped'),
              icon: 'info',
              sticky: false
            });
          }
        } catch (error) {
          this.flashMessages.danger(joinErrorMessages(error), {
            title: this.intl.t('cmdb.hardwareError', {
              recordName: record.name
            }),
            icon: 'warning',
            sticky: true
          });
          return Promise.reject(record.id);
        }
      }));
    }

    newItem() {
      let ctrl = Ember.getOwner(this).lookup('controller:cmdb.entity-types.index');
      ctrl.set('oldRoute', 'cmdb.entity-types.entities');
      this.router.transitionTo('cmdb.entity-types.entities.new-entity.attributes');
    }

    newItemModal() {
      Ember.set(this, 'openNewItem', true);
    }

    async openImportModal() {
      const {
        intl
      } = this;
      await this.modals.open('modals/cmdb-import', {
        title: intl.t('cmdb-import.title')
      }, {
        className: _modals.ModalLayoutClassName.XL
      });
    }

    closeNewModal() {
      Ember.set(this, 'openNewItem', false);
    }

    computeEntityTypeCategoryOptions(entityTypeCategories) {
      return entityTypeCategories.map(etc => {
        return {
          groupName: etc.get('name'),
          options: etc.get('entityTypes').filterBy('codeStore', 0).sortBy('longName')
        };
      });
    }

    selectEntityType(entityType) {
      Ember.set(this, 'selectedEntityType', entityType);
    }

    save() {
      let ctrl = Ember.getOwner(this).lookup('controller:cmdb.entity-types.index');
      ctrl.set('oldRoute', 'cmdb.entity-types.index');
      let {
        id
      } = this.selectedEntityType;
      this.router.transitionTo('cmdb.entity-types.entities.new-entity.attributes', id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "customAttributesTask", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "customAttributesTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "entityAttributesTask", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "entityAttributesTask"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "customAttributes", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "entityAttributes", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "columns", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "bulkActions", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "rowActions", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "oldRoute", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "oldRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "defaultFilters", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "defaultFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchParams", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "fetchParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "queryCustomFiltersTask", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "queryCustomFiltersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newItem", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "newItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newItemModal", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "newItemModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openImportModal", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "openImportModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeNewModal", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "closeNewModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "computeEntityTypeCategoryOptions", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "computeEntityTypeCategoryOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectEntityType", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "selectEntityType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = BetaCMDBComponent;

  const joinErrorMessages = error => {
    var _error$errors$reduce, _error$errors;

    return (_error$errors$reduce = (_error$errors = error.errors) === null || _error$errors === void 0 ? void 0 : _error$errors.reduce((acc, errorMessage) => {
      return `${acc} ${errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.detail}.`;
    }, '')) !== null && _error$errors$reduce !== void 0 ? _error$errors$reduce : error;
  };
});