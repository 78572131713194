define("iris/components/auto/form/one2-many/component", ["exports", "iris/lib/filters/column", "iris/lib/filters/types", "iris/lib/table", "iris/lib/filters/storage", "iris/lib/filters", "iris/lib/grid", "iris/lib/auto/default-fields", "iris/lib/modals"], function (_exports, _column, _types, _table, _storage, _filters, _grid, _defaultFields, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    api: Ember.inject.service(),
    store: Ember.inject.service(),
    modals: Ember.inject.service(),
    loading: Ember.inject.service(),
    themeSwitcher: Ember.inject.service(),
    scroller: Ember.inject.service(),

    init() {
      this._super(...arguments);

      if (this.limit) {
        this.storage.update('limit', this.limit);
      }
    },

    formModal: 'form-modal',
    inActive: false,
    isItemLoaded: false,
    readOnly: false,
    externalRowActions: [],
    externalBulkActions: null,
    neededFields: [],
    excludeFieldsList: [],
    dropDownActions: [],
    excludeFieldsListAll: Ember.computed('neededFields', 'parentModel', 'excludeFieldsList', function () {
      this.excludeFieldsList.push(this.parentModel.constructor.modelName);
      return this.excludeFieldsList;
    }),
    excludeFieldsForm: [],
    excludeFieldsFormAll: Ember.computed('parentModel', 'excludeFieldsForm', function () {
      this.excludeFieldsForm.push(this.parentModel.constructor.modelName);
      return this.excludeFieldsForm;
    }),
    modelName: Ember.computed('parentModel', 'itemName', function () {
      let relationships = this.parentModel.constructor.relationshipsByName;
      return relationships.get(this.itemName).type;
    }),
    model: Ember.computed('modelName', function () {
      return this.store.modelFor(this.modelName);
    }),
    listModelName: Ember.computed.oneWay('modelName'),
    newRouteLabel: Ember.computed('modelName', function () {
      let translationModel = this.translationModel ? this.translationModel : this.modelName;
      return `${this.intl.t('buttons.new')} ${this.intl.t(`${translationModel}.modelName`)}`;
    }),
    fields: Ember.computed('model', 'excludeFieldsListAll', 'neededFields.length', function () {
      if (this.neededFields.length) {
        return this.neededFields;
      }

      const res = [];
      const self = this;
      this.model.fields.forEach(function (kind, field) {
        if (!self.excludeFieldsListAll.includes(field) && !_defaultFields.DefaultFields.Fields.includes(field)) {
          res.push(field);
        }
      });
      return res;
    }),
    includeFields: Ember.computed('model', 'neededFields', 'excludeFieldsListAll', function () {
      const res = [];
      const self = this;
      const kinds = this.includeOnlyBelongsTo ? ['belongsTo'] : ['belongsTo', 'hasMany'];
      const neededFields = this.neededFields.map(field => {
        return field.substr(0, field.indexOf('.'));
      });
      this.model.eachRelationship(function (name, descriptor) {
        if (self.neededFields.length) {
          if (self.neededFields.map(f => f.split('.')[0]).includes(name) && kinds.includes(descriptor.kind)) {
            res.push(name);
          }
        } else {
          if (!self.excludeFieldsListAll.includes(name) && !_defaultFields.DefaultFields.Fields.includes(name) && kinds.includes(descriptor.kind)) {
            res.push(name);
          }
        }
      });
      return res;
    }),
    storage: (0, _storage.useStorage)('memory'),
    columns: (0, _column.columns)('fields.[]', function () {
      const res = [];
      const self = this;
      let i = 0;
      const k = this.groupBy ? 1 : 0;
      let aMapper = {};
      this.model.attributes.forEach(function (meta, name) {
        aMapper[name] = _types.AttributeMapper[meta.type];
      });
      this.fields.forEach(function (field) {
        let name = 'name';
        let displayName = self.intl.t(`${self.modelName}.${field}`);

        if (field.includes('.')) {
          let ar = field.split('.');
          field = ar[0];
          name = ar[1];
          displayName = self.intl.t(`${self.model.typeForRelationship(field, self.store).modelName}.${name}`);
        }

        let column = {
          name: field,
          displayName
        };
        let relationType = self.model.relationshipsByName.get(field) ? self.model.relationshipsByName.get(field).kind : null;

        if (i === k && !self.readOnly) {
          column['width'] = _column.ColumnWidth.M;
          column['fixed'] = self.groupBy ? false : true;

          if (!self.readOnly) {
            column['action'] = self.editItem;
          }
        } else {
          column['width'] = _column.ColumnWidth.SM;
          column['fixed'] = false;
        }

        if (relationType === 'belongsTo') {
          column['optionsResourceName'] = Ember.String.camelize(self.model.relationshipsByName.get(field).type);
          column['valuePath'] = `${field}.${name}`;
          column['type'] = _types.AttributeType.BelongsTo;
          column['name'] = `${field}Id`;
          column['orderProperty'] = `${field}.${name}`;
        } else if (relationType === 'hasMany') {
          column['visibility'] = _types.AttributeVisibility.Table;
          column['sortable'] = false;
          column['exportable'] = false;
        } else {
          column['type'] = aMapper[field];
        } // Overwrites default options


        if (self.columnOverwrites && (field in self.columnOverwrites || `${field}.${name}` in self.columnOverwrites)) {
          column = { ...column,
            ...self.columnOverwrites[field]
          };
        }

        res.push(column);
        i++;
      });
      return res;
    }),
    rowActions: (0, _table.rowActions)(function () {
      if (this.readOnly || this.noDelete) {
        return this.externalRowActions;
      }

      return [...this.externalRowActions, {
        name: this.intl.t('general.deletion'),
        icon: 'trash',
        style: 'danger',

        async action(row, event) {
          event.stopPropagation();
          event.preventDefault();
          let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (shouldDelete) {
            try {
              const record = this.groupBy ? this.store.peekRecord(this.modelName, row.id) : row;
              const res = await record.destroyRecord(true);

              if (res) {
                this.flashMessages.info(this.intl.t('general.delete.success'), {
                  title: this.intl.t('general.delete.success-title'),
                  icon: 'times'
                });
              }

              this.storage.refresh();
            } catch (error) {}
          }
        }

      }];
    }),
    bulkActions: (0, _grid.bulkActions)(function () {
      if (this.readOnly || this.noDelete) {
        return this.externalBulkActions;
      }

      const externalBulkActions = this.externalBulkActions ? this.externalBulkActions : [];
      return [...externalBulkActions, {
        name: this.intl.t('general.deletion'),
        icon: 'trash',

        async action(selection) {
          let shouldDelete = await this.modals.open('beta/modals/delete-confirmation');

          if (shouldDelete) {
            try {
              if (this.groupBy) {
                for (const select of selection) {
                  if (select.children) {
                    for (const child of select.children) {
                      let record = this.store.peekRecord(this.modelName, child.id);
                      await record.destroyRecord(true);
                    }
                  } else {
                    let record = this.store.peekRecord(this.modelName, select.id);
                    await record.destroyRecord(true);
                  }
                }
              } else {
                const promises = selection.invoke('destroyRecord', true);
                await Promise.all(promises);
              }

              this.flashMessages.info(this.intl.t('general.delete.success'), {
                title: this.intl.t('general.delete.success-title'),
                icon: 'times'
              });
              this.storage.refresh();
            } catch (error) {
              console.error(error);
              this.flashMessages.danger(this.intl.t('general.delete.error'), {
                title: this.intl.t('general.delete.error'),
                icon: 'warning'
              });
            }
          }
        }

      }];
    }),

    async toggleInActive() {
      this.toggleProperty('inActive');
      this.storage.refresh();
    },

    isActiveField: Ember.computed('model', function () {
      let res = false;
      this.model.fields.forEach(function (kind, field) {
        if (field === 'active') {
          res = true;
        }
      });
      return res;
    }),
    mergedDropDownActions: Ember.computed('inActive', 'isActiveField', function () {
      if (this.isActiveField) {
        const name = this.inActive === true ? this.intl.t('general.inactive-doesnt-show') : this.intl.t('general.inactive-show');
        return [...this.dropDownActions, {
          name,
          action: this.toggleInActive.bind(this)
        }];
      } else {
        return this.dropDownActions;
      }
    }),
    fetchParams: Ember.computed('columns', 'storage.params', 'includeFields', 'defaultOrder', 'defaultFilters', 'groupBy', 'model', function () {
      let {
        filter
      } = (0, _filters.makeQueryObject)(this.columns, this.storage.params);

      if (this.polymorphic) {
        var _filter$where, _filter$where$and;

        (_filter$where = filter.where) === null || _filter$where === void 0 ? void 0 : (_filter$where$and = _filter$where.and) === null || _filter$where$and === void 0 ? void 0 : _filter$where$and.push({
          [this.relationFiled]: this.parentModel.id,
          sourceType: classify(this.parentModel.constructor.modelName)
        });
      } else {
        var _filter$where2, _filter$where2$and;

        (_filter$where2 = filter.where) === null || _filter$where2 === void 0 ? void 0 : (_filter$where2$and = _filter$where2.and) === null || _filter$where2$and === void 0 ? void 0 : _filter$where2$and.push({
          [this.relationFiled]: this.parentModel.id
        });

        if (this.defaultFilters) {
          var _filter$where3, _filter$where3$and;

          (_filter$where3 = filter.where) === null || _filter$where3 === void 0 ? void 0 : (_filter$where3$and = _filter$where3.and) === null || _filter$where3$and === void 0 ? void 0 : _filter$where3$and.push(this.defaultFilters);
        }

        if ((this.defaultOrder || this.groupBy) && filter.order == 'id desc') {
          let orderColumn;

          if (this.groupBy) {
            let modelName = this.model.relationshipsByName.get(this.groupBy).type;
            let model = this.store.modelFor(modelName);
            orderColumn = model.attributes.get('orderColumn') ? model.attributes.get('orderColumn').options.defaultValue : 'name';
          }

          filter.order = this.defaultOrder ? this.defaultOrder : `${this.groupBy}.${orderColumn}`;
        }

        return {
          filter: { ...filter,
            include: this.includeFields,
            inActive: this.inActive
          }
        };
      }
    }),

    getNeededFields(record) {
      const res = {};
      res.id = record.get('id');
      res[this.modelName] = record;

      for (const field of this.neededFields) {
        res[field] = record.get(field);
      }

      return res;
    },

    async editItem(row) {
      const self = this;
      const record = self.groupBy ? self.store.peekRecord(self.modelName, row.id) : row;
      let editItem = await self.modals.open(`modals/form-modal`, {
        model: record,
        itemName: self.itemName,
        parentModel: self.parentModel,
        excludeFields: self.excludeFieldsFormAll,
        neededFields: self.neededFieldsForm || self.neededFields,
        formComponent: self.formComponent,
        formComponentData: self.formComponentData,
        translationFileds: self.translationFileds,
        afterSave: self.afterSave,
        beforeSave: self.beforeSave,
        beforeCloseModal: self.beforeCloseModal,
        proportion: self.proportion,
        noClose: self.noClose,
        noFooter: self.noFooter,
        componentLayout: self.componentLayout
      }, {
        className: _modals.ModalLayoutClassName[self.modalSize || 'M']
      });
      const scroller = document.querySelector('html');
      const scrollTop = scroller.scrollTop;
      self.storage.refresh();
      Ember.run.later(() => {
        self.get('scroller').scrollVertical('html', {
          offset: scrollTop
        });
      }, 500);
    },

    actions: {
      manageStoreResult(result) {
        if (this.groupBy) {
          return result.reduce((pre, curr) => {
            const group = pre.find(g => g[this.groupBy].name === curr.get(`${this.groupBy}.name`));

            if (group) {
              group.children.push(this.getNeededFields(curr));
            } else {
              pre.push({
                [this.groupBy]: {
                  name: curr.get(`${this.groupBy}.name`)
                },
                children: [this.getNeededFields(curr)]
              });
            }

            return pre;
          }, []);
        } else {
          return result;
        }
      },

      async newItem() {
        const record = this.createRecord ? await this.createRecord() : this.store.createRecord(this.modelName);
        this.parentModel[this.itemName].pushObject(record);
        let newItem = await this.modals.open('modals/form-modal', {
          model: record,
          itemName: this.itemName,
          parentModel: this.parentModel,
          excludeFields: this.excludeFieldsFormAll,
          neededFields: this.neededFieldsForm || this.neededFields,
          formComponent: this.formComponent,
          formComponentData: this.formComponentData,
          translationFileds: this.translationFileds,
          afterSave: this.afterSave,
          beforeSave: this.beforeSave,
          beforeCloseModal: this.beforeCloseModal,
          proportion: this.proportion,
          noClose: this.noClose,
          noFooter: this.noFooter,
          componentLayout: this.componentLayout
        }, {
          className: _modals.ModalLayoutClassName[this.modalSize || 'M']
        });

        if (newItem) {
          this.storage.refresh();
        }
      }

    }
  });

  _exports.default = _default;
});