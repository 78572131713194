define("ember-provider/-private/find-parent-provider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = findParentProvider;

  function findParentProvider(injectedProviders, component, ProviderKlass) {
    if (component.parentView) {
      const parentProviders = injectedProviders.get(component.parentView);
      let provider;

      if (parentProviders) {
        provider = parentProviders.find(p => p instanceof ProviderKlass);
      }

      return provider ? provider : findParentProvider(injectedProviders, component.parentView, ProviderKlass);
    }

    return undefined;
  }
});