define("iris/mixins/files-after-save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    disableButton: Ember.inject.service(),
    uploadDocuments: Ember.inject.service(),
    actions: {
      async afterSave(savedFile, generatePDF) {
        this.set('disableButton.disabled', true);

        const _this = this;

        let productResults = await savedFile.get('productResults');
        await productResults.invoke('save'); // Upload files

        await this.get('uploadDocuments').uploadPendingFiles(savedFile, 'Project');
        let toDelete = savedFile.get('toDelete.productResults');
        toDelete.invoke('destroyRecord', true);
        toDelete.clear();
        Ember.run.later(async function () {
          //Tickets
          const tickets = savedFile.hasMany('tickets').value();

          if (tickets) {
            let dirtyTickets = tickets.filterBy('isDirty');
            let ticketsWithUnsavedDependencies = dirtyTickets.filterBy('hasUnsavedDependencies');
            let promises = Ember.A();

            for (const ticket of dirtyTickets) {
              let savedTicket = await saveTicket(ticket);
              promises.pushObject(savedTicket);
            }

            Ember.RSVP.all(promises).then(savedTickets => {
              savedTickets.forEach(async savedTicket => {
                if (ticketsWithUnsavedDependencies.contains(savedTicket)) {
                  savedTicket.save();
                }

                await _this.get('uploadDocuments').uploadPendingFiles(savedTicket, 'Ticket');
              });
            });
          }
        }, 1000);

        function saveTicket(ticket) {
          let ticketPromise = ticket.save();
          ticketPromise.then(savedTicket => {
            //add new ticketUserAssocs
            savedTicket.get('ticketUserAssocs').then(tuas => {
              tuas.invoke('save');
            }); //delete removed ticketUserAssocs

            let toDelete = savedTicket.get('toDelete.ticketUser');
            toDelete.invoke('destroyRecord', true);
            toDelete.clear();
          });
          return ticketPromise;
        }

        savedFile.get('proposals').then(proposals => {
          //Proposals
          proposals.forEach(proposal => {
            proposal.save().then(savedProposal => {
              //add new items
              savedProposal.get('items').then(item => {
                item.invoke('save');
              }); //delete removed items

              let toDelete = savedProposal.get('toDelete.proposalItem');
              toDelete.invoke('destroyRecord', true);
              toDelete.clear();
            });
          });
        });
        toDelete = savedFile.get('toDelete.proposals');
        toDelete.invoke('destroyRecord', true);
        toDelete.clear();
        toDelete = savedFile.get('toDelete.GradeCosts');
        toDelete.invoke('destroyRecord', true);
        toDelete.clear();
        savedFile.get('fileUserGradeCosts').then(fileUserGradeCosts => {
          fileUserGradeCosts.invoke('save');
        });
        savedFile.get('filePlannerAssocs').then(filePlannerAssocs => {
          filePlannerAssocs.invoke('save');
        });
        toDelete = savedFile.get('toDelete.filePlannerAssocs');
        toDelete.invoke('destroyRecord', true);
        toDelete.clear();
        savedFile.get('fileSolverAssocs').then(fileSolverAssocs => {
          fileSolverAssocs.invoke('save');
        });
        toDelete = savedFile.get('toDelete.fileSolverAssocs');
        toDelete.invoke('destroyRecord', true);
        toDelete.clear();
        savedFile.get('fileSupplierAssocs').then(fileSupplierAssocs => {
          fileSupplierAssocs.invoke('save');
        });
        toDelete = savedFile.get('toDelete.fileSupplierAssocs');
        toDelete.invoke('destroyRecord', true);
        toDelete.clear();
        savedFile.get('outgoingProposals').then(outgoingProposals => {
          outgoingProposals.forEach(outgoingProposal => {
            outgoingProposal.save().then(savedProposal => {
              //add new items
              savedProposal.get('items').then(item => {
                item.invoke('save');
              }); //delete removed items

              let toDelete = savedProposal.get('toDelete.proposalItem');
              toDelete.invoke('destroyRecord', true);
              toDelete.clear();
            });
          });
        });
        toDelete = savedFile.get('toDelete.outgoingProposals');
        toDelete.invoke('destroyRecord', true);
        toDelete.clear();
        this.set('disableButton.disabled', false);
        Ember.run.later(function () {
          //generatePDF
          if (generatePDF) {
            var controller = _this.controllerFor('projects.files.edit');

            if (generatePDF.genQuotation) {
              controller.send('generateQuotationPDF', savedFile, generatePDF.id);
            }

            if (generatePDF.redierctToPerformanceModal) {
              _this.generatePerformanceRecordPDF(generatePDF.redierctToPerformanceModal);
            }

            if (generatePDF.genOutline) {
              controller.send('generateOutlinePDF', savedFile);
            }
          }
        }, 1000);
        var controller = this.controllerFor(this.get('routeName'));
        controller.set('refresh', false);
        Ember.run.next(function () {
          // The proposals list is a bs-table server.
          // The problem that we save the proposals in this after save
          // and the bs-table won't know about the change.
          // If the bs-table didn't know about the change next time
          // it will try to add the proposals again.
          // So thats why we need to relaod them after the save.
          let proposals = savedFile.hasMany('proposals');
          let outgoindProposals = savedFile.hasMany('proposals');
          proposals.reload();
          outgoindProposals.reload();
          controller.set('refresh', true);
        });
      }

    }
  });

  _exports.default = _default;
});